import { DialogTitle, DialogContent, DialogActions, Button, Dialog, Typography, Divider, Box, Avatar } from "@mui/material";
import { TransactionDto } from "../apiclient/MemberAPI";
import { t } from "@lingui/macro";
import { styled } from "@mui/material/styles";
import MuiGrid from "@mui/material/Grid";
import MemberAvatar from "../assets/resources/memberAvatar.webp";

interface ReceiptDialogProps {
	id: string;
	transaction: TransactionDto;
	open: boolean;
	onClose: (value?: string) => Promise<void>;
}

export function ReceiptDialog(props: ReceiptDialogProps) {
	const { onClose, open, ...other } = props;

	const handleCancel = async () => {
		await onClose();
	};

	const handlePrint = async () => {
		var actions = window.document.getElementById("noPrint");
		var actionStyle = actions!.style.display;
		actions!.style.display = "none";
		window.print();
		actions!.style.display = actionStyle;
		if ("matchMedia" in window) {
			window.matchMedia("print").addListener(function (media) {});
		}
		window.onafterprint = async function () {
			await onClose("Printed");
		};
	};

	const Grid = styled(MuiGrid)(({ theme }) => ({
		width: "100%",
		...theme.typography.body2,
		'& [role="separator"]': {
			margin: theme.spacing(0, 2),
		},
	}));
	let discountSum = 0;
	let taxSum = 0;

	if (props.transaction === undefined) return <></>;
	let title = t`Receipt`;
	switch (props.transaction.department?.departmentBudgetSegment) {
		case "Hotel":
			title = t`Reservations`;
			break;
		default:
			break;
	}
	return (
		<Dialog sx={{ "& .MuiDialog-paper": { width: "80%" } }} maxWidth="xs" open={open} {...other}>
			<DialogTitle variant="caption" textAlign={"center"} sx={{ fontWeight: "bold" }}>
				{title} <br /> {props.transaction.department?.displayName ?? "#" + props.transaction.externalOrderReference ?? ""}{" "}
				{new Date(props.transaction.createdAt ?? "").toLocaleString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}{" "}
				{new Date(props.transaction.createdAt ?? "").toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false })}
				<br />
				<Grid container>
					<Grid item xs sx={{ textAlign: "left" }}>
						<Box sx={{ display: "inline-block" }}>
							<Avatar
								alt="merchant"
								src={props.transaction.merchant?.logoUrl ?? ""}
								sx={{ "& .MuiAvatar-img": { objectFit: "cover" }, width: 64, height: 64, bgcolor: "white", mb: 1, float: "left" }}
							/>
						</Box>
						<Typography>{props.transaction.department?.displayName ?? props.transaction.merchant?.displayName}</Typography>
						<Typography variant="caption">
							{props.transaction.department?.address?.street ?? props.transaction.merchant?.address?.street}
							{", "}
							{props.transaction.department?.address?.zipCode ?? props.transaction.merchant?.address?.zipCode}
						</Typography>
						<br />
						<Typography variant="caption">
							{props.transaction.department?.address?.city ?? props.transaction.merchant?.address?.city}
							{", "} {props.transaction.merchant?.address?.country}
							{"."}
						</Typography>
						<br />
						<Typography variant="caption">{props.transaction.department?.phoneNumber ?? props.transaction.merchant?.phoneNumber}</Typography>
						<br />
						<Typography variant="caption">{props.transaction.department?.email ?? props.transaction.merchant?.email}</Typography>
						<br />
						<Typography variant="caption">{props.transaction.department?.website ?? props.transaction.merchant?.website}</Typography>
					</Grid>
					<Divider orientation="vertical" flexItem sx={{ color: "transparent", borderColor: "transparent" }} light />
					<Grid item xs sx={{ textAlign: "right" }}>
						<Box sx={{ display: "inline-block" }}>
							<Avatar
								alt="member"
								src={MemberAvatar}
								sx={{ "& .MuiAvatar-img": { objectFit: "cover" }, width: 64, height: 64, bgcolor: "white", mb: 1, float: "right" }}
							/>
						</Box>
						<Typography>
							{props.transaction.member?.firstName} {props.transaction.member?.lastName}
						</Typography>
						<Typography variant="caption">{props.transaction.member?.address?.street}</Typography>
						<br />
						<Typography variant="caption">
							{props.transaction.member?.address?.zipCode} {props.transaction.member?.address?.city}
						</Typography>
						<br />
						<br />
						<Typography variant="caption">{props.transaction.member?.cellphoneNumber}</Typography>
						<br />
						<Typography variant="caption">{props.transaction.member?.emailAddress}</Typography>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent
				dividers
				sx={{
					fontFamily: (theme) => theme.typography.caption.fontFamily,
					fontSize: (theme) => theme.typography.caption.fontSize,
					fontWeight: (theme) => theme.typography.caption.fontWeight,
					lineHeight: (theme) => theme.typography.caption.lineHeight,
					letterSpacing: (theme) => theme.typography.caption.letterSpacing,
					textTransform: (theme) => theme.typography.caption.textTransform,
				}}
			>
				<table style={{ width: "100%" }}>
					{props.transaction.transactionLines && props.transaction.transactionLines.length > 0 && (
						<thead>
							<tr>
								<th style={{ textAlign: "left", width: "5%" }}>#</th>
								<th style={{ textAlign: "left", width: "65%" }}>{t`Product`}</th>
								<th style={{ textAlign: "left", width: "5%" }}>{t`No.`}</th>
								<th style={{ textAlign: "right", width: "12.5%" }}>{t`Price`}</th>
								<th style={{ textAlign: "right", width: "12.5%" }}>{t`Total`}</th>
							</tr>
						</thead>
					)}
					<tbody>
						{props.transaction.transactionLines?.map((element, index) => {
							if (element.discount && element.discount.amount && element.discount.amount.amount) discountSum = discountSum + element.discount.amount.amount;
							if (element.tax && element.tax.amount) taxSum = taxSum + element.tax?.amount;
							return (
								<tr id={"l_t" + (index + 1).toString()}>
									<td>{index + 1}</td>
									<td>
										<h4>{element.product?.displayName}</h4>
									</td>
									<td style={{ textAlign: "center" }}>
										<strong>
											x<span>{element.quantity}</span>
										</strong>
									</td>
									<td style={{ textAlign: "right" }}>
										<strong>{element.unitPrice?.toLocaleString()},-</strong>
									</td>
									<td style={{ textAlign: "right" }}>{(Number(element.unitPrice) * Number(element.quantity)).toLocaleString()},-</td>
								</tr>
							);
						})}
						<tr>
							<td colSpan={4}>{t`Total`}</td>
							<td style={{ textAlign: "right" }}>
								<span>
									<strong>{props.transaction.paidAmount?.amount?.toLocaleString()},-</strong>
								</span>
							</td>
						</tr>
						<tr>
							<td colSpan={4}>{t`Bonus`}</td>
							<td style={{ textAlign: "right" }}>{props.transaction.bonusInfo?.bonusApplicableAmount?.toLocaleString()},-</td>
						</tr>
						<tr>
							<td colSpan={4}>{t`Discount`}</td>
							<td style={{ textAlign: "right" }}>
								<span>{discountSum.toLocaleString()},-</span>
							</td>
						</tr>
						<tr>
							<td colSpan={4}>{t`VAT`}</td>
							<td style={{ textAlign: "right" }}>
								<span>{taxSum.toLocaleString()},-</span>
							</td>
						</tr>
					</tbody>
				</table>
			</DialogContent>
			<DialogActions sx={{ pr: 3, pb: 0 }} id="noPrint">
				<Button
					sx={{
						bgcolor: (theme) => theme.palette.primary.dark,
						color: (theme) => theme.palette.text.secondary,
						border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
						marginBottom: 2,
						height: "30px",
						p: "unset",
					}}
					autoFocus
					onClick={handleCancel}
				>
					{t`Close`}
				</Button>
				<Button
					sx={{
						bgcolor: (theme) => theme.palette.primary.dark,
						color: (theme) => theme.palette.text.secondary,
						border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
						marginBottom: 2,
						height: "30px",
						p: "unset",
					}}
					onClick={handlePrint}
				>
					{t`Print`}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
