export function GetYears(arr: string[]): Array<number> {
	const minYear = ReceiptsAnnualPeriod(
		arr.map((date) => new Date(date)),
		"Min"
	);
	const maxYear = ReceiptsAnnualPeriod(
		arr.map((date) => new Date(date)),
		"Max"
	);
	if (minYear && maxYear) {
		if (minYear === maxYear) return [minYear];
		return Array.from(new Array(maxYear - minYear + 1).keys()).map(function (num) {
			return num + minYear;
		});
	}

	return [];
}

function ReceiptsAnnualPeriod(arr: Date[], MinOrMax: "Min" | "Max"): number | null {
	switch (MinOrMax) {
		case "Min":
			const minDate = new Date(Math.min(...arr.map((x) => new Date(x).getTime())));
			return minDate.getFullYear();
		case "Max":
			const maxDate = new Date(Math.max(...arr.map((x) => new Date(x).getTime())));
			return maxDate.getFullYear();
		default:
			return null;
	}
}
