/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Account {
  accountName?: string | null;
  accountNumber?: string | null;
  bankName?: string | null;
}

export interface Address {
  streetAddress?: string | null;
  postalCode?: string | null;
  region?: string | null;
  country?: string | null;
  formatted?: string | null;
  addressType?: string | null;
}

/**
 * Address
 */
export interface AddressDTO {
  /** Address type */
  addressType?: AddressType;

  /** Street name */
  street?: string | null;

  /** Zip code */
  zipCode?: string | null;

  /** City */
  city?: string | null;

  /** Two letter country code */
  countryCode?: string | null;

  /** Country name (TBD) */
  country?: string | null;
}

/**
 * Address type (1 = customer address, 2 = shipping address, 3 = billing address, 4 = other address
 */
export enum AddressType {
  Customer = "Customer",
  Shipping = "Shipping",
  Billing = "Billing",
}

export interface AuthResponseDTO {
  token?: string | null;

  /** Represents a given member in a given program */
  member?: MemberDTO | null;
}

/**
 * Gender
 */
export enum Gender {
  Male = "Male",
  Female = "Female",
  Other = "Other",
  Unknown = "Unknown",
}

/**
 * Represents a given member in a given program
 */
export interface MemberDTO {
  /**
   * Unique identifier for given member on given program
   * @format uuid
   */
  id: string;

  /** First name */
  firstName?: string | null;

  /** Last name */
  lastName?: string | null;

  /** Cellphone number */
  cellphoneNumber: string;

  /** E-mail address */
  emailAddress?: string | null;

  /**
   * Birth date
   * @format date-time
   */
  birthDate?: string | null;

  /** Default address */
  address?: AddressDTO | null;

  /** Gender.  Can be m/f/o/u (male, female, other, unknown). Default unknown. */
  gender?: Gender;

  /** Program tag */
  programTag?: string | null;

  /**
   * UTC Date and Time for when user agreement was accepted
   * @format date-time
   */
  userAgreementAcceptDate?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;

  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export interface VippsLoginRequestDTO {
  /** @format int32 */
  programId?: number;
  code?: string | null;
}

export interface VippsProxyURL {
  redirectURL?: string | null;
}

export interface VippsUserInfo {
  sub?: string | null;

  /** @format date-time */
  birthdate?: string | null;
  email?: string | null;
  emailVerified?: boolean | null;
  nin?: string | null;
  name?: string | null;
  givenName?: string | null;
  familyName?: string | null;
  sid?: string | null;
  phoneNumber?: string | null;
  address?: Address | null;
  otherAddresses?: Address[] | null;
  accounts?: Account[] | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Loyalty Administrative API
 * @version 1.0
 * @contact
 *
 * Loyalty Administrative API for program owners
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags vipps
     * @name VippsGetVippsProxyUrl
     * @request GET:/api/vipps/v1/vippsEnter/redirect
     * @secure
     */
    vippsGetVippsProxyUrl: (query?: { returnURL?: string }, params: RequestParams = {}) =>
      this.request<VippsProxyURL, ProblemDetails>({
        path: `/api/vipps/v1/vippsEnter/redirect`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags vipps
     * @name VippsGetCode
     * @request GET:/api/vipps/v1/vippsEnter/{returnURLOrigin}/vippsEnter/CallBackLogin
     * @secure
     */
    vippsGetCode: (returnUrlOrigin: string, query?: { code?: string }, params: RequestParams = {}) =>
      this.request<VippsUserInfo, ProblemDetails>({
        path: `/api/vipps/v1/vippsEnter/${returnUrlOrigin}/vippsEnter/CallBackLogin`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags vipps
     * @name VippsLogin
     * @request POST:/api/vipps/v1/vippsEnter/Login
     * @secure
     */
    vippsLogin: (data: VippsLoginRequestDTO, params: RequestParams = {}) =>
      this.request<AuthResponseDTO, ProblemDetails | void>({
        path: `/api/vipps/v1/vippsEnter/Login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
