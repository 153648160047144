import { Box, Stack, styled, Paper, Button, Typography } from "@mui/material";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { t, Trans } from "@lingui/macro";
import { VoucherDTO } from "../apiclient/MemberAPI";
import { createRef, useContext, useState } from "react";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { hexToRGBA } from "../utils/themeStyling";
import { i18n } from "@lingui/core";
import { TextTemplate } from "../service/jsonreader";
import { WidgetSize, TemplateElements, Components, BenefitTypes, BenefitReleaseModes } from "../types/enum";
import { BenefitTimerBox } from "./benefittimer";
import CircularStatic from "./progress";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: "transparent",
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.secondary,
	boxShadow: "0 0",
}));

interface Props {
	offerInfo: VoucherDTO | null;
	size: WidgetSize;
	idx: number;
	update: any;
}

export function OfferBox(props: Props) {
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const GetNoneReleasableText = (component: Components, level: number) => {
		let text = TextTemplate.GetTextTemplate(component, IntegrationSetting.customer, TemplateElements.texts, level, undefined);
		if (!text) return "";
		const translated = /*i18n*/ i18n._(`${text}`);
		return i18n._(translated) + " 🙂";
	};
	const inputRef = createRef<any>();
	const [processing, setProcessing] = useState<boolean>(false);
	const [benefitByTimer, setBenefitByTimer] = useState<boolean>(false);
	const tooltip = t`By activating you can't undo`;

	const requestOffer = async (timer: boolean) => {
		setProcessing(true);
		if (timer && props.offerInfo?.canRelease) setBenefitByTimer(true);
		setProcessing(false);
	};

	const id = props.idx.toString();

	if (!props.offerInfo?.canRelease) {
		return null;
	}
	const releaseByTimer = IntegrationSetting.releaseMode === BenefitReleaseModes.ByTimer;
	const showNoReleasableText = !IntegrationSetting.enableReleaseOffer && IntegrationSetting.noReleasableWithText;
	return (
		<>
			{benefitByTimer && props.offerInfo && props.offerInfo.header && (
				<BenefitTimerBox
					benefitType={BenefitTypes.Offer}
					widgetSize={props.size}
					offerHeader={props.offerInfo.header}
					voucherInfo={props.offerInfo}
					idx={props.idx}
					update={props.update}
				/>
			)}
			{!benefitByTimer && (
				<Box
					id={props.offerInfo?.reference ?? "0"}
					ref={inputRef}
					title={tooltip}
					sx={{ height: processing ? "30px" : "unset", margin: processing ? "0 auto" : "unset", textAlign: processing ? "center" : "unset" }}
				>
					{processing && <CircularStatic visible={processing} />}
					{!processing && (
						<Stack spacing={0}>
							<Item
								id={"offerbox-item0-" + id}
								sx={{
									pt: "16px",
									pl: "16px",
									pr: "16px",
									borderBottomRightRadius: 0,
									borderBottomLeftRadius: 0,
									backgroundColor: (theme) => {
										return hexToRGBA(theme.palette.background.paper, "0.1");
									},
								}}
							>
								<Stack
									direction="row"
									sx={{
										justifyContent: "space-between",
									}}
								>
									<Item
										sx={{
											color: (theme) => theme.palette.secondary.main,
											fontWeight: "unset",
											fontSize: "12px",
											backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
										}}
									>
										<Trans>Offer</Trans>
									</Item>
									<Item sx={{ fontSize: "9px", backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))" }}>
										<Trans>Valid until:</Trans> {props.offerInfo.validTo?.substring(0, 10)}
									</Item>
								</Stack>
							</Item>
							<Item
								id={"offerbox-item1-" + id}
								sx={{
									pt: "8px",
									pl: "16px",
									pr: "16px",
									borderRadius: 0,
									backgroundColor: (theme) => {
										return hexToRGBA(theme.palette.background.paper, "0.1");
									},
								}}
							>
								<Stack direction="row" spacing={0}>
									<Item
										sx={{
											ml: "-5px",
											backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
										}}
									>
										<LocalOfferOutlinedIcon sx={{ fontSize: "22px" }} />
									</Item>
									<Item
										sx={{
											color: (theme) => theme.palette.secondary.main,
											mt: "2px",
											fontWeight: "600",
											fontSize: "16px",
											textAlign: "left",
											backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
											pl: 1,
											width: "100%",
										}}
									>
										{props.offerInfo?.header}
									</Item>
								</Stack>
							</Item>
							<Item
								id={"offerbox-item2-" + id}
								sx={{
									pt: "8px",
									pl: "16px",
									pr: "16px",
									borderRadius: 0,
									backgroundColor: (theme) => {
										return hexToRGBA(theme.palette.background.paper, "0.1");
									},
								}}
							>
								<Stack
									direction="row"
									sx={{
										justifyContent: "space-between",
									}}
								>
									<Item
										sx={{
											color: (theme) => theme.palette.secondary.main,
											fontWeight: "unset",
											fontSize: "12px",
											backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
											textAlign: "left",
										}}
									>
										{props.offerInfo?.subHeader}
									</Item>
								</Stack>
							</Item>
							<Item
								id={"offerbox-item3-" + id}
								sx={{
									pt: "19px",
									pb: "24px",
									pl: "16px",
									pr: "16px",
									borderTopRightRadius: 0,
									borderTopLeftRadius: 0,
									backgroundColor: (theme) => {
										return hexToRGBA(theme.palette.background.paper, "0.1");
									},
								}}
							>
								{(IntegrationSetting.enableReleaseOffer || showNoReleasableText) && (
									<Box
										sx={{
											display: "flex",
											"& > *:not(:last-child)": { marginRight: "3px" },
										}}
									>
										{IntegrationSetting.enableReleaseOffer && (
											<Button
												sx={{
													bgcolor: (theme) => theme.palette.background.paper,
													color: (theme) => theme.palette.text.primary,
													height: "30px",
													"&:hover": {
														backgroundColor: (theme) => theme.palette.background.paper,
													},
												}}
												fullWidth
												size="small"
												variant="contained"
												onClick={async () => {
													await requestOffer(releaseByTimer);
												}}
												disabled={!props.offerInfo}
											>
												<Trans>See more</Trans>
											</Button>
										)}
										{showNoReleasableText && (
											<Typography sx={{ height: "30px", fontSize: "12px", mb: "1.57px" }}>{GetNoneReleasableText(Components.Offer, 0)}</Typography>
										)}
									</Box>
								)}
							</Item>
							{(IntegrationSetting.enableReleaseOffer || showNoReleasableText) && (
								<Item
									sx={{
										borderTopRightRadius: 0,
										borderTopLeftRadius: 0,
										mt: "-40px",
										height: "40px",
										backgroundColor: (theme) => theme.palette.background.default,
									}}
								></Item>
							)}
						</Stack>
					)}
				</Box>
			)}
		</>
	);
}
