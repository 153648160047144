import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { IconButton, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/usercontext";
import { styled } from "@mui/material";
import { ElementTextByTemplate } from "../service/jsonreader";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { Box } from "@mui/system";
import { Product } from "../types/type";
import { WidgetSize, Components, TemplateElements } from "../types/enum";

interface Props {
	IconSize: "small" | "medium" | "large" | undefined;
	Product: Product;
}

const Person = styled(IconButton)(({ theme }) => ({
	backgroundColor: "transparent",
	color: theme.palette.secondary.light,
}));

const Maximize = styled(IconButton)(({ theme }) => ({
	backgroundColor: "transparent",
	color: theme.palette.secondary.light,
	marginLeft: "auto",
}));

const Content = styled(IconButton)(({ theme }) => ({
	backgroundColor: "transparent",
	color: theme.palette.secondary.light,
}));

export function MinimizeUI(props: Props) {
	const userContext = useContext(UserContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const BackToMainOrFull = () => {
		const postLoginWidgetSize = Object.values(Object.keys(WidgetSize))[Number(IntegrationSetting.postLoginWidgetSize)] as WidgetSize;
		if (window.innerWidth <= 1024 && postLoginWidgetSize === WidgetSize.Full) {
			userContext.changeWidgetSize(WidgetSize.Full);
			return;
		}
		userContext.changeWidgetSize(WidgetSize.Main);
	};
	const [productMessage, setProductMessage] = useState([""]);

	useEffect(() => {
		setProductMessage(
			ElementTextByTemplate(
				Components.Minimize,
				IntegrationSetting.customer,
				IntegrationSetting.product.IsInSale ? 3 : 0,
				TemplateElements.texts,
				props.Product
			)
		);
	}, [props.Product]);

	return (
		<Box sx={{ display: "flex" }}>
			<Person size={props.IconSize} onClick={() => BackToMainOrFull()} sx={{ color: (theme) => theme.palette.text.primary }}>
				<PersonOutlineOutlinedIcon sx={{ fontSize: "2rem" }} />
			</Person>
			<Content
				size={props.IconSize}
				onClick={() => BackToMainOrFull()}
				sx={{ color: (theme) => theme.palette.text.primary, maxWidth: "250px", "&:hover": { backgroundColor: "unset" } }}
			>
				<Typography variant="body2" sx={{ textTransform: "none", fontSize: "10px", textAlign: "left", "&::first-line": { fontSize: "12px" } }}>
					{productMessage}
					<br />
					{ElementTextByTemplate(Components.Minimize, IntegrationSetting.customer, 1, TemplateElements.texts)}
				</Typography>
			</Content>
			<Maximize size={props.IconSize} onClick={() => BackToMainOrFull()} sx={{ color: (theme) => theme.palette.text.primary }}>
				<KeyboardArrowUpRoundedIcon />
			</Maximize>
		</Box>
	);
}
