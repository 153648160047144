import { log } from "../service/clientlog";
import { UserContext } from "../context/usercontext";
import { useContext, useEffect, useState } from "react";
import "../assets/styles/logo.css";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { ElementTextByTemplate } from "../service/jsonreader";
import { Link, Zoom } from "@mui/material";
import { ConsoleType, Components, TemplateElements } from "../types/enum";

//import { ReactComponent as Logo } from "../assets/resources/logo.svg";

export default function ClosedUI(Props: any) {
	const userContext = useContext(UserContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const [open, setOpen] = useState(false);
	const timeout = IntegrationSetting.popupTimeout;
	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
		setTimeout(() => {
			log("timeout - CLOSE", ConsoleType.Trace);
			handleClose();
		}, timeout);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			log("timeout - OPEN", ConsoleType.Trace);
			handleOpen();
		}, 0);
		return () => {
			log("timeout - RESET", ConsoleType.Trace);
			clearTimeout(timer);
		};
	}, []);

	const isCtx = useContext(IntegrationSettingsContext);
	const toggle = () => {
		log("Toggle called", ConsoleType.Trace, userContext.widgetModeState());
		userContext.toggleWidgetMode(userContext.widgetModeState());
		isCtx.onEvent({ EventType: "OnModeChange", Payload: { Ref: "CloseUI", WidgetModeState: userContext.widgetModeState() } });
	};
	const logoURL = `${Props.AssetsURL}logo${Props.programID}.svg`;
	//parseFloat(theme.typography.caption.fontSize?.toString() ?? "1") * 5 + "rem"

	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			cursor: "pointer",
			backgroundColor: "#ffffff",
			color: "#000000",
			maxWidth: 317,
			fontSize: theme.typography.pxToRem(12),
		},
		[`& .${tooltipClasses.arrow}`]: {
			transform: "translate(182px,1-px)",
			fontSize: "medium",
			color: theme.palette.secondary.dark,
		},
	}));

	return (
		<HtmlTooltip
			sx={{ borderRadius: 1, boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;", backgroundColor: "#ffffff" }}
			arrow
			TransitionComponent={Zoom}
			onClick={() => toggle()}
			open={open}
			onClose={handleClose}
			onOpen={handleOpen}
			title={
				<Box
					sx={{
						padding: 1,
					}}
				>
					<Typography sx={{ fontWeight: "bold" }} color="inherit">
						Psst....
					</Typography>
					<Typography variant="body2" sx={{ textAlign: "left" }}>
						{ElementTextByTemplate(Components.Close, IntegrationSetting.customer, 0, TemplateElements.texts, IntegrationSetting.product)}
						<br />
						<Link onClick={() => toggle()} color="inherit">
							{ElementTextByTemplate(Components.Close, IntegrationSetting.customer, 1, TemplateElements.texts)}
						</Link>
					</Typography>
				</Box>
			}
		>
			<img alt="logo" src={logoURL} className={`logo-loyall-${Props.programID}`} />
		</HtmlTooltip>
	);
}
