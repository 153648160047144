import { Trans } from "@lingui/macro";
import { Box, FormGroup, FormControlLabel, Switch, Typography, Theme, Grid, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ConsentType, MemberConsentDTO } from "../apiclient/MemberAPI";
import { APIContext } from "../context/apicontext";
import { log } from "../service/clientlog";
import { ConsoleType } from "../types/enum";
import CircularStatic from "./progress";

export function ConsentDetail(props: any) {
	const alpha = (callback1: (color: Theme) => string, opacity: number): string => {
		const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
		return callback1 + _opacity.toString(16).toUpperCase();
	};
	const memberContext = useContext(APIContext);
	const [currentSMS, setSMS] = useState<boolean>(false);
	const [currentEmail, setEmail] = useState<boolean>(false);
	const [currentOffer, setOffer] = useState<boolean>(false);
	const [processingUpdate, setProcessingUpdate] = useState<boolean>(false);
	const [animation, setAnimation] = useState<boolean>(false);
	const [animationColor, setAnimationColor] = useState("");

	useEffect(() => {
		if (animation) {
			setTimeout(() => {
				setAnimation(false);
			}, 2000);
		}
	}, [animation]);

	const updateConsentItems = (consentItems: MemberConsentDTO[]) => {
		consentItems.forEach((element) => {
			if (element.isValid) {
				switch (element.consentType) {
					case ConsentType.SMS:
						setSMS(true);
						break;
					case ConsentType.Email:
						setEmail(true);
						break;
					case ConsentType.Offers:
						setOffer(true);
						break;
				}
			}
		});
	};
	useEffect(() => {
		const loadAsync = async () => {
			const temp = await memberContext.consentInfo({ loadAPI: false });
			updateConsentItems(temp);
		};
		loadAsync();
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		switch (event.target.name) {
			case "sms":
				setSMS(event.target.checked);
				break;
			case "email":
				setEmail(event.target.checked);
				break;
			case "offer":
				setOffer(event.target.checked);
				break;
			default:
				break;
		}
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setProcessingUpdate(true);

		const consent = await memberContext.consentInfo({ loadAPI: true });

		let originSMS = false,
			originEmail = false,
			originOffer = false;
		let isSMSChanged = false,
			isEmailChanged = false,
			isOfferChanged = false;
		consent.forEach((item) => {
			if (item.isValid) {
				switch (item.consentType) {
					case ConsentType.SMS:
						originSMS = true;
						break;
					case ConsentType.Email:
						originEmail = true;
						break;
					case ConsentType.Offers:
						originOffer = true;
						break;
				}
			}
		});
		try {
			if (currentSMS !== originSMS) {
				await memberContext.updateConsent(ConsentType.SMS, currentSMS);
				isSMSChanged = true;
			}
			if (currentEmail !== originEmail) {
				await memberContext.updateConsent(ConsentType.Email, currentEmail);
				isEmailChanged = true;
			}
			if (currentOffer !== originOffer) {
				await memberContext.updateConsent(ConsentType.Offers, currentOffer);
				isOfferChanged = true;
			}
		} catch (error) {
			log("Member consent update", ConsoleType.Error, currentSMS, currentEmail, currentOffer);
			setProcessingUpdate(false);
			setAnimationColor("darkred");
			setAnimation(false);
		} finally {
			if (isSMSChanged || isEmailChanged || isOfferChanged) {
				setAnimation(true);
				setAnimationColor("darkgreen");
				await memberContext.consentInfo({ loadAPI: true });
			}
		}
		setProcessingUpdate(false);
	};

	return (
		<Box
			onSubmit={handleSubmit}
			noValidate
			component="form"
			autoComplete="off"
			sx={{
				pt: 0,
				display: "flex",
				color: (theme) => theme.palette.text.primary,
				mb: 1,
				pl: 1,
			}}
		>
			<FormGroup>
				<FormControlLabel
					control={
						<Switch
							sx={{
								color: (theme) => {
									return theme.palette.primary.main;
								},
								"& .MuiSwitch-switchBase.Mui-checked": {
									color: (theme) => theme.palette.primary.main,
									"&:hover": {
										backgroundColor: alpha((theme) => theme.palette.primary.light, 0.08),
									},
								},
								"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
									backgroundColor: (theme) => theme.palette.success.main,
								},
							}}
							name="sms"
							checked={currentSMS}
							onChange={handleChange}
						/>
					}
					label={
						<Typography variant="caption" color="secondary.main" textAlign="justify">
							<Trans>I agree to communication via SMS.</Trans>
						</Typography>
					}
				/>
				<FormControlLabel
					control={
						<Switch
							sx={{
								color: (theme) => {
									return theme.palette.primary.main;
								},
								"& .MuiSwitch-switchBase.Mui-checked": {
									color: (theme) => theme.palette.primary.main,
									"&:hover": {
										backgroundColor: alpha((theme) => theme.palette.primary.light, 0.08),
									},
								},
								"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
									backgroundColor: (theme) => theme.palette.success.main,
								},
							}}
							name="email"
							checked={currentEmail}
							onChange={handleChange}
						/>
					}
					label={
						<Typography variant="caption" color="secondary.main" textAlign="justify">
							<Trans>I agree to communication via E-mail.</Trans>
						</Typography>
					}
				/>
				<FormControlLabel
					control={
						<Switch
							sx={{
								color: (theme) => {
									return theme.palette.primary.main;
								},
								"& .MuiSwitch-switchBase.Mui-checked": {
									color: (theme) => theme.palette.primary.main,
									"&:hover": {
										backgroundColor: alpha((theme) => theme.palette.primary.light, 0.08),
									},
								},
								"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
									backgroundColor: (theme) => theme.palette.success.main,
								},
							}}
							name="offer"
							checked={currentOffer}
							onChange={handleChange}
						/>
					}
					label={
						<Typography variant="caption" color="secondary.main" textAlign="justify">
							<Trans>I would like to receive personalized information.</Trans>
						</Typography>
					}
				/>
				<Grid item xs={12} sx={{ mt: 3 }}>
					{props.editable && (
						<Button
							sx={{
								bgcolor: (theme) => theme.palette.primary.dark,
								color: (theme) => theme.palette.text.secondary,
								border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
								marginBottom: 2,
								height: "30px",
								width: "100%",
							}}
							type="submit"
							variant="contained"
							size="small"
							id="confirmall"
							name="confirmall"
							disabled={processingUpdate}
							onClick={() => handleSubmit}
						>
							{!processingUpdate && <Trans>Confirm</Trans>}
							{processingUpdate && <CircularStatic visible={processingUpdate} />}
						</Button>
					)}
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						height: "2px",
						animationName: "blinker",
						animationDuration: "5000ms",
						animationTimingFunction: "linear",
						animationIterationCount: animation ? "infinite" : 0,
						"@-webkit-keyframes blinker": {
							from: { backgroundColor: animationColor },
							to: { backgroundColor: "white" },
						},
						"@keyframes blinker": {
							from: { backgroundColor: animationColor },
							to: { backgroundColor: "white" },
							// "0%": {
							// 	background: "#00ff44",
							// },
							// "20%": {
							// 	background: "#02d139",
						},
						textAlign: "left",
					}}
				>
					{animation && animationColor === "darkred" ? (
						<Typography sx={{ color: animationColor }}>error 😏</Typography>
					) : (
						animation && (
							<Typography sx={{ color: animationColor }}>
								<Trans>Consent updated</Trans> 🎉
							</Typography>
						)
					)}
				</Grid>
			</FormGroup>
		</Box>
	);
}
