import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import qrc from "../assets/resources/qrcode.png";

interface Props {
	source: string;
}

const QrCard = (props: Props) => {
	return (
		<>
			<Typography
				variant="caption"
				sx={{ color: (theme) => theme.palette.background.paper, pb: 1 }}
			>{`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`}</Typography>
			<Box component="div">
				<Box component="img" width="125px" borderRadius={props.source === qrc ? "1rem" : 0} src={props.source} alt="qrCode-result"></Box>
			</Box>
		</>
	);
};

export default QrCard;
