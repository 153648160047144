import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { Trans } from "@lingui/macro";
import React, { FocusEvent, useContext, useEffect, useState } from "react";
import { APIContext } from "../context/apicontext";
import { InputLabel, Link, Paper, TextField } from "@mui/material";
import CircularStatic from "../components/progress";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { ThemeSettingsContext } from "../context/themesettingscontext";
import { ConsentType, MemberConsentDTO, MemberDTO } from "../apiclient/MemberAPI";
import { emailPattern } from "../types/interface";
import { Constants } from "../constants/constants";
import { log } from "../service/clientlog";
import { ConsoleType } from "../types/enum";
import { LoadHTML } from "../components/htmlloader";
import { TermsAndConditions } from "../components/tc";
import { format } from "date-fns";

function Consent() {
	const memberContext = useContext(APIContext);
	const userInfo = memberContext.userInfo({ loadAPI: false });
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const [processingAll, setProcessingAll] = useState<boolean>(false);
	const [processingSelected, setProcessingSelected] = useState<boolean>(false);

	const [sms, setSMS] = useState<boolean>(false);
	const [email, setEmail] = useState<boolean>(false);
	const [offer, setOffer] = useState<boolean>(false);
	const [consent, setConsent] = useState<MemberConsentDTO[] | null>([]);

	const getInitialInfo = (type: "email" | "birthdate") => {
		const member = memberContext.userInfo({ loadAPI: false });
		switch (type) {
			case "email":
				const incoming = new URLSearchParams(window.location.search).get(Constants.EmailSearchParam);
				if (incoming) return incoming;

				var emailAddress = member?.emailAddress;
				if (emailAddress && emailAddress !== undefined) return emailAddress;

				const temp =
					consent &&
					consent.filter((e) => {
						if (e.isValid && e.consentType === ConsentType.Email) return true;
						return false;
					});
				if (temp && temp.length > 0 && temp !== undefined) return temp[0].member?.emailAddress ?? "";
				return "";
			case "birthdate":
				var birthDate = member?.birthDate;
				if (birthDate && birthDate !== undefined) return birthDate;
				return "";
			default:
				return "";
		}
	};
	const [emailAddress, setEmailAddress] = useState(getInitialInfo("email"));
	const [birthDate, setBirthDate] = useState(getInitialInfo("birthdate"));
	const [birthDateError, setBirthDateError] = useState(false);

	const [emailError, setEmailError] = useState(emailAddress.length > 0 && !emailPattern.test(emailAddress));
	const [smsError, setSMSError] = useState(false);

	const updateConsentItems = (consentItems: MemberConsentDTO[]) => {
		consentItems.forEach((element) => {
			if (element.isValid) {
				switch (element.consentType) {
					case ConsentType.SMS:
						setSMS(true);
						break;
					case ConsentType.Email:
						setEmail(emailAddress.length > 0 && true);
						break;
					case ConsentType.Offers:
						setOffer(true);
						break;
				}
			}
		});
	};
	useEffect(() => {
		const loadAsync = async () => {
			const temp = await memberContext.consentInfo({ loadAPI: false });
			setConsent(temp);
			updateConsentItems(temp);
		};
		loadAsync();
	}, []);

	const handleBirthdateInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setBirthDate(event.target.value);
		setBirthDateError(false);
	};
	const handleEmailInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setEmailAddress(event.target.value);
	};
	const handleEmailBlur = (e: FocusEvent<HTMLInputElement>) => {
		const validity = emailPattern.test(emailAddress);
		setEmailError(!validity);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		switch (event.target.name) {
			case "sms":
				setSMS(event.target.checked);
				setSMSError(!event.target.checked);
				break;
			case "email":
				setEmail(event.target.checked);
				break;
			case "offer":
				setOffer(event.target.checked);
				break;
			default:
				break;
		}
	};

	const consentUserAgreement = (consentItems: MemberConsentDTO[]) => {
		const temp = consentItems.filter((e) => {
			if (e.isValid && e.consentType === ConsentType.UserAgreement) return true;
			return false;
		});
		if (temp && temp.length > 0) return true;
		return false;
	};
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (smsError) {
			return;
		}
		if (emailError && email) {
			return;
		}
		if (!sms) {
			setSMSError(true);
			return;
		}
		if (email && emailAddress.length <= 0) {
			setEmailError(true);
			return;
		}
		if (IntegrationSetting.authRequiredBirthdate && !IntegrationSetting.optionalBirthdateOnConsent && (birthDate.length < 6 || isNaN(Date.parse(birthDate)))) {
			setBirthDateError(true);
			return;
		}
		const submitter = (event.nativeEvent as SubmitEvent).submitter?.id ?? "";
		try {
			switch (submitter) {
				case "confirmall":
					setProcessingAll(true);
					break;
				default:
					setProcessingSelected(true);
					break;
			}

			if (submitter === "confirmall" && emailAddress.length <= 0) {
				setEmailError(true);
				setEmail(true);
				setOffer(true);
				return;
			}
			const data = new FormData(event.currentTarget);
			let sms = ((data.get("sms") as string) ?? "").includes("on");
			let email = ((data.get("email") as string) ?? "").includes("on");
			let offer = ((data.get("offer") as string) ?? "").includes("on");

			if (submitter === "confirmall") {
				setSMS(true);
				setEmail(true);
				setOffer(true);
				sms = true;
				email = true;
				offer = true;
			}

			const consent = await memberContext.consentInfo({ loadAPI: false });

			let originSMS = false,
				originEmail = false,
				originOffer = false;
			let isSMSChanged = false,
				isEmailChanged = false,
				isOfferChanged = false,
				isUserAgreementChanged = false;
			consent.forEach((item) => {
				if (item.isValid) {
					switch (item.consentType) {
						case ConsentType.SMS:
							originSMS = true;
							break;
						case ConsentType.Email:
							originEmail = true;
							break;
						case ConsentType.Offers:
							originOffer = true;
							break;
					}
				}
			});
			try {
				if (!originSMS) {
					await memberContext.updateConsent(ConsentType.SMS, true);
					isSMSChanged = true;
				}
				if (email !== originEmail) {
					await memberContext.updateConsent(ConsentType.Email, email);
					isEmailChanged = true;
				}
				if (offer !== originOffer) {
					await memberContext.updateConsent(ConsentType.Offers, offer);
					isOfferChanged = true;
				}
				if (!consentUserAgreement(consent) && (!IntegrationSetting.authRequiredCard || !IntegrationSetting.authRequiredAccount)) {
					await memberContext.updateConsent(ConsentType.UserAgreement, true);
					isUserAgreementChanged = true;
				}
			} catch (error) {
				log("Member consent update", ConsoleType.Error, sms, email, offer);
			} finally {
				if (isSMSChanged || isEmailChanged || isOfferChanged || isUserAgreementChanged) await memberContext.consentInfo({ loadAPI: true });
			}
			const cachedMember = memberContext.userInfo({ loadAPI: false }) as MemberDTO;
			const bday = birthDate ? new Date(birthDate).toISOString() : cachedMember.birthDate;
			const member: MemberDTO = {
				id: cachedMember.id,
				address: cachedMember.address,
				birthDate: bday,
				cellphoneNumber: cachedMember.cellphoneNumber,
				firstName: cachedMember.firstName ?? "",
				gender: cachedMember.gender,
				lastName: cachedMember.lastName ?? "",
				programTag: cachedMember.programTag ?? "",
				userAgreementAcceptDate: cachedMember.userAgreementAcceptDate ?? new Date().toISOString(),
				emailAddress: emailAddress,
			};
			const gotoBank = IntegrationSetting.authRequiredCard || IntegrationSetting.authRequiredAccount;
			if (gotoBank) await memberContext.cardInfo({ loadAPI: false });
			await memberContext.updateInfo(member, gotoBank, false);
		} catch (error) {
			setProcessingAll(false);
			setProcessingSelected(false);
		} finally {
			setProcessingAll(false);
			setProcessingSelected(false);
		}
	};

	const isAutoInvite = sessionStorage.getItem(Constants.LoyaltyInviteSession) !== null;

	return (
		<React.Fragment>
			<Card sx={{ background: "unset", color: "unset", borderRadius: "inherit", boxShadow: 0, transition: "unset" }}>
				<CardContent sx={{ padding: 2, color: (theme) => theme.palette.text.primary, pt: 2, textAlign: "center" }}>
					{!isAutoInvite && (
						<>
							<Typography variant="h6">
								<Trans>Welcome</Trans>
							</Typography>
							<Typography variant="body2" gutterBottom>
								{userInfo?.firstName + " " + userInfo?.lastName}
							</Typography>
						</>
					)}
					{isAutoInvite && LoadHTML(IntegrationSetting.programId, IntegrationSetting.displayName)}
				</CardContent>
				<CardActions sx={{ pt: 0, pb: 3.5, pr: 2, pl: 2 }}>
					<Box onSubmit={handleSubmit} noValidate component="form" autoComplete="off" sx={{ minWidth: "275px", maxWidth: "275px" }}>
						{IntegrationSetting.emailOnConsent && (
							<InputLabel
								shrink
								htmlFor="emailAddress"
								className="excludeLabel"
								sx={{ color: (theme) => theme.palette.text.primary, fontSize: (theme) => theme.typography.body2.fontSize }}
							>
								Email
							</InputLabel>
						)}
						{IntegrationSetting.emailOnConsent && (
							<TextField
								focused
								defaultValue={emailAddress}
								sx={{
									backgroundColor: (theme) => theme.palette.background.default,
									"& .MuiOutlinedInput-root": {
										color: (theme) => theme.palette.secondary.main,
										height: "30px",
										"& fieldset": {
											borderRadius: 0.25,
										},
									},
									"& :-webkit-autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
									"& :autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
								}}
								id="emailAddress"
								size="small"
								name="emailAddress"
								type="email"
								autoComplete="email"
								fullWidth
								onChange={handleEmailInputChange}
								onBlur={handleEmailBlur}
								disabled={processingAll || processingSelected}
							/>
						)}
						{IntegrationSetting.authRequiredBirthdate && (
							<InputLabel
								shrink
								htmlFor="birthDate"
								className="excludeLabel"
								sx={{ mt: 1, color: (theme) => theme.palette.text.primary, fontSize: (theme) => theme.typography.body2.fontSize }}
							>
								<Trans>Birth Date</Trans>
							</InputLabel>
						)}
						{IntegrationSetting.authRequiredBirthdate && (
							<TextField
								required={!IntegrationSetting.optionalBirthdateOnConsent}
								defaultValue={birthDate && format(new Date(birthDate), "yyyy-MM-dd")}
								inputProps={{
									type: "date",
								}}
								sx={{
									backgroundColor: (theme) => theme.palette.background.default,
									"& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
										color: "transparent",
									},
									"& .MuiOutlinedInput-root": {
										color: (theme) => theme.palette.secondary.main,
										height: "30px",
										"& fieldset": {
											borderRadius: 0.25,
										},
									},
									"& :-webkit-autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
									"& :autofill": {
										WebkitBoxShadow: "0 0 0 1000px white inset",
										WebkitTextFillColor: "#000",
										borderRadius: "5px",
										maxHeight: "10px",
									},
								}}
								variant="outlined"
								id="birthDate"
								size="small"
								name="birthDate"
								type="date"
								autoComplete="bday"
								InputLabelProps={{
									shrink: true,
								}}
								fullWidth
								onChange={handleBirthdateInputChange}
								disabled={processingAll || processingSelected}
							/>
						)}
						<Box sx={{ display: "flex", pt: 2.5 }}>
							<FormGroup>
								<FormControlLabel
									sx={{
										pb: 1,
										ml: 0,
										"& p": { pl: 2 },
									}}
									control={
										<Switch
											sx={{
												"& .MuiSwitch-switchBase + .MuiSwitch-track": {
													backgroundColor: (theme) => theme.palette.secondary.main,
												},
											}}
											size="small"
											onChange={handleChange}
											name="sms"
											checked={sms}
											disabled={processingAll || processingSelected}
										/>
									}
									label={
										<Typography variant="body2">
											<Trans>I agree to communication via SMS.</Trans> *
										</Typography>
									}
								/>
								<FormControlLabel
									sx={{
										pb: 1,
										ml: 0,
										"& p": { pl: 2 },
									}}
									control={
										<Switch
											sx={{
												"& .MuiSwitch-switchBase + .MuiSwitch-track": {
													backgroundColor: (theme) => theme.palette.secondary.main,
												},
											}}
											size="small"
											onChange={handleChange}
											name="email"
											checked={email}
											disabled={processingAll || processingSelected}
										/>
									}
									label={
										<Typography variant="body2">
											<Trans>I agree to communication via E-mail.</Trans>
										</Typography>
									}
								/>
								<FormControlLabel
									sx={{
										pb: 1,
										ml: 0,
										"& p": { pl: 2 },
									}}
									control={
										<Switch
											sx={{
												"& .MuiSwitch-switchBase + .MuiSwitch-track": {
													backgroundColor: (theme) => theme.palette.secondary.main,
												},
											}}
											size="small"
											onChange={handleChange}
											name="offer"
											checked={offer}
											disabled={processingAll || processingSelected}
										/>
									}
									label={
										<Typography variant="body2">
											<Trans>I'd like to receive personalized information.</Trans>
										</Typography>
									}
								/>
							</FormGroup>
						</Box>
						{smsError && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>We should have a right for sms communication.</Trans>
								</Typography>
							</Box>
						)}
						{emailError && email && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>Please enter correct email address.</Trans>
								</Typography>
							</Box>
						)}
						{birthDateError && (
							<Box sx={{ pl: "3px" }}>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>Please enter your birthday.</Trans>
								</Typography>
							</Box>
						)}
						<Box sx={{ display: "flex", flexDirection: "column", pt: 2 }}>
							<TermsAndConditions />
							<br />
							<Button
								sx={{
									bgcolor: (theme) => theme.palette.primary.dark,
									color: (theme) => theme.palette.text.secondary,
									border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
									marginBottom: 2,
									height: "30px",
								}}
								type="submit"
								variant="contained"
								size="small"
								id="confirmall"
								name="confirmall"
								onClick={() => handleSubmit}
								disabled={processingSelected || processingAll}
							>
								{processingAll && <CircularStatic visible={processingAll} />}
								{!processingAll && <Trans>Confirm all and verify profile</Trans>}
							</Button>
							<Link
								id="confirmselected"
								name="confirmselected"
								disabled={processingAll || processingSelected}
								textTransform={"unset"}
								href="#"
								underline="hover"
								variant="overline"
								component="button"
								onClick={() => handleSubmit}
								color="text.primary"
								sx={{
									color: (theme) => theme.palette.text.primary,
									fontSize: (theme) => theme.typography.subtitle1.fontSize,
									fontWeight: (theme) => theme.typography.h1.fontWeight,
									lineHeight: (theme) => theme.typography.h1.lineHeight,
								}}
							>
								{processingSelected && <CircularStatic visible={processingSelected} />}
								{!processingSelected && <Trans>Confirm selected and verify profile</Trans>}
							</Link>
						</Box>
					</Box>
				</CardActions>
			</Card>
		</React.Fragment>
	);
}

export function WelcomeUI() {
	const widgetSettingsCtx = useContext(ThemeSettingsContext);
	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	let customizedStyle = Object.fromEntries((widgetSettingsCtx.positionFull as string[]).map((s) => s.split(":")));

	const IsMobile = width <= 1024;
	if (IsMobile) {
		delete customizedStyle["width"];
		delete customizedStyle["minWidth"];
		delete customizedStyle["maxWidth"];
	}
	delete customizedStyle["height"];
	return (
		<Box
			sx={{
				display: "flex",
				boxShadow: "0px 0px 28px 1px rgba(0,0,0,0.74)",
				WebkitBoxShadow: "0px 0px 28px 1px rgba(0,0,0,0.74)",
				MozBoxShadow: "0px 0px 28px 1px rgba(0,0,0,0.74)",
				opacity: ".96",
				borderRadius: "20px",
			}}
		>
			<Paper
				sx={{
					width: "100vw",
					...customizedStyle,
				}}
			>
				<Consent />
			</Paper>
		</Box>
	);
}
