import * as React from "react";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
	//{`${Math.round(props.value)}%`}
	return (
		<Box sx={{ position: "relative", display: "inline-flex" }}>
			<CircularProgress variant="determinate" {...props} sx={{ color: "warning.main" }} size={30} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Typography variant="body2" component="div" color="text.main"></Typography>
			</Box>
		</Box>
	);
}

function CircularProgressForLevel(props: CircularProgressProps & { value: number }) {
	return (
		<Box sx={{ position: "relative", display: "inline-flex" }}>
			<CircularProgress variant="determinate" {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Typography variant="caption" component="div" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

export function CircularLevel(props: any & { visible: boolean }) {
	const [progress, setProgress] = React.useState(0);

	React.useEffect(() => {
		if (progress === props.value) {
			setProgress(props.value);
			props.onReset(0);
			return;
		}
		const intervalId = setInterval(() => {
			setProgress(progress + 1);
		}, 100);
		return () => {
			clearInterval(intervalId);
		};
	}, [progress]);
	if (props.visible) return <CircularProgressForLevel value={progress} />;
	return null;
}

export default function CircularStatic(props: any & { visible: boolean }) {
	const [progress, setProgress] = React.useState(100);

	React.useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
		}, 500);
		return () => {
			clearInterval(timer);
		};
	}, []);
	if (props.visible) return <CircularProgressWithLabel value={progress} />;

	return null;
}
