import { ConsoleType } from "../types/enum";

export function log(message: string, type: ConsoleType, ...o: any[]) {
	if ((window as any).DebugMode) {
		switch (type) {
			case 0: // error
				console.log("%c" + message, "background: red; color: #fff", o);
				break;
			case 1: // trace log
				console.log("%c" + message, "background: #87bf8a; color: #000", o);
				break;
			case 2: // http response
				console.info("%c" + message, "background: #222; color: #bada55", o);
				break;
			case 3: // warning
				console.warn("%c" + message, "background: #4f560f; color: #e6ff07", o);
				break;
			default:
				console.info("%c" + message, "background: #d9d9d9; color: #a82868; font-weight: bold;", o);
		}
	}
}
