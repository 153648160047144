import React, { useState } from "react";
import { WidgetMode, WidgetSize } from "../types/enum";
import { IStorage } from "../types/interface";
import { IntegrationSettings } from "./integrationsettingscontext";

export const CreateUserContext = (storage: IStorage, isCtx: IntegrationSettings) => {
	const [widgetMode, setWidgetMode] = useState(storage.getItem("WidgetMode") || "");
	const [widgetModeState, setWidgetModeState] = useState<WidgetMode>(WidgetMode.Open);

	const [widgetSize, setWidgetSize] = useState(storage.getItem("WidgetSize") || "");
	const [widgetSizeState, setWidgetSizeState] = useState<WidgetSize>(
		isCtx.postLoginWidgetSize ?? window.innerWidth <= 1024 ? WidgetSize.Full : WidgetSize.Main
	);

	return {
		widgetMode: widgetMode,
		widgetSize: widgetSize,

		setWidgetMode: (x: string): void => setWidgetMode(x),
		setWidgetSize: (x: string): void => setWidgetSize(x),

		toggleWidgetMode: (currentState: WidgetMode) => {
			let newState: WidgetMode;
			switch (currentState) {
				case WidgetMode.Close:
					newState = WidgetMode.Open;
					break;
				case WidgetMode.Open:
					newState = WidgetMode.Close;
					break;
				default:
					newState = WidgetMode.Open;
			}

			storage.setItem("WidgetMode", newState);
			setWidgetMode(newState);
			setWidgetModeState(newState);
			isCtx.onEvent({ EventType: "ToggleWidgetMode", Payload: { WidgetModeState: newState } });
		},

		changeWidgetSize: (newState: WidgetSize) => {
			storage.setItem("WidgetSize", newState);
			setWidgetSize(newState);
			setWidgetSizeState(newState);
			isCtx.onEvent({ EventType: "ChangeWidgetSize", Payload: { WidgetModeState: newState } });
		},

		widgetModeState: () => {
			if (!widgetMode) return widgetModeState;

			const state = widgetMode as unknown as WidgetMode;

			if (state) return state;

			return widgetModeState;
		},

		widgetSizeState: () => {
			if (!widgetSize) return widgetSizeState;

			const state = widgetSize as unknown as WidgetSize;

			if (state) return state;

			return widgetSizeState;
		},
	};
};

export interface IWidgetContext {
	widgetModeState: () => WidgetMode;
	toggleWidgetMode: (currentState: WidgetMode) => void;
	widgetSizeState: () => WidgetSize;
	changeWidgetSize: (newState: WidgetSize) => void;
}

export const UserContext = React.createContext<IWidgetContext>({} as IWidgetContext);
