import { CreateMrcTokenResponse } from "../apiclient/MemberAPI";
import { ConsoleType } from "../types/enum";
import { JWTResponseModel } from "../types/interface";
import { log } from "./clientlog";

export function parseJWT(token: string): JWTResponseModel | null {
	var base64Url = token.split(".");
	if (base64Url.length <= 1) return null;
	var base64UrlToken = base64Url[1];
	var base64 = base64UrlToken.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);
	return JSON.parse(jsonPayload);
}

export const getTokenByMRC = async (authContext: any, MRC: string): Promise<string> => {
	let token = null;
	try {
		token = await authContext.generateToken(MRC);
	} catch (e) {
		Promise.reject(e);
	} finally {
		log("getTokenByMRC:", ConsoleType.Info, token !== null);
		return Promise.resolve((token as CreateMrcTokenResponse).token ?? "");
	}
};

export const checkToken = (token: string) => {
	const jwt = parseJWT(token);
	if (!jwt) {
		log("no token", ConsoleType.Warning, jwt);
		return false;
	}
	if (Date.now() >= jwt.exp * 1000) {
		log("expired token", ConsoleType.Warning, jwt);
		return false;
	}
	return true;
};
