import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 0, pt: 4 }}>
					<Box>{children}</Box>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export function ThreeTabs(props: any) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const CustomTabLeft = styled(Tab)(({ theme }) => ({
		...theme.typography.caption,
		backgroundColor: value === 0 ? theme.palette.background.paper : theme.palette.primary.light,
		borderTopLeftRadius: "50px",
		borderBottomLeftRadius: "50px",
		zIndex: 1,
		"&.MuiButtonBase-root.MuiTab-root": {
			color: value === 0 ? theme.palette.text.primary : theme.palette.text.secondary,
		},
		"&:hover": { backgroundColor: value === 0 ? theme.palette.background.paper : theme.palette.primary.light },
	}));

	const CustomTabMiddle = styled(Tab)(({ theme }) => ({
		...theme.typography.caption,
		backgroundColor: value === 1 ? theme.palette.background.paper : theme.palette.primary.light,
		zIndex: 1,
		"&.MuiButtonBase-root.MuiTab-root": {
			color: value === 1 ? theme.palette.text.primary : theme.palette.text.secondary,
		},
		"&:hover": { backgroundColor: value === 1 ? theme.palette.background.paper : theme.palette.primary.light },
	}));

	const CustomTabRight = styled(Tab)(({ theme }) => ({
		...theme.typography.caption,
		backgroundColor: value === 2 ? theme.palette.background.paper : theme.palette.primary.light,
		borderTopRightRadius: "50px",
		borderBottomRightRadius: "50px",
		zIndex: 1,
		"&.MuiButtonBase-root.MuiTab-root": {
			color: value === 2 ? theme.palette.text.primary : theme.palette.text.secondary,
		},
		"&:hover": { backgroundColor: value === 2 ? theme.palette.background.paper : theme.palette.primary.light },
	}));

	return (
		<Box sx={{ minWidth: "275px", maxWidth: "275px", mt: 7 }}>
			<Tabs
				variant="fullWidth"
				sx={{
					borderRadius: "50px",
				}}
				value={value}
				TabIndicatorProps={{
					sx: { backgroundColor: (theme) => theme.palette.background.paper, width: "0 !important" },
				}}
				onChange={handleChange}
			>
				<CustomTabLeft sx={{ padding: 0, textTransform: "none", minHeight: "60px" }} label={props.Label1} {...a11yProps(0)} />
				<CustomTabMiddle sx={{ padding: 0, textTransform: "none", minHeight: "60px" }} label={props.Label2} {...a11yProps(1)} />
				<CustomTabRight sx={{ padding: 0, textTransform: "none", minHeight: "60px" }} label={props.Label3} {...a11yProps(2)} />
			</Tabs>
			<TabPanel value={value} index={0}>
				{props.Panel1}
			</TabPanel>
			<TabPanel value={value} index={1}>
				{props.Panel2}
			</TabPanel>
			<TabPanel value={value} index={2}>
				{props.Panel3}
			</TabPanel>
		</Box>
	);
}

export function TwoTabs(props: any) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const CustomTabLeft = styled(Tab)(({ theme }) => ({
		...theme.typography.caption,
		backgroundColor: value === 0 ? theme.palette.background.paper : theme.palette.primary.light,
		borderTopLeftRadius: "50px",
		borderBottomLeftRadius: "50px",
		zIndex: 1,
		"&.MuiButtonBase-root.MuiTab-root": {
			color: value === 0 ? theme.palette.text.primary : theme.palette.text.secondary,
		},
		"&:hover": { backgroundColor: value === 0 ? theme.palette.background.paper : theme.palette.primary.light },
	}));

	const CustomTabRight = styled(Tab)(({ theme }) => ({
		...theme.typography.caption,
		backgroundColor: value === 1 ? theme.palette.background.paper : theme.palette.primary.light,
		borderTopRightRadius: "50px",
		borderBottomRightRadius: "50px",
		zIndex: 1,
		"&.MuiButtonBase-root.MuiTab-root": {
			color: value === 1 ? theme.palette.text.primary : theme.palette.text.secondary,
		},
		"&:hover": { backgroundColor: value === 1 ? theme.palette.background.paper : theme.palette.primary.light },
	}));

	return (
		<Box sx={{ minWidth: "275px", maxWidth: "275px", mt: 7 }}>
			<Tabs
				variant="fullWidth"
				sx={{
					borderRadius: "50px",
				}}
				value={value}
				TabIndicatorProps={{
					sx: { backgroundColor: (theme) => theme.palette.background.paper, width: "0 !important" },
				}}
				onChange={handleChange}
			>
				<CustomTabLeft sx={{ padding: 0, textTransform: "none", minHeight: "60px" }} label={props.Label1} {...a11yProps(0)} />
				<CustomTabRight sx={{ padding: 0, textTransform: "none", minHeight: "60px" }} label={props.Label2} {...a11yProps(1)} />
			</Tabs>
			<TabPanel value={value} index={0}>
				{props.Panel1}
			</TabPanel>

			<TabPanel value={value} index={1}>
				{props.Panel2}
			</TabPanel>
		</Box>
	);
}
