import * as React from "react";
import { t } from "@lingui/macro";
import { DialogTitle, DialogContent, DialogActions, Button, Dialog, Typography } from "@mui/material";

export interface ConfirmationDialogRawProps {
	id: string;
	keepMounted: boolean;
	dataValue: string;
	showValue: string;
	title: string;
	open: boolean;
	onClose: (value?: string) => Promise<void>;
}

export function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
	const { onClose, dataValue: dataValueProp, showValue: showValueProp, open, ...other } = props;
	const [dataValue, setDataValue] = React.useState(dataValueProp);
	const [showValue, setShowValue] = React.useState(showValueProp);

	React.useEffect(() => {
		setDataValue(dataValueProp);
		if (!open) {
			setDataValue(dataValueProp);
		}
	}, [dataValueProp, open]);

	React.useEffect(() => {
		setShowValue(showValueProp);
		if (!open) {
			setShowValue(showValueProp);
		}
	}, [showValueProp, open]);

	const handleCancel = async () => {
		await onClose();
	};

	const handleOk = async () => {
		await onClose(dataValue);
	};

	return (
		<Dialog sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }} maxWidth="xs" open={open} {...other}>
			<DialogTitle variant="caption" textAlign={"justify"}>
				Warning!
				<br />
				{t`If you remove card/account from your profile, we will be unable to match your purchase and grant you bonus points automatically.`}
			</DialogTitle>
			<DialogContent dividers>
				<Typography>
					{props.title} number: {showValue ?? props.showValue}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					sx={{
						bgcolor: (theme) => theme.palette.primary.dark,
						color: (theme) => theme.palette.text.secondary,
						border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
						marginBottom: 2,
						height: "30px",
					}}
					autoFocus
					onClick={handleCancel}
				>
					Cancel
				</Button>
				<Button
					sx={{
						bgcolor: (theme) => theme.palette.primary.dark,
						color: (theme) => theme.palette.text.secondary,
						border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
						marginBottom: 2,
						height: "30px",
					}}
					onClick={handleOk}
				>
					Remove {props.title}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
