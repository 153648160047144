import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Trans } from "@lingui/macro";
import { APIContext } from "../context/apicontext";
import { useContext, useState } from "react";
import { Button, Link, styled } from "@mui/material";
import { TopBarIcons } from "../components/topbar";
import React from "react";
import CircularStatic from "../components/progress";
import { AuthenticationTypes, AuthState } from "../types/enum";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { TermsAndConditions } from "../components/tc";

const NoBorderTextField = styled(TextField)`
	& .MuiOutlinedInput-root {
		& fieldset {
			border: unset;
		}
	}
`;

export function VerificationUI() {
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const state = authContext.authState();

	const [value, setValue] = useState("");
	const [error, setError] = useState(false);
	const [processing, setProcessing] = useState<boolean>(false);

	const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setValue(event.target.value);
		setError(false);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		setProcessing(true);
		try {
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			await authContext.loginValidate({
				Type: AuthenticationTypes.Mobile,
				Phone: authContext.phoneNumber() as string,
				VerificationCode: data.get("token") as string,
			});
			setError(false);
			if (state === AuthState.MemberVerificationFailed || state === AuthState.NonMemberVerificationFailed) setError(true);
		} finally {
			setProcessing(false);
		}
	};

	const goBack = () => {
		authContext.logout();
	};

	return (
		<>
			<TopBarIcons Visibility={IntegrationSetting.topbarOnLoginRouter} IgnoreResizeOnLoginRouter IconSize="small" IconName="loyall" Transform="scale(1);" />
			<Card
				sx={{
					background: "unset",
					color: "unset",
					borderBottomLeftRadius: "inherit",
					borderBottomRightRadius: "inherit",
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					boxShadow: 0,
				}}
			>
				<CardContent sx={{ padding: 0, color: (theme) => theme.palette.text.primary, pt: 2 }}>
					<Typography variant="h6">
						<Trans>Verification code</Trans>
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
						sx={{
							maxWidth: state === AuthState.Unregistered || state === AuthState.NonMemberVerificationFailed ? "unset" : "275px",
							textAlign: state === AuthState.Unregistered || state === AuthState.NonMemberVerificationFailed ? "unset" : "center",
						}}
					>
						<Trans>Please fill in verification code received by SMS.</Trans>
					</Typography>
					<Typography>
						<Link
							textTransform={"unset"}
							href="#"
							underline="hover"
							variant="overline"
							component="button"
							onClick={() => {
								goBack();
							}}
							color="text.primary"
						>
							<Trans>Change phone number?</Trans>
						</Link>
					</Typography>
				</CardContent>
				<CardActions sx={{ pt: 0, pb: 5, pr: 2, pl: 2 }}>
					<Box onSubmit={handleSubmit} noValidate component="form" autoComplete="off" sx={{ minWidth: "275px", maxWidth: "275px" }}>
						<NoBorderTextField
							margin="normal"
							required
							variant="outlined"
							size="small"
							placeholder="000000"
							name="token"
							id="token"
							type="text"
							autoComplete="one-time-code" //FIXME: "one-time-code"
							onChange={handleChange}
							fullWidth
							sx={{
								bgcolor: (theme) => theme.palette.background.default,
							}}
							autoFocus
							InputProps={{
								sx: {
									color: (theme) => theme.palette.secondary.main,
									height: "30px",
									fontSize: (theme) => theme.typography.subtitle2.fontSize,
								},
							}}
						/>
						{(state === AuthState.Unregistered || state === AuthState.NonMemberVerificationFailed) && (
							<Box
								sx={{
									display: state === AuthState.Unregistered || state === AuthState.NonMemberVerificationFailed ? "flex" : "none",
									pt: "5px",
									pb: 1,
								}}
							>
								<TermsAndConditions />
							</Box>
						)}
						{(state === AuthState.MemberVerificationFailed || state === AuthState.NonMemberVerificationFailed || error) && (
							<Box>
								<Typography variant="caption" display="block" gutterBottom color="warning.light">
									<Trans>Wrong Code.</Trans>
								</Typography>
							</Box>
						)}
						<Box sx={{ pt: "5px", pb: 1 }}>
							<Button
								sx={{
									bgcolor: (theme) => theme.palette.primary.dark,
									color: (theme) => theme.palette.text.secondary,
									marginTop: 0,
									height: "30px",
									border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
									"&.Mui-disabled": {
										color: (theme) => theme.palette.secondary.main,
										backgroundColor: (theme) => theme.palette.background.default,
										border: "unset",
										opacity: 0.8,
									},
								}}
								type="submit"
								fullWidth
								variant="contained"
								size="small"
								//onClick={() => setElement("button")}
								disabled={!value || processing}
							>
								{processing && <CircularStatic visible={processing} />}
								{!processing && <Trans>Confirm</Trans>}
							</Button>
						</Box>
					</Box>
				</CardActions>
			</Card>
		</>
	);
}
