import { log } from "../service/clientlog";
import React, { useState } from "react";
import { i18n } from "@lingui/core";
import { detect, fromNavigator, fromStorage, fromUrl } from "@lingui/detect-locale";
import { IStorage } from "../types/interface";
import { ConsoleType } from "../types/enum";
import * as plurals from "make-plural/plurals";

// can be a function with custom logic or just a string, `detect` method will handle it

const pluralsData: Record<string, Function> = require("make-plural/plurals");

/* enable translations for a specific language */
async function activate(locale: string) {
	locale = locale.split("-")[0];
	if (locale === "nb") locale = "no";
	const { messages } = await import(`../locales/${locale}/messages.js`);
	const t = pluralsData[locale] as typeof plurals.en;
	setTimeout(() => {
		i18n.loadLocaleData(locale, { plurals: t });
		i18n.load(locale, messages);
		i18n.activate(locale);
	}, 0); // NOTE: Workaround to avoid an error from react in the js-console.
}

export const LanguageContextDefaults = function (storage: IStorage, defaultLanguageCode: string) {
	let lang = detect(fromUrl("lang"), fromStorage(storage.prefix + "_lang"), fromNavigator(), defaultLanguageCode);
	const [language, setLanguage] = useState(lang as string);
	activate(language).catch((err) => {
		log(err, ConsoleType.Error);
		activate(defaultLanguageCode).then(() => {
			log("Activated default from integration setting", ConsoleType.Info, defaultLanguageCode);
		});
	});

	return {
		language: language,
		setLanguage: (l: string) => {
			log("switching to language", ConsoleType.Info, l);
			setLanguage(l);
			storage.setItem("lang", l);
			activate(l).catch((err) => log(err, ConsoleType.Error));
		},
	};
};

export const LanguageContext = React.createContext({
	language: "en",
	setLanguage: (theme: string) => {},
});
