import { log } from "../service/clientlog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Trans } from "@lingui/macro";
import { APIContext } from "../context/apicontext";
import { useContext, useState } from "react";
import { Button, Link } from "@mui/material";
import { TopBarIcons } from "../components/topbar";
import React from "react";
import CircularStatic from "../components/progress";
import { SSOCallbackHandler } from "../service/ApiService";
import { AuthenticationTypes, AuthState, ConsoleType } from "../types/enum";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";

// remained items:

interface Props {
	SSOType: string;
	SSOCode: string;
}

export function SSO(props: Props) {
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const [error, setError] = useState(false);

	let code = "";
	let type = "";
	if (props) {
		code = props.SSOCode ?? "";
		type = props.SSOType ?? "";
	}
	if (code.length === 0) {
		const callbackURL = SSOCallbackHandler();
		code = callbackURL.Token ?? "";
		type = callbackURL.Type ?? "";
	}

	if (code.length === 0) setError(true);

	const authContext = useContext(APIContext);
	const state = authContext.authState();

	const [processing, setProcessing] = useState<boolean>(false);
	const [ssoLogin, setSSOLogin] = useState<Promise<boolean>>();

	if (!ssoLogin && !error)
		setSSOLogin(async () => {
			setProcessing(true);
			try {
				await authContext.loginValidate({
					Type: AuthenticationTypes.Vipps,
					VippsCode: code,
				});
				setError(false);
			} catch (e) {
				setError(true);
			} finally {
				setProcessing(false);
				state === AuthState.SSOVerificationFailed && setError(true);
			}
			log(`Login via VIPPS: code=${code} - type=${type} - error=${error} - state=${state}`, ConsoleType.Info);
			return Promise.resolve(error);
		});

	// useEffect(() => {
	// 	let isMounted = true;
	// 	if (country === null) {
	// 		if (isMounted)
	// 			fetch("https://api.country.is")
	// 				.then((data) => data.json())
	// 				.then((data) => updateDefaultPhoneCountry(data.country));
	// 	}
	// 	return () => {
	// 		isMounted = false;
	// 	};
	// }, [country]);

	const handleClick = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setProcessing(true);
		try {
			authContext.logout();
			const url = await authContext.vippsStart(window.location.href);
			if (url) window.location.replace(url);
		} catch (e) {
			setError(true);
		} finally {
			setProcessing(false);
		}
	};

	const goBack = () => {
		authContext.logout();
	};

	return (
		<React.Fragment>
			<TopBarIcons Visibility={IntegrationSetting.topbarOnLoginRouter} IgnoreResizeOnLoginRouter IconSize="small" IconName="loyall" Transform="scale(1);" />
			<Card
				sx={{
					background: "unset",
					color: "unset",
					borderBottomLeftRadius: "inherit",
					borderBottomRightRadius: "inherit",
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					boxShadow: 0,
				}}
			>
				<CardContent sx={{ padding: 5, color: (theme) => theme.palette.text.primary, borderRadius: "inherit" }}>
					<Typography variant="h6">
						<Trans>Single sign-on</Trans>
					</Typography>
					{!error || state === AuthState.PendingSSOVerification ? (
						<Typography variant="h6">
							<Trans>Please wait. Login in process ...</Trans>
						</Typography>
					) : (
						<Typography variant="h6">
							<Trans>{type !== "" && type === "vipps" ? "VIPPS" : "SSO"} now has problem.</Trans>
						</Typography>
					)}
					{(error || state === AuthState.SSOVerificationFailed) && (
						<Typography>
							<Link
								href="#"
								underline="hover"
								variant="overline"
								component="button"
								onClick={() => {
									goBack();
								}}
								color="text.primary"
							>
								<Trans>Try another login?</Trans>
							</Link>
						</Typography>
					)}
				</CardContent>
				<CardActions sx={{ pt: 0 }}>
					<Box noValidate={false} component="form" autoComplete="off" onSubmit={handleClick}>
						<Box>
							<Button
								sx={{
									bgcolor: (theme) => theme.palette.primary.dark,
									color: (theme) => theme.palette.text.secondary,
									marginTop: 0,
								}}
								type="submit"
								fullWidth
								variant="contained"
								size="small"
							>
								{processing && <CircularStatic visible={processing} />}
								{!processing && <Trans>Try again</Trans>}
							</Button>
						</Box>
					</Box>
				</CardActions>
			</Card>
		</React.Fragment>
	);
}
