import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { log } from "../service/clientlog";
import { ConsoleType } from "../types/enum";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number; clock: string }) {
	return (
		<Box sx={{ margin: "unset", textAlign: "center", width: "75%" }}>
			<Typography variant="body1" color="background.paper">{`${props.clock}`}</Typography>
			<LinearProgress color="success" variant="determinate" {...props} />
		</Box>
	);
}

export default function LinearWithValueLabel(props: any) {
	const [progress, setProgress] = useState(0);
	const [clockInfo, setClockInfo] = useState("00:00");

	const d = new Date();
	const future = d.setSeconds(d.getSeconds() + props.maxInSecond + 1);
	const countDownDate = new Date(future).getTime();
	const normalize = (value: number) => ((value - 0) * 100) / (props.maxInSecond - 0);

	useEffect(() => {
		const timer = setInterval(() => {
			let now = new Date().getTime();
			let distance = countDownDate - now;

			let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			let seconds = Math.floor((distance % (1000 * 60)) / 1000) + 1;
			let prefix = minutes < 10 ? "0" : "";
			let suffix = seconds < 10 ? "0" : "";
			seconds = seconds === 60 ? 59 : seconds;
			setClockInfo(prefix + minutes + ":" + suffix + seconds);
			setProgress((prevProgress) => {
				if (prevProgress === props.maxInSecond) {
					const expireWithLoadingInfo = async () => {
						await props.expired();
						clearInterval(timer);
						props.activation(false);
					};
					expireWithLoadingInfo()
						.catch((e) => log("expireWithLoadingInfo:", ConsoleType.Error, e))
						.finally(() => {
							log("success expiration", ConsoleType.Info);
						});
				}
				return prevProgress <= props.maxInSecond ? prevProgress + 1 : 0;
			});
		}, 1000);
		return () => {
			clearInterval(timer);
		};
	}, []);

	return <LinearProgressWithLabel value={normalize(progress)} clock={clockInfo} />;
}
