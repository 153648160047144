import { Box, Stack, styled, Paper, Button, Typography } from "@mui/material";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import { t, Trans } from "@lingui/macro";
import { VoucherDTO } from "../apiclient/MemberAPI";
import { createRef, useContext, useState } from "react";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { TextTemplate } from "../service/jsonreader";
import { hexToRGBA } from "../utils/themeStyling";
import { i18n } from "@lingui/core";
import { useEffect } from "react";
import { GiftResponseDTO } from "../types/type";
import { BenefitReleaseModes, BenefitTypes, Components, TemplateElements, WidgetSize } from "../types/enum";
import CircularStatic from "./progress";
import { BenefitTimerBox } from "./benefittimer";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: "transparent",
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.secondary,
	boxShadow: "0 0",
}));

interface Props {
	giftInfo: VoucherDTO | null;
	size: WidgetSize;
	idx: number;
	update: any;
}

export function GiftBox(props: Props) {
	const isCtx = useContext(IntegrationSettingsContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const ref = window.location.host;
	const inputRef = createRef<any>();
	const [giftRelease, setGiftRelease] = useState<GiftResponseDTO>({ Message: "", Reference: "", Result: false, Value: "" });
	const [processing, setProcessing] = useState<boolean>(false);
	const [benefitByTimer, setBenefitByTimer] = useState<boolean>(false);

	useEffect(() => {
		if (!processing) {
			const res = isCtx.getGiftReleased(ref, props.giftInfo?.reference ?? "");
			if (res.Result) {
				const giftReleaseResponseMSG = /*i18n*/ i18n._(`${res.Message}`);
				const giftReleaseResponse: GiftResponseDTO = {
					Message: i18n._(giftReleaseResponseMSG) + " 🙂",
					Reference: res.Reference,
					Result: res.Result,
					Value: res.Value,
				};
				setGiftRelease(giftReleaseResponse);
			}
		}
	}, [processing, isCtx, ref, props.giftInfo?.reference]);

	const tooltip = t`By activating you can't undo`;
	const GetNoneReleasableText = (component: Components, level: number) => {
		let text = TextTemplate.GetTextTemplate(component, IntegrationSetting.customer, TemplateElements.texts, level, undefined);
		if (!text) return "";
		const translated = /*i18n*/ i18n._(`${text}`);
		return i18n._(translated) + " 🙂";
	};

	let giftMaxVal = props.giftInfo?.amount ?? 0;
	giftMaxVal = Number(giftMaxVal.toFixed(2));
	const id = props.idx.toString();

	const requestGift = async (timer: boolean) => {
		if (!timer) {
			if (props.giftInfo && props.giftInfo?.reference && props.giftInfo?.amount) {
				isCtx.onEvent({ EventType: "onGiftReserved", Payload: { Ref: "Gift", GiftReference: props.giftInfo?.reference } });
				setProcessing(true);
				const response = await isCtx.onGiftReserved(ref, props.giftInfo?.reference, props.giftInfo?.amount.toString());
				setProcessing(false);
				if (response as GiftResponseDTO) {
					const message = /*i18n*/ i18n._(`${response.Message}`);
					const translatedResponse: GiftResponseDTO = {
						Message: i18n._(message) + " 🙂",
						Result: response.Result,
						Value: response.Value,
						Reference: response.Reference,
					};
					if (inputRef?.current?.id === translatedResponse.Reference) setGiftRelease(translatedResponse);
				}
			}
		}
		if (timer && props.giftInfo && props.giftInfo.canRelease && props.giftInfo.amount && props.giftInfo.amount > 0) setBenefitByTimer(true);
	};

	let discountCode = "";
	if (giftRelease && giftRelease.Result && giftRelease.Message.length > 0) {
		try {
			discountCode = JSON.parse(localStorage.getItem(`${IntegrationSetting.storageId}_Gift_${props.giftInfo?.reference}_${ref}`) ?? "").title;
			if (typeof discountCode === "undefined" || !discountCode) {
				discountCode = JSON.parse(JSON.parse(localStorage.getItem(`${IntegrationSetting.storageId}_Gift_${props.giftInfo?.reference}_${ref}`) ?? "")).title;
			}
		} catch {}
	}

	if (!giftRelease.Result && !props.giftInfo?.canRelease) {
		return null;
	}
	const currency = IntegrationSetting.product.Currency ?? ",-";
	const releaseByTimer = IntegrationSetting.releaseMode === BenefitReleaseModes.ByTimer;
	const showNoReleasableText = !IntegrationSetting.enableReleaseGift && IntegrationSetting.noReleasableWithText;

	return (
		<>
			{benefitByTimer && props.giftInfo && props.giftInfo.amount && (
				<BenefitTimerBox
					benefitType={BenefitTypes.Gift}
					widgetSize={props.size}
					requestAmount={props.giftInfo.amount}
					voucherInfo={props.giftInfo}
					idx={props.idx}
					update={props.update}
				/>
			)}
			{!benefitByTimer && (
				<Box
					id={props.giftInfo?.reference ?? "0"}
					ref={inputRef}
					title={tooltip}
					sx={{ height: processing ? "30px" : "unset", margin: processing ? "0 auto" : "unset", textAlign: processing ? "center" : "unset" }}
				>
					{processing && <CircularStatic visible={processing} />}
					{!processing && (
						<Stack spacing={0}>
							{!giftRelease.Result && (
								<Item
									id={"giftbox-item0-" + id}
									sx={{
										pt: "16px",
										pl: "16px",
										pr: "16px",
										borderBottomRightRadius: 0,
										borderBottomLeftRadius: 0,
										backgroundColor: (theme) => {
											return hexToRGBA(theme.palette.background.paper, "0.1");
										},
									}}
								>
									<Stack
										direction="row"
										sx={{
											justifyContent: "space-between",
										}}
									>
										<Item
											sx={{
												color: (theme) => theme.palette.secondary.main,
												fontWeight: "unset",
												fontSize: "12px",
												backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
											}}
										>
											<Trans>Giftcard</Trans>
										</Item>
										<Item sx={{ fontSize: "9px", backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))" }}>
											<Trans>Valid until:</Trans> {props.giftInfo?.validTo?.substring(0, 10)}
										</Item>
									</Stack>
								</Item>
							)}
							{!giftRelease.Result && (
								<Item
									id={"giftbox-item1-" + id}
									sx={{
										pt: "8px",
										pl: "16px",
										pr: "16px",
										borderRadius: 0,
										backgroundColor: (theme) => {
											return hexToRGBA(theme.palette.background.paper, "0.1");
										},
									}}
								>
									<Stack direction="row" spacing={0}>
										<Item
											sx={{
												ml: "-5px",
												backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
											}}
										>
											<CardGiftcardOutlinedIcon
												sx={{
													color: (theme) => theme.palette.secondary.main,
													fontSize: "22px",
												}}
											/>
										</Item>
										<Item
											sx={{
												color: (theme) => theme.palette.secondary.main,
												mt: "-2px",
												fontWeight: "600",
												fontSize: "20px",
												backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
												pl: 1,
											}}
										>
											{giftMaxVal}
											{currency}
										</Item>
									</Stack>
								</Item>
							)}
							{!giftRelease.Result && (
								<Item
									id={"giftbox-item2-" + id}
									sx={{
										pt: "8px",
										pl: "16px",
										pr: "16px",
										borderRadius: 0,
										backgroundColor: (theme) => {
											return hexToRGBA(theme.palette.background.paper, "0.1");
										},
									}}
								>
									<Stack
										direction="row"
										sx={{
											justifyContent: "space-between",
										}}
									>
										<Item
											sx={{
												color: (theme) => theme.palette.secondary.main,
												fontWeight: "unset",
												fontSize: "12px",
												backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00))",
												textAlign: "left",
											}}
										>
											{props.giftInfo?.subHeader}
										</Item>
									</Stack>
								</Item>
							)}
							{giftRelease && giftRelease.Result && giftRelease.Message.length > 0 && (
								<Item
									sx={{
										pt: "3px",
										pl: "16px",
										pr: "16px",
										borderRadius: "20px 20px 0px 0px !important",
										backgroundColor: (theme) => {
											return hexToRGBA(
												theme.palette.mode === "light" ? theme.palette.success.main : theme.palette.primary.main,
												theme.palette.tonalOffset.toString()
											);
										},
									}}
								>
									<Stack direction="row" spacing={0} sx={{ margin: "0 auto" }}>
										<Item
											sx={{
												pt: "19px",
												fontWeight: "bold",
												width: "100% !important",
												backgroundColor: (theme) => {
													return hexToRGBA(
														theme.palette.mode === "light" ? theme.palette.success.main : theme.palette.primary.main,
														theme.palette.mode === "light" ? "0" : "0.095"
													);
												},
											}}
										>
											<Trans>Gift card redeemed</Trans>
										</Item>
									</Stack>
									<Stack direction="row" spacing={0} sx={{ margin: "0 auto" }}>
										<Item
											sx={{
												mt: "1px",
												width: "100%",
												backgroundColor: (theme) => {
													return hexToRGBA(
														theme.palette.mode === "light" ? theme.palette.success.main : theme.palette.primary.main,
														theme.palette.mode === "light" ? "0" : "0.095"
													);
												},
											}}
										>
											{giftRelease.Value}
											{currency} <Trans>discount.</Trans>
										</Item>
									</Stack>
								</Item>
							)}
							<Item
								id={"giftbox-item3-" + id}
								sx={{
									pt: "19px",
									pb: "24px",
									pl: "16px",
									pr: "16px",
									borderTopRightRadius: 0,
									borderTopLeftRadius: 0,
									backgroundColor: (theme) => {
										let tempColor;
										giftRelease && giftRelease.Result && giftRelease.Message.length > 0
											? (tempColor = hexToRGBA(
													theme.palette.mode === "light" ? theme.palette.success.main : theme.palette.primary.main,
													theme.palette.tonalOffset.toString()
											  ))
											: (tempColor = hexToRGBA(theme.palette.background.paper, "0.1"));
										return tempColor;
									},
									zIndex: giftRelease && giftRelease.Result && giftRelease.Message.length > 0 ? 1 : "unset",
								}}
							>
								{!giftRelease.Result && (IntegrationSetting.enableReleaseGift || showNoReleasableText) && (
									<Box
										sx={{
											display: "flex",
											"& > *:not(:last-child)": { marginRight: "3px" },
										}}
									>
										{IntegrationSetting.enableReleaseGift && (
											<Button
												sx={{
													bgcolor: (theme) => theme.palette.background.paper,
													color: (theme) => theme.palette.text.primary,
													height: "30px",
													"&:hover": {
														backgroundColor: (theme) => theme.palette.background.paper,
													},
												}}
												fullWidth
												size="small"
												variant="contained"
												onClick={async () => {
													await requestGift(releaseByTimer);
												}}
												disabled={!props.giftInfo}
											>
												<Trans>Activate gift card</Trans>
											</Button>
										)}
										{showNoReleasableText && (
											<Typography sx={{ height: "30px", fontSize: "12px", mb: "1.57px" }}>{GetNoneReleasableText(Components.Gift, 0)}</Typography>
										)}
									</Box>
								)}
								{IntegrationSetting.enableReleaseGift && giftRelease && giftRelease.Result && giftRelease.Message.length > 0 && (
									<Box
										sx={{
											maxHeight: "40px",
											textAlign: "center",
										}}
									>
										<Typography variant="caption" gutterBottom>
											<Trans>{props.giftInfo?.redeemedTime ? "Code: " + discountCode : giftRelease.Message}</Trans>
										</Typography>
									</Box>
								)}
							</Item>
							{(IntegrationSetting.enableReleaseGift || showNoReleasableText) && (
								<Item
									sx={{
										borderTopRightRadius: 0,
										borderTopLeftRadius: 0,
										mt: "-40px",
										height: "40px",
										backgroundColor: (theme) => theme.palette.background.default,
									}}
								></Item>
							)}
						</Stack>
					)}
				</Box>
			)}
		</>
	);
}
