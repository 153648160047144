import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useContext, useEffect, useState } from "react";
import { APIContext } from "../context/apicontext";
import { Paper } from "@mui/material";
import { BonusBox } from "../components/bonus";
import { ThemeSettingsContext } from "../context/themesettingscontext";
import { UserContext } from "../context/usercontext";
import { HearderUI } from "../components/header";
import { GiftBox } from "../components/gifts";
import { MemberBonusInfoDTO, MemberLevelDto, VoucherDTO, VoucherType } from "../apiclient/MemberAPI";
import { OfferBox } from "../components/offers";
import { FixedSizes } from "../constants/constants";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { BenefitReleaseModes, BenefitTypes, WidgetSize } from "../types/enum";
import { LevelBox } from "../components/level";

function BonusFull() {
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const [bonusInfo, setBonusInfo] = useState<MemberBonusInfoDTO[] | null>(null);
	const [voucherInfo, setVoucherInfo] = useState<VoucherDTO[] | null>(null);
	const [currentLevelInfo, setCurrentLevelInfo] = useState<MemberLevelDto | null | undefined>(null);
	const [nextLevelInfo, setNextLevelInfo] = useState<MemberLevelDto | null | undefined>(null);
	const [apiCall, setApiCall] = useState(false);

	const loadVoucher = async (api: boolean) => {
		const res = await authContext.voucherInfo({ loadAPI: api });
		if (res.length > 0) {
			const temp = res
				.slice()
				.sort((a: VoucherDTO, b: VoucherDTO) => {
					const order = (a: number, b: number) => {
						// equal items sort equally
						if (a === b) {
							return 0;
						}
						// nulls sort after anything else
						if (a === null) {
							return 1;
						}
						if (b === null) {
							return -1;
						}
						// if descending, highest sorts first
						return a < b ? 1 : -1;
					};
					return order(Number(b.redeemedTime), Number(a.redeemedTime));
				})
				.filter((data) => data.canRelease || localStorage.getItem(`${IntegrationSetting.storageId}_Gift_${data.reference}_${window.location.host}`));
			setVoucherInfo(temp);
			setApiCall(api);
		}
	};

	const loadBenefits = async (api: boolean) => {
		const res = await authContext.bonusInfo({ loadAPI: api });
		setBonusInfo(res);
		setApiCall(api);
	};

	const loadLevels = async (api: boolean) => {
		const res = await authContext.userInfo({ loadAPI: api });
		setCurrentLevelInfo(res?.memberLevel);
		setNextLevelInfo(res?.nextLevel);
		setApiCall(api);
	};

	useEffect(() => {
		if (IntegrationSetting.benefits.includes(BenefitTypes.Gift) || IntegrationSetting.benefits.includes(BenefitTypes.Offer)) loadVoucher(false);
		if (IntegrationSetting.benefits.includes(BenefitTypes.Bonus)) loadBenefits(false);
		if (IntegrationSetting.showLevel) loadLevels(false);
	}, [apiCall]);
	const releaseByTimer = IntegrationSetting.releaseMode === BenefitReleaseModes.ByTimer;
	const memberHasLevel = currentLevelInfo && currentLevelInfo !== undefined && currentLevelInfo !== null;

	return (
		<Card id="loyallwidget_full_card_benefits" sx={{ borderRadius: "unset", overflow: "none" }}>
			<CardContent id="loyallwidget_full_card_content" sx={{ padding: 0 }}>
				<HearderUI />
			</CardContent>
			<CardActions
				id="loyallwidget_full_card_actions"
				sx={{
					display: "flex",
					flexDirection: "column",
					height: window.innerHeight - FixedSizes.TOPBAR - FixedSizes.HEADER - FixedSizes.NAVBAR,
					overflow: "scroll",
					backgroundColor: (theme) => theme.palette.background.default,
					paddingRight: 3,
					paddingLeft: 3,
				}}
			>
				{memberHasLevel && (
					<Paper
						id="loyallwidget_full_paper_level"
						sx={{
							maxWidth: "275px",
							minWidth: "275px",
							mt: 7,
							boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
							backgroundColor: (theme) => theme.palette.background.default,
						}}
					>
						<LevelBox memberLevel={currentLevelInfo} nextLevel={nextLevelInfo} />
					</Paper>
				)}
				<Paper
					id="loyallwidget_full_paper_bonus"
					sx={{
						maxWidth: "275px",
						minWidth: "275px",
						mt: memberHasLevel ? 2 : 7,
						boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
						backgroundColor: (theme) => theme.palette.background.default,
					}}
					elevation={12}
				>
					<BonusBox bonusInfo={bonusInfo && bonusInfo[0]} size={WidgetSize.Full} update={async () => loadBenefits(true)} />
				</Paper>
				{voucherInfo &&
					voucherInfo.length > 0 &&
					voucherInfo.map((item, index) => {
						const inx = index.toString();
						if (
							item.voucherType === VoucherType.Giftcard &&
							IntegrationSetting.benefits.includes(BenefitTypes.Gift) &&
							((!releaseByTimer && item.canRelease) || item.canRelease)
						)
							return (
								<Paper
									id={"giftPaper" + inx}
									sx={{
										maxWidth: "275px",
										minWidth: "275px",
										mt: 2,
										ml: "0px !important",
										boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
										backgroundColor: (theme) => theme.palette.background.default,
										"& :first-child": { ml: 0 },
									}}
									elevation={12}
								>
									<GiftBox idx={index} giftInfo={item} size={WidgetSize.Full} update={async () => loadVoucher(true)} />
								</Paper>
							);
						if (
							item.voucherType === VoucherType.Offer &&
							IntegrationSetting.benefits.includes(BenefitTypes.Offer) &&
							((!releaseByTimer && item.canRelease) || item.canRelease)
						)
							return (
								<Paper
									id={"offerPaper" + inx}
									sx={{
										maxWidth: "275px",
										minWidth: "275px",
										mt: 2,
										ml: "0px !important",
										boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
										backgroundColor: (theme) => theme.palette.background.default,
										"& :first-child": { ml: 0 },
									}}
									elevation={12}
								>
									<OfferBox idx={index} offerInfo={item} size={WidgetSize.Full} update={async () => loadVoucher(true)} />
								</Paper>
							);
						return null;
					})}
				{voucherInfo && voucherInfo.length > 0 && (
					<Paper
						sx={{
							maxWidth: "275px",
							mt: 2,
							ml: "0px !important",
							width: "100%",
							boxShadow: "unset",
							backgroundColor: "transparent",
							"& :first-child": { ml: 0 },
							minHeight: "20px",
						}}
						elevation={12}
					></Paper>
				)}
			</CardActions>
		</Card>
	);
}

function BonusMain() {
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const [bonusInfo, setBonusInfo] = useState<MemberBonusInfoDTO[] | null>(null);
	const [voucherInfo, setVoucherInfo] = useState<VoucherDTO[] | null>(null);
	const [currentLevelInfo, setCurrentLevelInfo] = useState<MemberLevelDto | null | undefined>(null);
	const [nextLevelInfo, setNextLevelInfo] = useState<MemberLevelDto | null | undefined>(null);
	const [apiCall, setApiCall] = useState(false);

	const loadVoucher = async (api: boolean) => {
		const res = await authContext.voucherInfo({ loadAPI: false });
		if (res.length > 0) {
			const temp = res
				.slice()
				.sort((a: VoucherDTO, b: VoucherDTO) => {
					const order = (a: number, b: number) => {
						// equal items sort equally
						if (a === b) {
							return 0;
						}
						// nulls sort after anything else
						if (a === null) {
							return 1;
						}
						if (b === null) {
							return -1;
						}
						// if descending, highest sorts first
						return a < b ? 1 : -1;
					};
					return order(Number(b.redeemedTime), Number(a.redeemedTime));
				})
				.filter((data) => data.canRelease || localStorage.getItem(`${IntegrationSetting.storageId}_Gift_${data.reference}_${window.location.host}`));
			setVoucherInfo(temp);
			setApiCall(api);
		}
	};

	const loadBenefits = async (api: boolean) => {
		const res = await authContext.bonusInfo({ loadAPI: false });
		setBonusInfo(res);
		setApiCall(api);
	};

	const loadLevels = async (api: boolean) => {
		const res = await authContext.userInfo({ loadAPI: api });
		setCurrentLevelInfo(res?.memberLevel);
		setNextLevelInfo(res?.nextLevel);
		setApiCall(api);
	};

	useEffect(() => {
		if (IntegrationSetting.benefits.includes(BenefitTypes.Gift) || IntegrationSetting.benefits.includes(BenefitTypes.Offer)) loadVoucher(false);
		if (IntegrationSetting.benefits.includes(BenefitTypes.Bonus)) loadBenefits(false);
		if (IntegrationSetting.showLevel) loadLevels(false);
	}, [apiCall]);

	let layout = Layouting();
	delete layout["maxHeight"];
	let minNavigationHight = 0;
	if (layout.fs) {
		delete layout["minHeight"];
		delete layout["height"];
		delete layout["overflow"];
		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		minNavigationHight = 100 * (182 / vh);
	}
	const releaseByTimer = IntegrationSetting.releaseMode === BenefitReleaseModes.ByTimer;
	const memberHasLevel = currentLevelInfo && currentLevelInfo !== undefined && currentLevelInfo !== null;

	return (
		<Card id="loyallwidget_main_card_benefits" sx={layout}>
			<CardContent
				id="loyallwidget_main_card_content"
				sx={{
					padding: 0,
				}}
			>
				<HearderUI />
			</CardContent>
			<CardActions
				id="loyallwidget_main_card_actions"
				sx={{
					display: "flex",
					flexDirection: "column",
					maxHeight: "246px", // !keyIsFullscreen ? "246px" : `calc(100vh - ${maxNavigationHight}vh)`,
					minHeight: () => {
						if (layout.fs) return `calc(100vh - ${minNavigationHight}vh)`;
						return "246px";
					},
					overflow: "auto",
					backgroundColor: (theme) => theme.palette.background.default,
					//paddingTop: 0,
					paddingRight: 3,
					paddingLeft: 3,
				}}
			>
				{memberHasLevel && (
					<Paper
						id="loyallwidget_main_paper_level"
						sx={{
							maxWidth: "275px",
							minWidth: "275px",
							mt: 7,
							boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
							backgroundColor: (theme) => theme.palette.background.default,
						}}
					>
						<LevelBox memberLevel={currentLevelInfo} nextLevel={nextLevelInfo} />
					</Paper>
				)}
				<Paper
					id="loyallwidget_main_paper_bonus"
					sx={{
						maxWidth: "275px",
						minWidth: "275px",
						mt: memberHasLevel ? 2 : 7,
						boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
						backgroundColor: (theme) => theme.palette.background.default,
					}}
				>
					<BonusBox bonusInfo={bonusInfo && bonusInfo[0]} size={WidgetSize.Main} update={async () => loadBenefits(true)} />
				</Paper>

				{voucherInfo &&
					voucherInfo.length > 0 &&
					voucherInfo.map((item, index) => {
						const inx = index.toString();
						if (
							item.voucherType === VoucherType.Giftcard &&
							IntegrationSetting.benefits.includes(BenefitTypes.Gift) &&
							((!releaseByTimer && item.canRelease) || item.canRelease)
						)
							return (
								<Paper
									id={"loyallwidget_main_paper_gift" + inx}
									sx={{
										maxWidth: "275px",
										minWidth: "275px",
										boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
										backgroundColor: (theme) => theme.palette.background.default,
										ml: "0px !important",
										mt: 2,
									}}
								>
									<GiftBox idx={index} giftInfo={item} size={WidgetSize.Main} update={async () => loadVoucher(true)} />
								</Paper>
							);
						if (
							item.voucherType === VoucherType.Offer &&
							IntegrationSetting.benefits.includes(BenefitTypes.Offer) &&
							((!releaseByTimer && item.canRelease) || item.canRelease)
						)
							return (
								<Paper
									id={"loyallwidget_main_paper_offer" + index.toString()}
									sx={{
										maxWidth: "275px",
										minWidth: "275px",
										mt: 2,
										boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
										backgroundColor: (theme) => theme.palette.background.default,
									}}
								>
									<OfferBox idx={index} offerInfo={item} size={WidgetSize.Main} update={async () => loadVoucher(true)} />
								</Paper>
							);
						return null;
					})}
				{
					<Paper
						sx={{
							maxWidth: "275px",
							mt: "5px",
							ml: "0px !important",
							width: "100%",
							boxShadow: "unset",
							backgroundColor: "transparent",
							"& :first-child": { ml: 0 },
							minHeight: "10px",
						}}
						elevation={12}
					></Paper>
				}
			</CardActions>
		</Card>
	);
}

function BonusSmall() {
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const [bonusInfo, setBonusInfo] = useState<MemberBonusInfoDTO[] | null>(null);

	const loadBenefits = async (api: boolean) => {
		const res = await authContext.bonusInfo({ loadAPI: false });
		setBonusInfo(res);
	};

	useEffect(() => {
		if (IntegrationSetting.benefits.includes(BenefitTypes.Bonus)) loadBenefits(false);
	}, []);

	let layout = Layouting();
	layout.maxHeight = "190px";
	delete layout["height"];

	return (
		<Card sx={layout}>
			<CardActions
				sx={{
					display: "flex",
					flexDirection: "column",
					backgroundColor: (theme) => theme.palette.background.default,
					paddingRight: 3,
					paddingLeft: 3,
				}}
			>
				<Paper
					sx={{
						mt: 1,
						width: "100%",
						boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
						backgroundColor: (theme) => theme.palette.background.default,
					}}
				>
					<BonusBox bonusInfo={bonusInfo && bonusInfo[0]} size={WidgetSize.Small} update={async () => loadBenefits(true)} />
				</Paper>
			</CardActions>
		</Card>
	);
}

export function BonusUI() {
	const userContext = useContext(UserContext);
	switch (userContext.widgetSizeState()) {
		case WidgetSize.Main:
			return <BonusMain />;
		case WidgetSize.Small:
			return <BonusSmall />;
		case WidgetSize.Full:
			return <BonusFull />;
		default:
			return <BonusFull />;
	}
}

function Layouting() {
	const widgetSetting = useContext(ThemeSettingsContext);
	let state = null;
	if (widgetSetting.overflow) {
		state = {
			height: "fit-content",
			overflow: "auto",
			boxShadow: "0",
			minHeight: "180px",
			maxHeight: "180px",
		};
	}
	state = {
		...state,
		borderRadius: "0",
		fs: widgetSetting.floatInMobile,
	};
	return state;
}
