import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { Box, Fab, Typography } from "@mui/material";
import { APIContext } from "../context/apicontext";
import { useContext } from "react";
import { Trans } from "@lingui/macro";
export function HearderUI() {
	const authContext = useContext(APIContext);
	const userInfo = authContext.userInfo({ loadAPI: false });
	return (
		<>
			<Typography
				variant="body1"
				sx={{
					paddingBottom: 2.5,
					pt: 1,
					color: (theme) => theme.palette.text.primary,
				}}
			>
				<Trans>Welcome</Trans> {userInfo?.firstName}
			</Typography>
			<Box sx={{ width: "100%", mb: -5, textAlign: "center" }} component="form">
				<Fab
					sx={{
						width: "80px",
						height: "80px",
						color: (theme) => theme.palette.background.paper,
						bgcolor: (theme) => theme.palette.grey[300],
						border: (theme) => "4px solid " + theme.palette.background.default,
						boxShadow: "0",
					}}
				>
					<AccountCircleRoundedIcon />
				</Fab>
			</Box>
		</>
	);
}
