import React, { useState } from "react";
import {
	MemberDTO,
	MemberBonusInfoDTO,
	AuthResponseDTO,
	TermsOfServiceDTO,
	TransactionDto,
	CardDTO,
	VoucherDTO,
	MemberLoginRequestDTO,
	CreateMrcTokenResponse,
	MemberConsentDTO,
	ConsentType,
	StoreboxIframeRequestDTO,
	SupportTicketDto,
	TicketReplyRequestDto,
	FaqDto,
} from "../apiclient/MemberAPI";
import { Constants } from "../constants/constants";
import { log } from "../service/clientlog";
import { AuthenticationTypes, AuthState, ConsoleType, RedeemType, WidgetSize } from "../types/enum";
import { AuthenticationRequest, ILoyallApiService, IStorage } from "../types/interface";

export const CreateAuthContext = (storage: IStorage, memberApiService: ILoyallApiService, programId: number) => {
	function useCachedState<S>(key: string, initialState: S | (() => S)): [S, (v: S) => void] {
		let i = storage.getItem(key);
		if (i) {
			initialState = JSON.parse(i);
		}
		const [g, s] = useState(initialState);
		return [
			g,
			(v: S) => {
				if (key === "FAQ") {
					let dic = v as unknown;
					const dictionary = dic as Map<string, FaqDto[]>;
					const map = Array.from(dictionary.entries());
					storage.setItem(key, JSON.stringify(map));
					return s(v);
				}
				storage.setItem(key, JSON.stringify(v));
				return s(v);
			},
		];
	}

	const [member, setMember] = useCachedState<MemberDTO | null>("member", null);
	const [authState, setAuthState] = useCachedState<AuthState>("authstate", AuthState.Unauthorized);
	const [bonusInfo, setBonusInfo] = useCachedState<MemberBonusInfoDTO[]>("bonus", []);
	const [voucherInfo, setVoucherInfo] = useCachedState<VoucherDTO[]>("voucher", []);
	const [token, setToken] = useCachedState<string | null>("token", null);
	const [terms, setTerms] = useCachedState<TermsOfServiceDTO | null>("terms", null);
	const [receiptInfo, setReceiptInfo] = useCachedState<TransactionDto[]>("receipt", []);
	const [cardInfo, setCardInfo] = useCachedState<CardDTO[]>("card", []);
	const [consentInfo, setConsentInfo] = useCachedState<MemberConsentDTO[]>("consent", []);
	const [ticketsInfo, setTicketsInfo] = useCachedState<SupportTicketDto[]>("tickets", []);
	const [faqInfo, setFaqInfo] = useCachedState<any>("FAQ", []);

	const reset = () => {
		setAuthState(AuthState.Unauthorized);
		setMember(null);
		setBonusInfo([]);
		setVoucherInfo([]);
		setToken(null);
		setReceiptInfo([]);
		setCardInfo([]);
		setTerms(null);
		setConsentInfo([]);
		setTicketsInfo([]);
		setFaqInfo([]);
		storage.setItem("WidgetSize", WidgetSize.Main);
		storage.setItem(window.location.host, ""); // regarding to bonus released object
		localStorage.setItem(Constants.STOREBOX_HASH, ""); // regarding to callback hash URL parameter from loadStoreboxIframe
		Object.entries(localStorage).forEach((element) => {
			// regarding to gift released object
			if (element[0].includes(`${storage.prefix}_Gift_`)) {
				localStorage.setItem(element[0], "");
				localStorage.removeItem(element[0]);
			}
		});
	};

	const ActionsAfterAuthentication = async (token: string) => {
		try {
			if (bonusInfo && bonusInfo.length <= 0) await GetBonus(token);
			if (voucherInfo && voucherInfo.length <= 0) await GetVoucher(token);
			if (receiptInfo && receiptInfo.length <= 0) await GetReceipts(token);
			if (cardInfo && cardInfo.length <= 0) await GetCards(token);
			if (consentInfo && consentInfo.length <= 0) await GetConsent(token);
			if (ticketsInfo && ticketsInfo.length <= 0) await GetTickets(token);
			if (faqInfo && faqInfo.length <= 0) await GetFaq(token);
		} catch (e) {
			log("ActionsAfterAuthentication:", ConsoleType.Error, e);
			throw e;
		}
	};

	const GetBonus = async (token: string | null) => {
		if (token === null || token === "") throw new Error("missing token information before getting bonus");
		var bonus = await memberApiService.GetBonusInfo(token);
		if (bonus === null) throw new Error("missing bonus information");
		setBonusInfo(bonus as MemberBonusInfoDTO[]);
	};

	const GetVoucher = async (token: string | null) => {
		if (token === null || token === "") throw new Error("missing token information before getting voucher");
		var voucher = await memberApiService.GetVoucherInfo(token);
		if (voucher === null) throw new Error("missing voucher information");
		setVoucherInfo(voucher as VoucherDTO[]);
	};

	const GetUserInfo = async (token: string | null) => {
		if (token === null || token === "") throw new Error("missing token information before getting user info");
		var member = await memberApiService.GetMemberInfo(token);
		if (member === null) throw new Error("missing member information");
		setMember(member as MemberDTO);
	};

	const GetReceipts = async (token: string | null) => {
		if (token === null || token === "") throw new Error("missing token information before getting receipts");
		var receipts = await memberApiService.GetReceiptInfo(token);
		if (receipts === null) throw new Error("missing receipts information");
		setReceiptInfo(receipts as TransactionDto[]);
	};

	const GetCards = async (token: string | null) => {
		if (token === null || token === "") throw new Error("missing token information before getting cards");
		var cards = await memberApiService.GetCardInfo(token);
		if (cards === null) throw new Error("missing cards information");
		setCardInfo(cards as CardDTO[]);
		return cards;
	};

	const GeTTermsOfService = async () => {
		if (!terms) {
			const term = await memberApiService.GetTermsOfService(programId, localStorage.getItem(Constants.STORAGE_COUNTRY_CODE)?.toString() ?? "en");
			setTerms(term);
		}
	};

	const GetConsent = async (token: string | null) => {
		if (token === null || token === "") throw new Error("missing token information before getting consent");
		var consent = await memberApiService.GetMemberConsent(token);
		if (consent === null) throw new Error("missing consent information");
		setConsentInfo(consent as MemberConsentDTO[]);
	};

	const GetTickets = async (token: string | null) => {
		if (token === null || token === "") throw new Error("missing token information before getting tickets");
		var tickets = await memberApiService.GetTicketsInfo(token);
		if (tickets === null) throw new Error("missing ticket information");
		setTicketsInfo(tickets as SupportTicketDto[]);
		return tickets;
	};

	const GetFaq = async (token: string | null) => {
		if (token === null || token === "") throw new Error("missing token information before getting Faq");
		const faqLang: any = new Map<string, FaqDto[]>();
		for (let index = 0; index < Constants.SystemLanguages.length; index++) {
			const lan = Constants.SystemLanguages[index];
			var faq = await memberApiService.GetFaqInfo(token, lan);
			if (faq !== null) faqLang.set(lan, faq as FaqDto[]);
		}
		setFaqInfo(faqLang);
		return faqLang;
	};

	return {
		phoneNumber: () => member?.cellphoneNumber ?? null,
		bonusInfo: async ({ loadAPI }: RefParameters) => {
			if (loadAPI) await GetBonus(token);
			return bonusInfo ?? (await GetBonus(token));
		},
		voucherInfo: async ({ loadAPI }: RefParameters) => {
			if (loadAPI) await GetVoucher(token);
			return voucherInfo ?? (await GetVoucher(token));
		},
		authState: () => authState,
		userInfo: ({ loadAPI }: RefParameters) => {
			if (loadAPI) GetUserInfo(token);
			return member; //?? GetUserInfo(token);
		},
		logout: () => reset(),
		loginStart: async (phone: string) => {
			if (phone.charAt(0) === "+")
				// api expect no leading +
				phone = phone.substring(1);

			setMember({ cellphoneNumber: phone } as MemberDTO);
			GeTTermsOfService();
			try {
				await memberApiService.CreateVerificationCode(programId, phone);
				setAuthState(AuthState.PendingVerification);
			} catch (e) {
				if (e instanceof Response) {
					// member not found
					if (e.status === 404) {
						setAuthState(AuthState.Unregistered);
						return;
					}
				}
				setAuthState(AuthState.OtherError);
				throw e;
			}
		},
		loginValidate: async (request: AuthenticationRequest) => {
			let t: AuthResponseDTO | null = {};
			try {
				switch (request.Type) {
					case AuthenticationTypes.Mobile:
						let phone = request.Phone;
						if (!phone) {
							setAuthState(AuthState.MemberVerificationFailed);
							return;
						}
						if (phone.charAt(0) === "+") phone = phone.substring(1); // api expect no leading +
						let memberLoginRequestDTO: MemberLoginRequestDTO;
						memberLoginRequestDTO = {
							phoneNumber: phone,
							programId: programId,
							recruitmentSource: ("widget_" + window.location.host).substring(0, 32),
							verificationCode: request.VerificationCode,
						};
						t = await memberApiService.Authenticate({
							Type: AuthenticationTypes.Mobile,
							Data: memberLoginRequestDTO,
						});
						break;
					case AuthenticationTypes.Vipps:
						let code = request.VippsCode;
						if (!code) {
							setAuthState(AuthState.SSOVerificationFailed);
							return;
						}
						t = await memberApiService.Authenticate({
							Type: AuthenticationTypes.Vipps,
							ProgramId: programId,
							VippsCode: code,
						});
						break;
					case AuthenticationTypes.JWT:
						if (request.Token) {
							var member = await memberApiService.GetMemberInfo(request.Token);
							if (!member) {
								setToken(null);
								setAuthState(AuthState.Unauthorized);
								return;
							}
							setToken(request.Token);
							setMember(member);
							if (member.userAgreementAcceptDate) setAuthState(AuthState.Authorized);
							if (!member.userAgreementAcceptDate) setAuthState(AuthState.AuthorizedByToken);
							await GeTTermsOfService();
							await GetConsent(request.Token);
							await ActionsAfterAuthentication(request.Token);
						}
						return;
				}
				if (!t || !t.token) {
					request.Type === AuthenticationTypes.Mobile && setAuthState(AuthState.MemberVerificationFailed);
					request.Type === AuthenticationTypes.Vipps && setAuthState(AuthState.SSOVerificationFailed);
					return;
				}
				setToken(t.token);
				if (t.member?.userAgreementAcceptDate === null) {
					const updated = await memberApiService.UpdateMemberInfo(t.token, { userAgreementAcceptDate: new Date().toISOString() } as MemberDTO, programId);
					await GetConsent(t.token);
					setMember(updated.member as MemberDTO);
					setAuthState(AuthState.PendingConsent);
					return;
				}
				setMember(t.member as MemberDTO);
			} catch (e) {
				request.Type === AuthenticationTypes.Mobile &&
					(authState === AuthState.Unregistered || authState === AuthState.NonMemberVerificationFailed) &&
					setAuthState(AuthState.NonMemberVerificationFailed);
				request.Type === AuthenticationTypes.Mobile &&
					!(authState === AuthState.Unregistered || authState === AuthState.NonMemberVerificationFailed) &&
					setAuthState(AuthState.MemberVerificationFailed);
				request.Type === AuthenticationTypes.Vipps && setAuthState(AuthState.SSOVerificationFailed);
				return;
			}
			if (t.token) {
				await ActionsAfterAuthentication(t.token);
			}
			setAuthState(AuthState.Authorized);
		},
		generateToken: async (GUID: string) => {
			return await memberApiService.CreateTokenByMRC(GUID);
		},
		updateInfo: async (obj: MemberDTO, bankInsert: boolean = false, userAgreement: boolean = false, byPassUpdate: boolean = false) => {
			if (byPassUpdate) {
				if (authState !== AuthState.Authorized && token) {
					await ActionsAfterAuthentication(token);
					setAuthState(AuthState.Authorized);
				}
				return null;
			}
			if (bankInsert && !userAgreement) {
				setMember(obj);
				setAuthState(AuthState.PendingBank);
				return null;
			}
			if (userAgreement) {
				await memberApiService.UpdateMemberConsent(token, ConsentType.UserAgreement, true);
			}
			let update = await memberApiService.UpdateMemberInfo(token, obj, programId);
			setMember(update.member as MemberDTO);
			if (authState !== AuthState.Authorized && token) {
				await ActionsAfterAuthentication(token);
				setAuthState(AuthState.Authorized);
			}
			return update.member ?? null;
		},
		termsOfService: () => terms?.tosHtml ?? null,
		receiptInfo: () => receiptInfo ?? GetReceipts(token),
		cardInfo: async ({ loadAPI }: RefParameters) => {
			if (loadAPI) return await GetCards(token);
			if (cardInfo && cardInfo.length > 0) return cardInfo;
			return await GetCards(token);
		},
		loadStoreboxIframe: async (obj: StoreboxIframeRequestDTO) => {
			const frameURL = await memberApiService.SetCardByFrame(token, obj);
			return frameURL;
		},
		updateCardByBankAxept: async (accountNumber?: string) => {
			return await memberApiService.SetCardByBankAxept(token, accountNumber);
		},
		vippsStart: async (returnURL?: string) => {
			const redirectURL = await memberApiService.GetVippsRedirect(returnURL);
			if (!redirectURL) {
				setAuthState(AuthState.Unauthorized);
				return "";
			}
			setAuthState(AuthState.PendingSSOVerification);
			GeTTermsOfService();
			return redirectURL;
		},
		applyBonus: async (merchantId?: number, amount?: number, redeemedBy?: string, redeemedByEmail?: string, reason?: string) => {
			try {
				await memberApiService.ApplyBonusOverTime(token, merchantId, amount, redeemedBy, redeemedByEmail, reason);
				return true;
			} catch (e) {
				log("apply bonus", ConsoleType.Warning, e);
				return false;
			}
		},
		applyVoucher: async (voucherRef: string, redeemedBy: string, reason: RedeemType) => {
			try {
				await memberApiService.ApplyVoucherOverTime(token, voucherRef, redeemedBy, reason);
				return true;
			} catch (e) {
				log("apply voucher", ConsoleType.Warning, e);
				return false;
			}
		},
		consentInfo: async ({ loadAPI }: RefParameters) => {
			if (loadAPI) await GetConsent(token);
			return consentInfo ?? (await GetConsent(token));
		},
		updateConsent: async (consentType: ConsentType, create: boolean) => {
			await memberApiService.UpdateMemberConsent(token, consentType, create);
		},
		removeCard: async (cardId: string) => {
			return await memberApiService.DeleteMemberCard(token, cardId);
		},
		ticketsInfo: async ({ loadAPI }: RefParameters) => {
			if (loadAPI) return await GetTickets(token);
			return ticketsInfo ?? (await GetTickets(token));
		},
		createTicket: async (request: SupportTicketDto) => {
			return await memberApiService.CreateTicket(token, request);
		},
		replyTicket: async (reply: TicketReplyRequestDto) => {
			return await memberApiService.ReplyTicket(token, reply);
		},
		faqInfo: async ({ loadAPI }: RefParameters) => {
			if (loadAPI) return await GetFaq(token);
			return faqInfo ?? (await GetFaq(token));
		},
	};
};

interface RefParameters {
	loadAPI: boolean;
}

export const APIContext = React.createContext({
	phoneNumber: (): string | null => null,
	authState: (): AuthState | null => null,
	userInfo: ({ loadAPI }: RefParameters): MemberDTO | null => null,
	bonusInfo: async ({ loadAPI }: RefParameters): Promise<MemberBonusInfoDTO[]> => Promise.resolve([]),
	voucherInfo: async ({ loadAPI }: RefParameters): Promise<VoucherDTO[]> => Promise.resolve([]),
	logout: () => {},
	loginStart: async (phone: string): Promise<void> => {},
	loginValidate: (request: AuthenticationRequest) => {},
	generateToken: async (GUID: string): Promise<CreateMrcTokenResponse | null> => null,
	updateInfo: async (obj: MemberDTO, bankInsert: boolean = false, userAgreement: boolean = false, byPassUpdate: boolean = false): Promise<MemberDTO | null> =>
		null,
	termsOfService: (): string | null => null,
	receiptInfo: (): TransactionDto[] => [],
	cardInfo: async ({ loadAPI }: RefParameters): Promise<CardDTO[]> => Promise.resolve([]),
	loadStoreboxIframe: (obj: StoreboxIframeRequestDTO): Promise<string> => Promise.resolve(""),
	updateCardByBankAxept: (accountNumber?: string): Promise<string> => Promise.resolve(""),
	vippsStart: (returnURL?: string): Promise<string> => Promise.resolve(""),
	applyBonus: async (merchantId?: number, amount?: number, redeemedBy?: string, redeemedByEmail?: string, reason?: string): Promise<boolean> =>
		Promise.resolve(false),
	applyVoucher: async (voucherRef: string, redeemedBy: string, reason: RedeemType): Promise<boolean> => Promise.resolve(false),
	consentInfo: async ({ loadAPI }: RefParameters): Promise<MemberConsentDTO[]> => Promise.resolve([]),
	updateConsent: (consentType: ConsentType, create: boolean): Promise<void> => Promise.resolve(),
	removeCard: async (cardId: string): Promise<string> => Promise.resolve(""),
	ticketsInfo: async ({ loadAPI }: RefParameters): Promise<SupportTicketDto[]> => Promise.resolve([]),
	createTicket: async (request: SupportTicketDto): Promise<string> => Promise.resolve(""),
	replyTicket: async (reply: TicketReplyRequestDto): Promise<string> => Promise.resolve(""),
	faqInfo: async ({ loadAPI }: RefParameters): Promise<any> => Promise.resolve([]),
});
