import { Box, Button, Card, CardActions, CardContent, IconButton, Typography } from "@mui/material";
import { MemberBonusInfoDTO, VoucherDTO } from "../apiclient/MemberAPI";
import { useContext, useState } from "react";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { BenefitTypes, Components, ConsoleType, RedeemType, TemplateElements, WidgetSize } from "../types/enum";
import { t } from "@lingui/macro";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { BonusBox } from "./bonus";
import { TextTemplate } from "../service/jsonreader";
import { i18n } from "@lingui/core";
import LinearWithValueLabel from "./linearprogress";
import qrc from "../assets/resources/qrcode.png";
import QrCard from "./qr";
import CircularStatic from "./progress";
import { APIContext } from "../context/apicontext";
import { log } from "../service/clientlog";
import { GiftBox } from "./gifts";
import { OfferBox } from "./offers";

interface Props {
	benefitType: BenefitTypes;
	widgetSize: WidgetSize;
	requestAmount?: number;
	offerHeader?: string;
	bonusInfo?: MemberBonusInfoDTO;
	voucherInfo?: VoucherDTO;
	idx?: number;
	update: (api: boolean) => Promise<void>;
}

export function BenefitTimerBox(props: Props) {
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const isCtx = useContext(IntegrationSettingsContext);
	const apiContext = useContext(APIContext);

	const [cancelActivation, setCancelActivation] = useState<boolean>(false);
	const [activate, setActivate] = useState<boolean>(false);
	const [qrSource, setQRSource] = useState(qrc);
	const [processing, setProcessing] = useState<boolean>(false);

	const GetNoneReleasableText = (component: Components, level: number) => {
		let text = TextTemplate.GetTextTemplate(component, IntegrationSetting.customer, TemplateElements.texts, level, undefined);
		if (!text) return "";
		const translated = /*i18n*/ i18n._(`${text}`);
		return i18n._(translated);
	};

	const applyBenefit = async () => {
		setProcessing(true);
		let response = false;
		switch (props.benefitType) {
			case BenefitTypes.Bonus:
				isCtx.onEvent({ EventType: "on bonus release by timer", Payload: { Ref: "Bonus Timer", BonusUserInput: props.requestAmount } });
				log(
					"Bonus Timer",
					ConsoleType.Info,
					props.bonusInfo?.merchant?.merchantId,
					props.requestAmount,
					window.location.host,
					apiContext.phoneNumber() ?? apiContext.userInfo({ loadAPI: false })?.cellphoneNumber,
					`max timer:${IntegrationSetting.releaseTimerMax}`
				);
				response = await apiContext.applyBonus(
					props.bonusInfo?.merchant?.merchantId,
					props.requestAmount,
					window.location.host,
					apiContext.phoneNumber() ?? apiContext.userInfo({ loadAPI: false })?.cellphoneNumber,
					`max timer:${IntegrationSetting.releaseTimerMax}`
				);
				break;
			case BenefitTypes.Gift:
			case BenefitTypes.Offer:
				if (props.voucherInfo?.reference) {
					isCtx.onEvent({
						EventType: `on ${props.benefitType} release by timer`,
						Payload: { Ref: `${props.benefitType} Timer`, VoucherReference: props.benefitType === BenefitTypes.Gift ? props.requestAmount : props.offerHeader },
					});
					log(
						"Voucher Timer",
						ConsoleType.Info,
						props.voucherInfo.reference,
						window.location.host,
						props.benefitType === BenefitTypes.Gift ? RedeemType.giftcard : RedeemType.offer
					);
					response = await apiContext.applyVoucher(
						props.voucherInfo.reference,
						window.location.host,
						props.benefitType === BenefitTypes.Gift ? RedeemType.giftcard : RedeemType.offer
					);
				}
				break;
		}
		setProcessing(false);
		if (response) {
			await props.update(true);
			return true;
		}
		return false;
	};

	const generateQr = (data: string) => {
		const qr = `http://api.qrserver.com/v1/create-qr-code/?data=${data}&size=125x125`;
		setQRSource(qr);
	};

	async function backFromActivation() {
		switch (props.benefitType) {
			case BenefitTypes.Bonus:
				await apiContext.bonusInfo({ loadAPI: true });
				break;
			case BenefitTypes.Gift:
			case BenefitTypes.Offer:
				await apiContext.voucherInfo({ loadAPI: true });
				break;
		}
		setCancelActivation(true);
		return true;
	}

	const title =
		(props.benefitType === BenefitTypes.Bonus && t`bonus certificate`) ||
		(props.benefitType === BenefitTypes.Gift && t`gift card certificate`) ||
		(props.benefitType === BenefitTypes.Offer && t`offer certificate`);
	const button =
		(props.benefitType === BenefitTypes.Bonus && t`Activate bonus`) ||
		(props.benefitType === BenefitTypes.Gift && t`Activate gift card`) ||
		(props.benefitType === BenefitTypes.Offer && t`Activate offer`);

	const component = (): Components => {
		switch (props.benefitType) {
			case BenefitTypes.Bonus:
				return Components.Bonus;
			case BenefitTypes.Gift:
				return Components.Gift;
			case BenefitTypes.Offer:
				return Components.Offer;
		}
	};
	const qrPrefix = t`The discount coupon has been redeemed at:`;
	const currency = IntegrationSetting.product.Currency ?? ",-";
	return (
		<>
			{cancelActivation && props.benefitType === BenefitTypes.Bonus && props.bonusInfo !== undefined && (
				<BonusBox bonusInfo={props.bonusInfo} size={props.widgetSize} update={props.update} />
			)}
			{cancelActivation && props.benefitType === BenefitTypes.Gift && props.voucherInfo !== undefined && props.idx !== undefined && (
				<GiftBox giftInfo={props.voucherInfo} size={props.widgetSize} idx={props.idx} update={props.update} />
			)}
			{cancelActivation && props.benefitType === BenefitTypes.Offer && props.voucherInfo !== undefined && props.idx !== undefined && (
				<OfferBox offerInfo={props.voucherInfo} size={props.widgetSize} idx={props.idx} update={props.update} />
			)}
			{!cancelActivation && (
				<Card
					sx={{
						background: "unset",
						color: "unset",
						borderBottomLeftRadius: "inherit",
						borderBottomRightRadius: "inherit",
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						boxShadow: 0,
					}}
				>
					<CardContent sx={{ padding: "unset" }}>
						<Box sx={{ pt: "25px", pr: 2, pl: 2 }}>
							<Typography
								variant="h5"
								sx={{ color: (theme) => theme.palette.secondary.main, textTransform: "uppercase", fontWeight: "bold", textAlign: "center" }}
							>
								{title}
							</Typography>
							<Typography
								variant="body1"
								sx={{
									color: (theme) => theme.palette.secondary.main,
									textAlign: "center",
								}}
							>
								{props.benefitType === BenefitTypes.Offer ? props.offerHeader : props.requestAmount + currency}
							</Typography>
							<Typography
								sx={{ color: (theme) => theme.palette.secondary.main }}
								dangerouslySetInnerHTML={{
									__html: props.voucherInfo?.body ?? "",
								}}
							></Typography>
							{!activate && (
								<Box sx={{ pl: "16px", pr: "16px", pt: "10px" }}>
									<Typography variant="body2" sx={{ color: (theme) => theme.palette.secondary.main, mb: "10px", textAlign: "center" }}>
										{GetNoneReleasableText(component(), 1)}
									</Typography>
									<Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }}>
										{GetNoneReleasableText(component(), 2)}
									</Typography>
								</Box>
							)}
							{activate && (
								<Box sx={{ pl: "16px", pr: "16px", pt: "10px" }}>
									<Typography variant="body2" sx={{ color: (theme) => theme.palette.secondary.main, textAlign: "center" }}>
										{qrPrefix}
									</Typography>
								</Box>
							)}
						</Box>
					</CardContent>
					<CardActions sx={{ pl: "16px", pr: "16px" }}>
						{!activate && (
							<Button
								sx={{
									bgcolor: (theme) => theme.palette.background.paper,
									color: (theme) => theme.palette.text.primary,
									height: "30px",
									"&:hover": {
										backgroundColor: (theme) => {
											return theme.palette.background.paper + " !important";
										},
									},
								}}
								fullWidth
								size="small"
								variant="contained"
								onClick={async () => {
									const result = await applyBenefit();
									if (result) {
										generateQr(`${qrPrefix} ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`);
										setActivate(true);
									}
								}}
							>
								{processing && <CircularStatic visible={processing} />}
								{!processing && button}
							</Button>
						)}
						{activate && <QrCard source={qrSource} />}
						{activate && <LinearWithValueLabel activation={setActivate} expired={backFromActivation} maxInSecond={IntegrationSetting.releaseTimerMax} />}
						<IconButton
							aria-label="cancel"
							sx={{
								color: (theme) => theme.palette.background.paper,
							}}
							onClick={async () => {
								await backFromActivation();
							}}
						>
							<HighlightOffRoundedIcon
								sx={{
									fontSize: "40px",
								}}
							/>
						</IconButton>
					</CardActions>
				</Card>
			)}
		</>
	);
}
