import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import React from "react";
import TextsObj from "../constants/template.json";
import { Components, ProductIntegrationSettings, TemplateElements } from "../types/enum";
import { Product } from "../types/type";

interface Template {
	login: LoginPage[];
}
interface LoginPage {
	customer: string;
	template: Template;
}

function ExtractIntegrationSettings(str: string): string[] {
	var results = [],
		re = /{([^}]+)}/g,
		text;

	while ((text = re.exec(str))) {
		results.push(text[1]);
	}
	return results;
}

function ReplaceConfigsWithData(config: ProductIntegrationSettings, product: Product | undefined): string {
	if (!product) return "";
	let data: string = "";
	switch (config) {
		case ProductIntegrationSettings.Name:
			data = product.Name ?? "";
			break;
		case ProductIntegrationSettings.Discount:
			let discount = Number(product.Discount);
			data = discount.toFixed(2).toString() ?? "";
			break;
		case ProductIntegrationSettings.DiscountPercentage:
			data = product.DiscountPercentage?.toString() ?? "";
			break;
		case ProductIntegrationSettings.IsInSale:
			data = String(product.IsInSale);
			break;
		case ProductIntegrationSettings.Price:
			data = product.Price?.toString() ?? "";
			break;
		case ProductIntegrationSettings.Currency:
			data = String(product.Currency);
			break;
		default:
			break;
	}
	return data;
}

function contentTranslate(value: boolean, key: string) {
	if (value) {
		return React.createElement("b", {}, t`${key}` + " "); //<b>{t`${key}`} </b>
	}
	const message = /*i18n*/ i18n._(`${key}`);
	return React.createElement(React.Fragment, {}, i18n._(message) + " "); //<>{i18n._(message)} </>;
}

export function GetTranslatedText(component: Components, customer: string, level: number, element: TemplateElements, product?: Product | undefined) {
	let text = TextTemplate.GetTextTemplate(component, customer, element, level, product);
	if (text.length > 0) {
		const message = /*i18n*/ i18n._(`${text}`);
		return message;
	}
	return null;
}

export const ElementTextByTemplate = (component: Components, customer: string, level: number, element: TemplateElements, product?: Product | undefined) => {
	let finalText: any[] = [];
	let text = TextTemplate.GetTextTemplate(component, customer, element, level, product);
	var matches = text.match(/#[a-z0-9_]+/g);
	if (matches === null) {
		finalText.push(contentTranslate(false, text));
		return finalText;
		//return Array.from(object)[0][0];
	}

	const words = text.split(" ");
	let object = new Map<string, boolean>();
	for (let index = 0; index < text.split(" ").length; index++) {
		object.set(words[index], words[index].startsWith("#"));
	}

	Array.from(object.keys()).map((item) => {
		return finalText.push(contentTranslate(object.get(item) ?? false, item.replace("#", " ")));
	});

	return finalText;
};

export class TextTemplate {
	public static toTemplate(json: string): Template {
		return JSON.parse(json);
	}

	public static templateToJson(value: Template): string {
		return JSON.stringify(value);
	}

	public static GetTextTemplate(component: Components, customer: string, element: TemplateElements, level: number, product: Product | undefined): string {
		let template = "";
		let JSON = TextsObj.login;
		switch (component) {
			case Components.Login:
				JSON = TextsObj.login;
				break;
			case Components.Minimize:
				JSON = TextsObj.minimize;
				break;
			case Components.Close:
				JSON = TextsObj.close;
				break;
			case Components.Bonus:
				JSON = TextsObj.bonus;
				break;
			case Components.Gift:
				JSON = TextsObj.gift;
				break;
			case Components.Offer:
				JSON = TextsObj.offer;
				break;
			default:
				break;
		}
		// TEMPORARY
		let customerExist = false;
		for (var j = 0; j < JSON.length; j++) {
			let item = JSON[j];
			if (item.customer === customer) {
				customerExist = true;
			}
		}
		// TEMPORARY
		const IgnoreShopify = [
			"infinity.loyallfriends.no",
			"infinity.member.loyalty.loyall.no",
			"widget.linuxweb01.srv.loyall.no",
			"stil.loyallfriends.no",
			"stil.member.loyalty.loyall.no",
			"modo.loyallfriends.no",
			"modo.member.loyalty.loyall.no",
			"bogartfamily.loyallfriends.no",
			"bogartfamily.member.loyalty.loyall.no",
			"deana.loyallfriends.no",
			"deana.member.loyalty.loyall.no",
			"komeclub.loyallfriends.no",
			"komeclub.member.loyalty.loyall.no",
			"komekompis.loyallfriends.no",
			"pharmaphamily.loyallfriends.no",
			"pharmaphamily.member.loyalty.loyall.no",
			"dayvipclub.loyallfriends.no",
			"dayvipclub.member.loyalty.loyall.no",
			"riccovero.loyallfriends.no",
			"riccovero.member.loyalty.loyall.no",
			"freshpepper.loyallfriends.no",
			"freshpepper.member.loyalty.loyall.no",
			"vrimmlinger.loyallfriends.no",
			"vrimmlinger.member.loyalty.loyall.no",
			"bmbpaleet.loyallfriends.no",
			"bmbpaleet.member.loyalty.loyall.no",
			"qomo.loyallfriends.no",
			"qomo.member.loyalty.loyall.no",
			"formen.loyallfriends.no",
			"formen.member.loyalty.loyall.no",
			"tantepose.loyallfriends.no",
			"tantepose.member.loyalty.loyall.no",
			"markandbrandy.loyallfriends.no",
			"markandbrandy.member.loyalty.loyall.no",
			"zicco.loyallfriends.no",
			"zicco.member.loyalty.loyall.no",
			"exitfifty.loyallfriends.no",
			"exitfifty.member.loyalty.loyall.no",
			"bogartcosmo.member.loyalty.loyall.no",
			"bogartcosmo.loyallfriends.no",
			"swahvenn.loyallfriends.no",
			"swahvenn.member.loyalty.loyall.no",
			"hosjosefine.loyallfriends.no",
			"hosjosefine.member.loyalty.loyall.no",
			"tennis.loyallfriends.no",
			"tennis.member.loyalty.loyall.no",
			"kiman.loyallfriends.no",
			"kiman.member.loyalty.loyall.no",
			"teleoutletclub.loyallfriends.no",
			"teleoutletclub.member.loyalty.loyall.no",
			"regnvenn.loyallfriends.no",
			"regnvenn.member.loyalty.loyall.no",
			"10.10.129.3",
		];
		const LoyallStandard = ["skomanino.5.dev.nymedia.no", "skomani.ddev.site", "skomani.no", "localhost"];

		if (!customerExist || IgnoreShopify.includes(window.location.hostname)) customer = "Visbook";
		if (LoyallStandard.includes(window.location.hostname)) customer = "loyall";
		// TEMPORARY
		for (var i = 0; i < JSON.length; i++) {
			let item = JSON[i];
			if (item.customer === customer) {
				switch (element) {
					case TemplateElements.texts:
						template = item.template.texts[level].text;
						let integrationSetting = ExtractIntegrationSettings(template);
						if (integrationSetting.length === 0 || !product) break;

						for (let index = 0; index < integrationSetting.length; index++) {
							let content = integrationSetting[index];
							let el = content as unknown as ProductIntegrationSettings;
							template = template.replace(content, ReplaceConfigsWithData(el, product));
							template = template.replace("{", "");
							template = template.replace("}", "");
						}
						break;

					case TemplateElements.buttons:
						template = item.template.buttons[level].text;
						break;
					default:
						break;
				}
			}
		}
		return template;
	}
}
