import { Constants } from "../constants/constants";

export function StoreboxURLBuilder(): string {
	const temp = window.location.href.split("#")[0];
	const redirect = new URL(temp);
	redirect.searchParams.append(Constants.StoreboxSearchParam, Constants.StoreboxRedirect);
	return redirect.href;
}

export function groupBy<T>(arr: T[], fn: (item: T) => any) {
	return arr.reduce<Record<string, T[]>>((prev, curr) => {
		const groupKey = fn(curr);
		const group = prev[groupKey] || [];
		group.push(curr);
		return { ...prev, [groupKey]: group };
	}, {});
}
