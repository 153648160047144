import React, { useState, useEffect } from "react";
import "../assets/styles/firework.css";

export function FireworksAnimation(props: any) {
	const [fireworks, setFireworks] = useState<JSX.Element[]>([]);

	useEffect(() => {
		const generateRandomPosition = (): { top: string; left: string } => {
			return {
				top: `${Math.random() * 100}%`,
				left: `${Math.random() * 100}%`,
			};
		};

		const generateRandomColor = (): string => {
			const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16); // Generate a random hex color code
			return randomColor;
		};

		const newFireworks: JSX.Element[] = [];

		// Generate 14 fireworks with random positions and colors
		for (let j = 0; j < props.value; j++) {
			const position = generateRandomPosition();
			const color = generateRandomColor();
			newFireworks.push(
				<div
					key={j}
					className="firework"
					style={{
						top: position.top,
						left: position.left,
						backgroundColor: color,
					}}
				></div>
			);
		}

		setFireworks(newFireworks);
	}, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount

	return <div style={{ width: "100%", position: "relative" }}>{fireworks}</div>;
}
