import { Trans } from "@lingui/macro";
import { Box, Link, Modal, Typography } from "@mui/material";
import { useContext, useState, forwardRef } from "react";
import { APIContext } from "../context/apicontext";
import Backdrop from "@mui/material/Backdrop";
import { useSpring, animated } from "@react-spring/web";

interface FadeProps {
	children?: React.ReactElement;
	in: boolean;
	onEnter?: () => {};
	onExited?: () => {};
}

const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
	const { in: open, children, onEnter, onExited, ...other } = props;
	const style = useSpring({
		from: { opacity: 0 },
		to: { opacity: open ? 1 : 0 },
		onStart: () => {
			if (open && onEnter) {
				onEnter();
			}
		},
		onRest: () => {
			if (!open && onExited) {
				onExited();
			}
		},
	});

	return (
		<animated.div ref={ref} style={style} {...other}>
			{children}
		</animated.div>
	);
});

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	color: "text.primary",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
	height: "50%",
	width: window.innerWidth - 100,
	overflow: "auto",
};

export function TermsAndConditions(props: any) {
	const authContext = useContext(APIContext);
	const termsObj = (): string => {
		return authContext.termsOfService() ?? "";
	};

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Typography
			variant="caption"
			display="block"
			gutterBottom
			color="text.primary"
			sx={{
				fontSize: (theme) => theme.typography.subtitle1.fontSize,
				fontWeight: (theme) => theme.typography.h1.fontWeight,
				lineHeight: (theme) => theme.typography.h1.lineHeight,
			}}
		>
			<Trans>By becoming a member, you accept that the personal information provided is used to administer the membership, and acceptance of the</Trans>
			<Link
				sx={{
					fontSize: (theme) => theme.typography.subtitle1.fontSize,
					color: (theme) => theme.palette.text.primary,
					textTransform: "none",
					mt: "-3px",
					ml: ".5rem",
					lineHeight: 0,
					textDecoration: "underline",
				}}
				href="#"
				underline="hover"
				variant="overline"
				onClick={handleOpen}
				type="link"
				color="secondary"
			>
				<Trans>terms and conditions.</Trans>
			</Link>
			<Modal
				aria-labelledby="spring-modal-title"
				aria-describedby="spring-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Typography
							id="spring-modal-title"
							variant="h6"
							component="h2"
							dangerouslySetInnerHTML={{
								__html: "<h1>End-User License Agreement (EULA)</h1>",
							}}
						></Typography>
						<Typography
							id="spring-modal-description"
							sx={{ mt: 2 }}
							variant="body2"
							dangerouslySetInnerHTML={{
								__html: termsObj(),
							}}
						></Typography>
					</Box>
				</Fade>
			</Modal>
		</Typography>
	);
}
