import { Box } from "@mui/material";
import { useEffect, useRef } from "react";

export default function IFrame(props: any) {
	const bottomRef = useRef<null | HTMLElement>(null);

	useEffect(() => {
		const ref = bottomRef.current;
		if (ref) {
			ref.addEventListener("load", () => {
				const obj = ref?.getElementsByClassName("ng-pristine");
				if (obj) {
					ref?.scrollIntoView({ behavior: "smooth" });
					props.updateProcessing(false);
				}
			});
		}
		return () => {
			ref?.removeEventListener("load", () => {
				props.updateProcessing(false);
			});
		};
	}, []);

	let Iframe = props.iframe;
	return (
		<Box sx={{ margin: "0px auto 0px auto !important", textAlign: "center" }}>
			<Iframe
				id="loyall-widget-storebox-frame"
				ref={bottomRef}
				src={props.src}
				height={props.height}
				width={props.width}
				name="loyall-widget-storebox-frame"
				style={{ border: "none", backgroundColor: "transparent" }}
			></Iframe>
		</Box>
	);
}
