import { styled, TextField } from "@mui/material";

const CssTextField = styled(TextField)(({ theme }) => ({
	"& label.Mui-focused": {
		color: theme.palette.secondary.light,
		fontFamily: theme.typography.fontFamily,
	},
	"& label": {
		color: theme.palette.secondary.light,
		fontFamily: theme.typography.fontFamily,
	},
	"& label:hover": {
		color: theme.palette.secondary.light,
		fontFamily: theme.typography.fontFamily,
	},
	"& .MuiOutlinedInput-root": {
		color: theme.palette.secondary.main,
		fontFamily: theme.typography.fontFamily,
		"& fieldset": {
			borderColor: theme.palette.secondary.dark,
			fontFamily: theme.typography.fontFamily,
			borderRadius: "5px",
		},
		"&:hover fieldset": {
			borderColor: theme.palette.secondary.light,
			fontFamily: theme.typography.fontFamily,
		},
		"&.Mui-focused fieldset": {
			borderWidth: 2,
			borderColor: theme.palette.secondary.light,
			fontFamily: theme.typography.fontFamily,
		},
	},
}));

export function CustomTextbox(props: any) {
	return (
		<CssTextField
			sx={{
				bgcolor: (theme) => theme.palette.background.default,
			}}
			label={props.label}
			name={props.name}
			id={props.id}
			type={props.type}
			variant={props.variant}
			size={props.size}
			fullWidth={props.fullWidth}
			placeholder={props.placeholder}
			required={props.required}
			InputLabelProps={{
				shrink: true,
				sx: {
					bgcolor: (theme) => theme.palette.background.default,
					pr: 1,
				},
			}}
			// onInput={(e: any) => {
			// 	e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, props.maxLength);
			// }}
			autoFocus={props.autoFocus}
			onChange={props.onChange}
			onClick={props.onClick}
			InputProps={{
				inputProps: { maxLength: props.maxLength },
				sx: { fontSize: props.fontSize, fontWeight: props.fontWeight, height: props.height },
				spellCheck: false,
			}}
			multiline={props.multiline}
			rows={props.rows}
			inputRef={props.inputRef}
			disabled={props.disabled}
		/>
	);
}
