import React, { useState } from "react";
import { IStorage } from "../types/interface";

export const CreateThemeContext = (storage: IStorage, defaultTheme: string) => {
	const [themeName, setThemeName] = useState(storage.getItem("theme") || defaultTheme);
	return {
		currentTheme: themeName,
		setCurrentTheme: (t: string) => {
			storage.setItem("theme", t);
			setThemeName(t);
		},
	};
};
export const ThemeContext = React.createContext({
	currentTheme: "dark",
	setCurrentTheme: (theme: string) => {},
});
