import { t, Trans } from "@lingui/macro";
import { Box, Paper, Typography, Divider, IconButton, Card, CardActions, Button, styled } from "@mui/material";
import { CardDTO, CardType } from "../apiclient/MemberAPI";
import { ReactComponent as MaterCardLogo } from "../assets/resources/mastercard.svg";
import { ReactComponent as VisaLogo } from "../assets/resources/visa.svg";
import { ReactComponent as AmericanExpressLogo } from "../assets/resources/americanexpress.svg";
import { ReactComponent as BankAxeptLogo } from "../assets/resources/bankaxept.svg";
import { APIContext } from "../context/apicontext";
import { useContext, useEffect, useState } from "react";
import AddCardRoundedIcon from "@mui/icons-material/AddCardRounded";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import IFrame from "./iframe";
import CircularStatic from "./progress";
import { Constants, textEllipsis } from "../constants/constants";
import { CustomTextbox } from "./textbox";
import { StoreboxURLBuilder } from "../service/helper";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { bankAccountPattern } from "../types/interface";
import { ConfirmationDialogRaw } from "./dialog";
import { LanguageContext } from "../context/languagecontext";

const Close = styled(IconButton)(({ theme }) => ({
	backgroundColor: "transparent",
	color: theme.palette.primary.main,
}));

export function CardDetail(props: any) {
	const memberContext = useContext(APIContext);
	const [cards, setCards] = useState<CardDTO[] | null>();

	const [storebox, setStorebox] = useState("");
	const [storeboxHash, setStoreboxHash] = useState<string | undefined>(localStorage.getItem(Constants.STOREBOX_HASH) as string);
	const [storeboxResponse, setStoreboxResponse] = useState(t`Please Wait`);
	const [bankaxeptResponse, setBankAxeptResponse] = useState("");

	useEffect(() => {
		let isMounted = true;
		if (cards === null || cards === undefined) {
			if (isMounted && storeboxHash) {
				localStorage.setItem(Constants.STOREBOX_HASH, "");
				setStoreboxHash("");
			}
			const updateAsync = async () => {
				const data = await memberContext.cardInfo({ loadAPI: false });
				setCards(data);
			};
			!cards && updateAsync();
		}
		return () => {
			isMounted = false;
		};
	}, [cards, storeboxHash, memberContext]);

	const [storeBoxLoadProcess, setStoreBoxLoadProcess] = useState<boolean>(false);
	const [accountSubmitProcess, setAccountSubmitProcess] = useState<boolean>(false);

	const [value, setValue] = useState("");
	const [error, setError] = useState(false);
	const [bankaxeptFrame, setBankAxeptFrame] = useState<boolean>(false);
	const theme = useTheme();
	const languageContext = useContext(LanguageContext);
	const openStoreBox = async () => {
		if (storebox) {
			setStorebox("");
			return;
		}
		setStoreBoxLoadProcess(true);
		let result: string = "";
		let buttonBackgroundColor = () => {
			return theme.palette.primary.dark;
		};
		let buttonColor = () => {
			return theme.palette.text.secondary;
		};
		try {
			result = await memberContext.loadStoreboxIframe({
				returnURL: StoreboxURLBuilder(),
				language: languageContext.language,
				css: {
					bodyBackgroundColor: "transparent",
					buttonBackgroundColor: buttonBackgroundColor(),
					buttonColor: buttonColor(),
				},
			});
			if (result.startsWith(Constants.STOREBOX_URL)) {
				setStorebox(result);
				const hash = new URLSearchParams(result ?? "").get(Constants.StoreboxHashSearchParam) ?? "";
				localStorage.setItem(Constants.STOREBOX_HASH, hash);
				setStoreboxHash(hash);
			}
		} catch (error: any) {
			if (error.status === 400) {
				setStoreboxResponse(t`Please try again later.`);
			}
			if (error.status === 401) {
				setStoreboxResponse(t`Please logout and sign-in again.`);
			}
			setError(true);
		} finally {
			setTimeout(() => {
				setStoreBoxLoadProcess(false);
			}, 2000);
		}
	};

	const openBankAxept = () => {
		!bankaxeptFrame && storebox && setStorebox("");
		setBankAxeptFrame(!bankaxeptFrame);
	};

	const handleSubmitAccountNumber = async (event: React.FormEvent<HTMLFormElement>) => {
		setAccountSubmitProcess(true);
		setError(false);
		setBankAxeptResponse("");

		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const problem = await memberContext.updateCardByBankAxept(data.get("token")?.toString());
		if (problem) {
			setError(true);
			setBankAxeptResponse(problem);
			setAccountSubmitProcess(false);
			return;
		}
		setBankAxeptResponse(t`Account successfully added.`);
		setAccountSubmitProcess(false);
		await memberContext.cardInfo({ loadAPI: true });
		setCards(null);
	};

	const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		value && setValue("");
		bankaxeptResponse && setBankAxeptResponse("");
		const digit13 = bankAccountPattern.test(event.target.value.toString()) && event.target.value.length === 13;
		const digit11 = event.target.value.length === 11 && !isNaN(Number(event.target.value));
		if (digit11 || digit13) {
			setValue(event.target.value);
			setError(false);
		}
	};

	const updateProcessingOfStoreBox = (processing: boolean) => {
		setStoreBoxLoadProcess(processing);
	};

	const [open, setOpen] = useState(false);
	const [removeValue, setRemoveValue] = useState("");
	const [removeShowValue, setRemoveShowValue] = useState("");

	const removeCard = (card: string | undefined, account: string, cardId: string | null | undefined) => {
		if (!cardId || cardId === undefined) return;
		setRemoveValue(cardId);
		setRemoveShowValue(card ?? account);
		setOpen(true);
	};

	const [removeMessage, setRemoveMessage] = useState("");
	const [removeProcess, setRemoveProcess] = useState<boolean>(false);

	const handleClose = async (newValue?: string) => {
		setOpen(false);
		if (newValue) {
			setRemoveProcess(true);
			const problem = await memberContext.removeCard(newValue);
			if (problem) {
				setError(true);
				setRemoveMessage(problem);
				setRemoveProcess(false);
				return;
			}
			setRemoveMessage("");
			await memberContext.cardInfo({ loadAPI: true });
			setCards(null);
			setRemoveProcess(false);
		}
	};

	const accountTooltip = t`Add Account`;
	const cardTooltip = t`Add Card`;
	const closeTooltip = t`Remove Card/Account`;
	const expireTooltip = t`Exp. Date`;

	return (
		<Box sx={{ pl: 2, pr: 2, pb: 2, margin: "0 auto", textAlign: "center" }}>
			{cards &&
				cards.map((element, index) => {
					let mask = element.maskedPan?.replaceAll("X", "x");
					if (mask && mask !== undefined && mask.length > 11) {
						mask = mask.match(/.{1,4}/g)?.join(" ");
					}
					let accNumber = "";
					if (mask && mask !== undefined && mask.length === 11) {
						accNumber = mask.slice(0, 4) + "." + mask.slice(4);
						accNumber = accNumber.slice(0, 7) + "." + accNumber.slice(7);
					}
					var cardLogo;
					switch (element.cardType) {
						case CardType.Visa:
							cardLogo = <VisaLogo />;
							break;
						case CardType.MasterCard:
							cardLogo = <MaterCardLogo />;
							break;
						case CardType.AmericanExpress:
							cardLogo = <AmericanExpressLogo />;
							break;
						default:
							cardLogo = <BankAxeptLogo />;
							break;
					}
					return (
						<Paper
							id={"Paper_Card" + index.toString()}
							elevation={3}
							sx={{
								borderRadius: 1,
								padding: 2,
								color: (theme) => (element.cardType === CardType.MasterCard ? theme.palette.primary.main : theme.palette.secondary.main),
								bgcolor: (theme) => (element.cardType === CardType.MasterCard ? theme.palette.secondary.main : theme.palette.primary.main),
								//width: "275px",
								mb: 1,
								//margin: "2px",
							}}
						>
							<Box id={"Box_Card" + index.toString()} sx={{ display: "flex", justifyContent: "space-between", width: "95%" }}>
								<Typography variant="body1" sx={{ fontWeight: "normal", ...textEllipsis }}>
									{props.name}
								</Typography>
								{props.editable && (
									<Close
										aria-haspopup="true"
										aria-controls="ringtone-menu"
										title={closeTooltip}
										size={props.IconSize}
										onClick={() => removeCard(mask, accNumber, element.cardId)}
										sx={{
											padding: 0,
											mt: "4px",
											color: (theme) => (element.cardType === CardType.MasterCard ? theme.palette.primary.main : theme.palette.secondary.main),
										}}
									>
										<CloseIcon sx={{ fontSize: "12px", float: "right" }} />
									</Close>
								)}
							</Box>
							<Divider
								sx={{
									width: "95%",
									borderColor: (theme) => (element.cardType === CardType.MasterCard ? theme.palette.primary.main : theme.palette.secondary.main),
								}}
							/>
							<Box id={"Box_Mask" + index.toString()} sx={{ display: "flex", justifyContent: "space-between", width: "95%", mt: 1, maxHeight: "24px" }}>
								<Typography variant="body2" sx={{ textTransform: "unset" }}>
									{mask && mask.length > 11 ? mask : "Account number"}
								</Typography>
								{cardLogo}
							</Box>
							<Box id={"Box_Exp" + index.toString()} sx={{ display: "flex", justifyContent: "space-between", width: "100%" }} title={expireTooltip}>
								<Typography variant="body2" sx={{ textTransform: "unset" }}>
									{mask && mask.length > 11 ? `${element.expMonth}/${element.expYear}` : accNumber}
								</Typography>
							</Box>
							<Box id={"Box_Message" + index.toString()}>
								{removeProcess && element.cardId === removeValue && <CircularStatic visible={removeProcess} />}
								{!removeProcess && element.cardId === removeValue && (
									<Typography variant="caption" color={"darkred"}>
										{removeMessage}
									</Typography>
								)}
							</Box>
						</Paper>
					);
				})}
			{props.editable && storeBoxLoadProcess && <CircularStatic visible={storeBoxLoadProcess} />}
			{props.editable && !storeBoxLoadProcess && !bankaxeptFrame && (
				<Box sx={{ pt: 2, width: "50px", display: "unset" }} title={cardTooltip}>
					<IconButton
						sx={{
							backgroundColor: "transparent",
						}}
						size="small"
						onClick={() => openStoreBox()}
						disabled={storeBoxLoadProcess}
					>
						<AddCardRoundedIcon
							sx={{
								color: (theme) => theme.palette.secondary.main,
								cursor: "pointer",
								fontSize: "25px",
							}}
						/>
					</IconButton>
				</Box>
			)}
			{props.editable && !storeBoxLoadProcess && (
				<Box sx={{ pt: 2, width: "50px", display: "unset" }} title={accountTooltip}>
					<IconButton
						sx={{
							backgroundColor: "transparent",
						}}
						size="small"
						onClick={() => openBankAxept()}
					>
						<QueuePlayNextIcon
							sx={{
								color: (theme) => theme.palette.secondary.main,
								cursor: "pointer",
								fontSize: "25px",
							}}
						/>
					</IconButton>
					{bankaxeptFrame && (
						<Card sx={{ borderRadius: 0, background: "unset", color: "unset", width: "fit-content" }}>
							<CardActions sx={{ pt: 1 }}>
								<Box onSubmit={handleSubmitAccountNumber} noValidate component="form" autoComplete="off">
									<CustomTextbox
										label={t`Account number`}
										name="token"
										id="token"
										variant="outlined"
										size="small"
										fullWidth
										placeholder="0000.00.00000"
										required
										onChange={handleChange}
										autoFocus
										maxLength={13}
									/>
									{bankaxeptResponse.length > 0 && !accountSubmitProcess && value && (
										<div style={{ width: "211px", height: "40px" }}>
											<Typography
												variant="caption"
												sx={{
													p: 1,
													overflow: "hidden",
													textOverflow: "ellipsis",
													display: "-webkit-box",
													"-webkit-line-clamp": "2",
													"line-clamp": "2",
													"-webkit-box-orient": "vertical",
												}}
												color={error ? "red" : "darkgreen"}
											>
												{bankaxeptResponse}
											</Typography>
										</div>
									)}
									<Button
										sx={{
											marginTop: 1,
											height: "30px",
											color: (theme) => theme.palette.secondary.main,
										}}
										type="submit"
										fullWidth
										variant="contained"
										size="small"
										disabled={!value || accountSubmitProcess}
									>
										{accountSubmitProcess && <CircularStatic visible={accountSubmitProcess} />}
										{!accountSubmitProcess && <Trans>Confirm</Trans>}
									</Button>
								</Box>
							</CardActions>
						</Card>
					)}
				</Box>
			)}
			{props.editable && storeBoxLoadProcess && (
				<Box sx={{ width: "80%", pt: 3 }}>
					<Typography color={"red"}>{storeboxResponse} ...</Typography>
				</Box>
			)}
			{props.editable && storebox.length > 0 && (
				<IFrame iframe="iframe" src={storebox} height="297px" width="243px" updateProcessing={updateProcessingOfStoreBox} />
			)}
			{props.editable && (
				<ConfirmationDialogRaw
					id="ringtone-menu"
					keepMounted
					open={open}
					onClose={handleClose}
					dataValue={removeValue}
					showValue={removeShowValue}
					title={removeShowValue.length === 11 ? t`Account` : t`Card`}
				/>
			)}
		</Box>
	);
}
