import React from "react";
import { IThemeSettings } from "../types/interface";

const ContainerThemeSettingsDefaults: IThemeSettings = {
	positionFull: {
		zIndex: 1042,
		position: "fixed",
		width: "20em",
		height: "25em",
		bottom: "2em",
		right: "2em",
	},
	positionDynamic: {
		zIndex: 1042,
		position: "fixed",
		width: "20em",
		height: "25em",
		bottom: "2em",
		right: "2em",
	},
	positionFix: {
		zIndex: 1042,
		position: "fixed",
		width: "20em",
		bottom: "2em",
		right: "2em",
	},
	positionClose: {
		zIndex: 1042,
		position: "fixed",
		width: "20em",
		bottom: "2em",
		right: "2em",
	},
	positionMinimize: {
		zIndex: 1042,
		position: "fixed",
		width: "20em",
		height: "25em",
		bottom: "2em",
		right: "2em",
	},
	overflow: false,
	floatInMobile: false,
};

export const ContainerThemeSettingsByConfig = (obj: IThemeSettings | null): IThemeSettings => {
	if (!obj) return ContainerThemeSettingsDefaults;
	const themeSetting: IThemeSettings = {
		positionFull: obj.positionFull,
		positionDynamic: obj.positionDynamic,
		positionFix: obj.positionFix,
		positionClose: obj.positionClose,
		positionMinimize: obj.positionMinimize,
		overflow: obj.overflow,
		floatInMobile: obj.floatInMobile,
	};
	return themeSetting;
};

export const ThemeSettingsContext = React.createContext(ContainerThemeSettingsDefaults);
