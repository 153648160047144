import Button from "@mui/material/Button";
import { ReactComponent as VippsLogo } from "../assets/resources/Vipps.svg";
import Stack from "@mui/material/Stack";
import { Trans } from "@lingui/macro";

interface IProps {
	isValid: boolean;
	onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function VippsButton(props: IProps) {
	return (
		<Stack direction="row" spacing={2} sx={{ pt: 0.25 }}>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				size="small"
				disabled={!props.isValid}
				startIcon={<VippsLogo />}
				onClick={props.onClick}
				sx={{
					bgcolor: "#FF5B24",
					color: "white",
					filter: !props.isValid ? "grayscale(100%)" : "",
					fillOpacity: !props.isValid ? "0.1" : "",
					fontSize: (theme) => theme.typography.subtitle2.fontSize,
					height: "30px",
					"&:hover": {
						bgcolor: "#FF5B24",
					},
				}}
			>
				<Trans>Continue with Vipps</Trans>
			</Button>
		</Stack>
	);
}
