import { log } from "../service/clientlog";
import CloseIcon from "@mui/icons-material/Close";
import ZoomOutMapRoundedIcon from "@mui/icons-material/ZoomOutMapRounded";
import MinimizeRoundedIcon from "@mui/icons-material/MinimizeRounded";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import { IconButton, Stack } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../context/usercontext";
import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { ConsoleType, WidgetSize, TopbarMenu } from "../types/enum";
import { APIContext } from "../context/apicontext";

interface Props {
	IconSize: "small" | "medium" | "large";
	IconName: string;
	Transform: string;
	Visibility: boolean;
	IgnoreResizeOnLoginRouter?: boolean;
}

const Resize = styled(IconButton)(({ theme }) => ({
	backgroundColor: "transparent",
	color: theme.palette.primary.main,
}));

const Close = styled(IconButton)(({ theme }) => ({
	backgroundColor: "transparent",
	color: theme.palette.primary.main,
}));

const Minimize = styled(IconButton)(({ theme }) => ({
	backgroundColor: "transparent",
	color: theme.palette.primary.main,
	marginLeft: "auto",
}));

export function TopBarIcons(props: Props) {
	const userContext = useContext(UserContext);
	const authContext = useContext(APIContext);

	const integrationContext = useContext(IntegrationSettingsContext);
	let hasResize = false,
		hasMinimize = false,
		hasClose = false,
		hasRefresh = false;
	integrationContext.topbar.forEach((element: TopbarMenu) => {
		switch (element) {
			case TopbarMenu.Close:
				hasClose = true;
				break;
			case TopbarMenu.Minimize:
				hasMinimize = true;
				break;
			case TopbarMenu.Resize:
				hasResize = true;
				break;
			case TopbarMenu.Refresh:
				hasRefresh = true;
				break;
			default:
				log("integration topbar item mismatch.", ConsoleType.Error);
				break;
		}
	});

	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	const IsMobile = width <= 1024;

	const close = () => {
		userContext.toggleWidgetMode(userContext.widgetModeState());
	};

	const toggle = () => {
		if (userContext.widgetSizeState() !== WidgetSize.Full && IsMobile) {
			userContext.changeWidgetSize(WidgetSize.Full);
			return;
		}
		if (userContext.widgetSizeState() !== WidgetSize.Main && !IsMobile) {
			userContext.changeWidgetSize(WidgetSize.Main);
			return;
		}
	};

	const minimize = () => {
		if (userContext.widgetSizeState() !== WidgetSize.Minimize) {
			userContext.changeWidgetSize(WidgetSize.Minimize);
			return;
		}
	};

	const refresh = () => {
		authContext.bonusInfo({ loadAPI: true });
		authContext.voucherInfo({ loadAPI: true });
		authContext.userInfo({ loadAPI: true });
		let i = 1;
		var innerArrow = document.getElementsByClassName("MuiSvgIcon-root");
		function myLoop() {
			setTimeout(function () {
				innerArrow[0].setAttribute("style", "-webkit-transform: rotate(" + i + "deg); -moz-transform: rotate(" + i + "deg); transform: rotate(" + i + "deg)");
				i++;
				if (i < 180) {
					myLoop();
				}
			}, 10);
		}
		myLoop();
	};

	const direction = !hasResize && !hasMinimize ? "row-reverse" : "row";

	return (
		<Stack direction={direction} justifyContent="" sx={{ pt: 2, pr: 2.5, pl: 2.5, pb: 2, minHeight: "34px", maxHeight: "34px" }}>
			{props.Visibility && !props.IgnoreResizeOnLoginRouter && hasResize && (
				<Resize title="resize" size={props.IconSize} onClick={() => toggle()} sx={{ color: (theme) => theme.palette.text.primary }}>
					<ZoomOutMapRoundedIcon />
				</Resize>
			)}
			{props.Visibility && hasMinimize && userContext.widgetSizeState() !== WidgetSize.Minimize && (
				<Minimize title="minimize" size={props.IconSize} onClick={() => minimize()} sx={{ color: (theme) => theme.palette.text.primary }}>
					<MinimizeRoundedIcon />
				</Minimize>
			)}
			{props.Visibility && hasClose && (
				<Close title="state" size={props.IconSize} onClick={() => close()} sx={{ color: (theme) => theme.palette.text.primary }}>
					<CloseIcon />
				</Close>
			)}
			{props.Visibility && hasRefresh && (
				<Close title="refresh" size={props.IconSize} onClick={() => refresh()} sx={{ color: (theme) => theme.palette.text.primary }}>
					<CachedRoundedIcon />
				</Close>
			)}
		</Stack>
	);
}
