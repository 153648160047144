import { CardContent, Link, Box, Typography, Card, Button, CardActions, useTheme } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import React, { useContext, useEffect, useState } from "react";
import { APIContext } from "../context/apicontext";
import { CardType, MemberDTO } from "../apiclient/MemberAPI";
import { TopBarIcons } from "../components/topbar";
import CircularStatic from "../components/progress";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { Constants } from "../constants/constants";
import IFrame from "../components/iframe";
import { CustomTextbox } from "../components/textbox";
import { StoreboxURLBuilder } from "../service/helper";
import { bankAccountPattern } from "../types/interface";
import { LanguageContext } from "../context/languagecontext";
import { log } from "../service/clientlog";

export function BankUI() {
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const [error, setError] = useState(false);
	const [storeboxDOM, setStoreboxDOM] = useState("");
	const [storeboxHash, setStoreboxHash] = useState<string | undefined>(localStorage.getItem(Constants.STOREBOX_HASH) as string);
	const [storeboxLoader, setStoreboxLoader] = useState(true);
	const [storeboxLoaderResponse, setStoreboxLoaderResponse] = useState(t`Please Wait`);
	const [header, setHeader] = useState(t`Please add card number`);
	const [subHeader, setSubHeader] = useState(
		t`Card number are used to award bonuses and other benefits automatically. The bank card will not be used for any payments.`
	);
	const [continueText, setContinueText] = useState(t`Continue without card number`);
	const theme = useTheme();
	const languageContext = useContext(LanguageContext);
	const [bankaxeptProgress, setBankaxeptProgress] = useState(false);
	const [bankaxeptResponse, setBankAxeptResponse] = useState("");
	const [bankaxeptAccount, setBankaxeptAccount] = useState("");

	const handleChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		bankaxeptAccount && setBankaxeptAccount("");
		bankaxeptResponse && setBankAxeptResponse("");
		const digit13 = bankAccountPattern.test(event.target.value.toString()) && event.target.value.length === 13;
		const digit11 = event.target.value.length === 11 && !isNaN(Number(event.target.value));
		if (digit11 || digit13) {
			setBankaxeptAccount(event.target.value);
			setError(false);
		}
	};

	const handleSubmitAccountNumber = async (event: React.FormEvent<HTMLFormElement>) => {
		setBankaxeptProgress(true);
		setError(false);
		setBankAxeptResponse("");
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const problem = await authContext.updateCardByBankAxept(data.get("token")?.toString());
		if (problem) {
			setError(true);
			setBankAxeptResponse(problem);
			setBankaxeptProgress(false);
			return;
		}
		setBankAxeptResponse(t`Account successfully added.`);
		const member = (await authContext.userInfo({ loadAPI: false })) as MemberDTO;
		await authContext.updateInfo(member, IntegrationSetting.authRequiredAccount, member.userAgreementAcceptDate === null);
		setBankaxeptProgress(false);
	};

	const [nextStepMessage, setNextStepMessage] = useState("");
	const [finalStepProgress, setFinalStepProgress] = useState(false);

	const checkMemberAccount = async () => {
		const cardDto = await authContext.cardInfo({ loadAPI: false });
		let account = false;
		if (cardDto) {
			cardDto.forEach((rec) => {
				if (!account && rec.cardType === CardType.AccountNumber) {
					account = true;
					return true;
				}
			});
		}
		return account;
	};

	const nextStepTasks = async (final: boolean) => {
		if (!final) {
			setStoreboxHash("");
			localStorage.removeItem(Constants.STOREBOX_HASH);
			setStoreboxLoaderResponse("");
			const hasAccount = await checkMemberAccount();
			if (!IntegrationSetting.authRequiredAccount || hasAccount) {
				try {
					setBankaxeptProgress(true);
					setHeader("Loading your information");
					setSubHeader("You have already registered the account number.");
					setContinueText("Please wait ...");
					await authContext.updateInfo((await authContext.userInfo({ loadAPI: false })) as MemberDTO, false, false, true);
					return;
				} catch (error: any) {
					setBankaxeptProgress(false);
					setStoreboxLoaderResponse(`Please contact support for ${error.statusText}.`);
					log("internal", error);
				}
			}
			setHeader(t`Please add account number`);
			setSubHeader(t`Account number is used to award bonuses and other benefits automatically. Account number cannot be used to make payments.`);
			setContinueText(t`Continue without account number`);
			return;
		}
		if (final && !storeboxHash && !nextStepMessage) {
			setNextStepMessage(
				t`If you do not add an account number to your profile, we will be unable to match your purchase and grant you bonus points automatically. Click "Continue without account number" again to continue.`
			);
			return;
		}
		const taskLoaderAsync = async () => {
			setFinalStepProgress(true);
			const member = (await authContext.userInfo({ loadAPI: false })) as MemberDTO;
			await authContext.updateInfo(member, false, member.userAgreementAcceptDate === null);
			window.history.replaceState({}, document.title, "/");
			setFinalStepProgress(false);
		};
		taskLoaderAsync();
	};

	useEffect(() => {
		const CheckRedirection = async (hasCard: boolean = false) => {
			if (hasCard) {
				await nextStepTasks(false);
				return true;
			}
			const search = window.location.search;
			const storebox = search.includes(Constants.StoreboxSearchParam);
			if (storebox) {
				const params = new URLSearchParams(search);
				let status = params.get(Constants.StoreboxSearchParam);
				if (status === Constants.StoreboxRedirect) {
					await nextStepTasks(false);
					return true;
				}
			}
			return false;
		};

		const openStoreBox = async () => {
			setError(false);
			let result: string = "";
			let buttonBackgroundColor = () => {
				return theme.palette.primary.dark;
			};
			let buttonColor = () => {
				return theme.palette.text.secondary;
			};
			let labelColor = () => {
				return theme.palette.text.primary;
			};
			try {
				if (!storeboxHash) setStoreboxHash(localStorage.getItem(Constants.STOREBOX_HASH) as string);
				if (await CheckRedirection()) {
					setStoreboxLoader(false);
					return;
				}

				result = await authContext.loadStoreboxIframe({
					returnURL: StoreboxURLBuilder(),
					language: languageContext.language,
					css: {
						bodyBackgroundColor: "transparent",
						buttonBackgroundColor: buttonBackgroundColor(),
						buttonColor: buttonColor(),
						labelColor: labelColor(),
					},
				});
				if (result.startsWith(Constants.STOREBOX_URL)) {
					setStoreboxDOM(result);
					const hash = new URLSearchParams(result ?? "").get(Constants.StoreboxHashSearchParam) ?? "";
					localStorage.setItem(Constants.STOREBOX_HASH, hash);
					setStoreboxHash(hash);
				}
			} catch (error: any) {
				if (error.status === 400) {
					setStoreboxLoaderResponse(t`Please try again later.`);
				}
				if (error.status === 401) {
					setStoreboxLoaderResponse(t`Please logout and sign-in again.`);
				}
				setError(true);
			} finally {
				if (error)
					setTimeout(() => {
						setStoreboxLoader(false);
					}, 2000);
			}
		};

		const checkMemberCard = async () => {
			const cardDto = await authContext.cardInfo({ loadAPI: false });
			let card = false;
			if (cardDto) {
				cardDto.forEach((rec) => {
					if (!card && rec.cardType !== CardType.AccountNumber) {
						card = true;
						return;
					}
				});
			}
			return card;
		};

		const taskLoaderAsync = async () => {
			if (await CheckRedirection(await checkMemberCard())) return;
			await openStoreBox();
		};
		taskLoaderAsync();
	}, []);

	const updateProcessingOfStoreBox = (processing: boolean) => {
		setStoreboxLoader(processing);
	};

	const GoNextStep = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (nextStepMessage.length === 0 && storeboxHash && storeboxHash?.length > 0) {
			const msg = t`If you do not add a payment card to your profile, we will be unable to match your purchase and grant you bonus points automatically. Click "Continue without card number" again to continue.`;
			setNextStepMessage(msg);
			return;
		}
		setNextStepMessage("");
		await nextStepTasks(!(storeboxHash !== undefined && storeboxHash.length > 0));
	};

	return (
		<React.Fragment>
			<TopBarIcons Visibility={IntegrationSetting.topbarOnLoginRouter} IgnoreResizeOnLoginRouter IconSize="small" IconName="loyall" Transform="scale(1);" />
			<Card
				sx={{
					background: "unset",
					color: "unset",
					borderBottomLeftRadius: "inherit",
					borderBottomRightRadius: "inherit",
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					boxShadow: 0,
				}}
			>
				<CardContent sx={{ color: (theme) => theme.palette.text.primary, pr: 4.5, pl: 4.5, pb: 0 }}>
					<Typography variant="h6">{header}</Typography>
					<Typography variant="body2" textAlign={"center"} gutterBottom>
						{subHeader}
					</Typography>
				</CardContent>
				<CardActions sx={{ pr: 4.5, pl: 4.5, pb: 0, pt: 0 }}>
					<Box onSubmit={GoNextStep} noValidate component="form" autoComplete="off" sx={{ textAlign: "center" }}>
						<Link
							textTransform={"unset"}
							href="#"
							underline="hover"
							variant="overline"
							component="button"
							onClick={() => GoNextStep}
							color="text.primary"
							sx={{
								color: (theme) => theme.palette.text.primary,
								fontSize: (theme) => theme.typography.subtitle1.fontSize,
								fontWeight: (theme) => theme.typography.h1.fontWeight,
								lineHeight: (theme) => theme.typography.h1.lineHeight,
							}}
						>
							{finalStepProgress && <CircularStatic visible={finalStepProgress} />}
							{!finalStepProgress && continueText}
						</Link>
						{nextStepMessage && (
							<Typography variant="subtitle1" sx={{ p: 1, color: (theme) => theme.palette.warning.main }}>
								{nextStepMessage}
							</Typography>
						)}
					</Box>
				</CardActions>
				{storeboxLoaderResponse && IntegrationSetting.authRequiredCard && (
					<CardActions sx={{ pt: 0, pr: 2, pl: 2, pb: 0 }}>
						<Box noValidate component="form" autoComplete="off" sx={{ minWidth: "275px", maxWidth: "275px" }}>
							{storeboxLoader && (
								<Box sx={{ width: "80%", pt: 3 }}>
									<Typography color={"red"}>{storeboxLoaderResponse} ...</Typography>
									<CircularStatic visible={storeboxLoader} />
								</Box>
							)}
							{storeboxDOM.length > 0 && (
								<IFrame iframe="iframe" src={storeboxDOM} height="360px" width="275px" updateProcessing={updateProcessingOfStoreBox} />
							)}
						</Box>
					</CardActions>
				)}
				{!storeboxLoaderResponse && IntegrationSetting.authRequiredAccount && (
					<CardActions sx={{ pt: 2, pr: 2, pl: 2, pb: 4 }}>
						<Box onSubmit={handleSubmitAccountNumber} noValidate component="form" autoComplete="off">
							<CustomTextbox
								name="token"
								id="token"
								variant="outlined"
								size="small"
								fullWidth
								placeholder="0000.00.00000"
								required
								onChange={handleChange}
								autoFocus
								maxLength={13}
								height={"30px"}
								disabled={bankaxeptProgress}
							/>
							{bankaxeptResponse.length > 0 && !bankaxeptProgress && bankaxeptAccount && (
								<Typography variant="caption" sx={{ p: 1 }} color={error ? "red" : "darkgreen"}>
									{bankaxeptResponse}
								</Typography>
							)}
							<Button
								sx={{
									marginTop: 1,
									height: "30px",
									color: (theme) => theme.palette.secondary.main,
									"&.Mui-disabled": {
										color: (theme) => theme.palette.secondary.main,
										backgroundColor: (theme) => theme.palette.background.default,
										border: "unset",
										opacity: 0.8,
									},
								}}
								type="submit"
								fullWidth
								variant="contained"
								size="small"
								disabled={bankaxeptProgress}
							>
								{bankaxeptProgress && <CircularStatic visible={bankaxeptProgress} />}
								{!bankaxeptProgress && <Trans>Continue</Trans>}
							</Button>
						</Box>
					</CardActions>
				)}
			</Card>
		</React.Fragment>
	);
}
