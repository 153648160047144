import { Breakpoint, createTheme, PaletteMode, responsiveFontSizes } from "@mui/material";
import { ThemeCodes } from "../types/enum";

export function Get(themeStr: keyof typeof ThemeCodes, palette?: string) {
	if (palette) {
		return ThemeByConfig(palette);
	}
	const theme: ThemeCodes = ThemeCodes[themeStr];
	switch (theme) {
		case ThemeCodes.Loyall:
			return LoyallTheme();
		case ThemeCodes.Retro:
			return RetroTheme();
		default:
			return DefaultTheme(ThemeCodes[theme] as PaletteMode);
	}
}

export function ThemeByConfig(palette: string) {
	const obj = JSON.parse(palette);
	let loyallTheme = createTheme({
		shape: {
			borderRadius: 20,
		},
		components: {
			MuiFormHelperText: {
				defaultProps: {
					style: {
						backgroundColor: "transparent",
					},
				},
			},
			MuiCardContent: {
				defaultProps: {
					style: {
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: "secondary",
					},
				},
			},
			MuiCardActions: {
				defaultProps: {
					style: {
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					},
				},
			},
			MuiButton: {
				defaultProps: {
					style: {
						borderRadius: 5,
						textTransform: "none",
					},
				},
			},
			MuiScopedCssBaseline: {
				defaultProps: {
					style: {
						backgroundColor: "Transparent",
						color: "unset",
					},
				},
			},
			MuiTextField: {
				defaultProps: {
					style: {
						borderRadius: 5,
						marginTop: 0,
					},
				},
			},
		},
		typography: {
			htmlFontSize: 16,
			//pxToRem: (px: number) => {return ''},
			fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
			fontSize: 12,
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 500,
			fontWeightBold: 700,
			h1: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 300,
				fontSize: "6rem",
				lineHeight: 1.167,
				letterSpacing: "-0.01562em",
				textTransform: "inherit",
			},
			h2: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 300,
				fontSize: "3.75rem",
				lineHeight: 1.2,
				letterSpacing: "-0.00833em",
				textTransform: "inherit",
			},
			h3: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "3rem",
				lineHeight: 1.167,
				letterSpacing: "0em",
				textTransform: "inherit",
			},
			h4: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "2.125rem",
				lineHeight: 1.235,
				letterSpacing: "0.00735em",
				textTransform: "inherit",
			},
			h5: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "1.375rem",
				lineHeight: 1.334,
				letterSpacing: "0em",
				textTransform: "inherit",
			},
			h6: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "1.25rem",
				lineHeight: 1.6,
				letterSpacing: "0.0075em",
				textTransform: "inherit",
			},
			subtitle1: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.625rem",
				lineHeight: 1.75,
				letterSpacing: "0.00625", //10px
				textTransform: "inherit",
			},
			subtitle2: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "0.875rem",
				lineHeight: 1.57,
				letterSpacing: "0.00714em",
				textTransform: "inherit",
			},
			body1: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "1rem",
				lineHeight: 1.5,
				letterSpacing: "0.00938em",
				textTransform: "inherit",
			},
			body2: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.75rem",
				lineHeight: 1.43,
				letterSpacing: "0.01071em",
				textTransform: "inherit",
			},
			button: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "0.875rem",
				lineHeight: 1.75,
				letterSpacing: "0.02857em",
				textTransform: "uppercase",
			},
			caption: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.75rem",
				lineHeight: 1.66,
				letterSpacing: "0.03333em",
				textTransform: "inherit",
			},
			overline: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.75rem",
				lineHeight: 2.66,
				letterSpacing: "0.08333em",
				textTransform: "uppercase",
			},
		},
	});

	loyallTheme.palette.mode = obj.mode;
	loyallTheme.palette.background = obj.background;
	loyallTheme.palette.primary = obj.primary;
	loyallTheme.palette.secondary = obj.secondary;
	loyallTheme.palette.warning = obj.warning;
	loyallTheme.palette.text = obj.text;
	loyallTheme.palette.success = obj.success;

	loyallTheme = responsiveFontSizes(loyallTheme);
	return loyallTheme;
}

export function DefaultTheme(paletteMode: PaletteMode) {
	return createTheme({
		breakpoints: {
			keys: ["xs", "sm", "md", "lg", "xl"],
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
			up: (key: Breakpoint | number) => {
				return "";
			},
			down: (key: Breakpoint | number) => {
				return "";
			},
			between: (start: Breakpoint | number, end: Breakpoint | number) => {
				return "";
			},
			only: (key: Breakpoint) => {
				return "";
			},
			not: (key: Breakpoint) => {
				return "";
			},
			unit: "px",
		},
		direction: "ltr",
		components: {
			MuiFormHelperText: {
				defaultProps: {
					style: {
						backgroundColor: "transparent",
					},
				},
			},
			MuiCardContent: {
				defaultProps: {
					style: {
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: "secondary",
					},
				},
			},
			MuiCardActions: {
				defaultProps: {
					style: {
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					},
				},
			},
			MuiButton: {
				defaultProps: {
					style: {
						borderRadius: 5,
						textTransform: "none",
					},
				},
			},
			MuiScopedCssBaseline: {
				defaultProps: {
					style: {
						backgroundColor: "Transparent",
						color: "unset",
					},
				},
			},
			MuiTextField: {
				defaultProps: {
					style: {
						borderRadius: 5,
						marginTop: 0,
					},
				},
			},
		},

		palette: {
			mode: paletteMode,
			...(paletteMode === "light"
				? {
						common: {
							black: "#000",
							white: "#fff",
						},
						primary: {
							main: "#1976d2",
							light: "#42a5f5",
							dark: "#1565c0",
							contrastText: "#fff",
						},
						secondary: {
							main: "#9c27b0",
							light: "#ba68c8",
							dark: "#7b1fa2",
							contrastText: "#fff",
						},
						error: {
							main: "#d32f2f",
							light: "#ef5350",
							dark: "#c62828",
							contrastText: "#fff",
						},
						warning: {
							main: "#ed6c02",
							light: "#ff9800",
							dark: "#e65100",
							contrastText: "#fff",
						},
						info: {
							main: "#0288d1",
							light: "#03a9f4",
							dark: "#01579b",
							contrastText: "#fff",
						},
						success: {
							main: "#2e7d32",
							light: "#4caf50",
							dark: "#1b5e20",
							contrastText: "#fff",
						},
						grey: {
							50: "#fafafa",
							100: "#f5f5f5",
							200: "#eeeeee",
							300: "#e0e0e0",
							400: "#bdbdbd",
							500: "#9e9e9e",
							600: "#757575",
							700: "#616161",
							800: "#424242",
							900: "#212121",
							A100: "#f5f5f5",
							A200: "#eeeeee",
							A400: "#bdbdbd",
							A700: "#616161",
						},
						contrastThreshold: 3,
						getContrastText: (background: string) => {
							return background;
						},
						/*augmentColor: () => { },*/
						tonalOffset: 0.2,
						text: {
							primary: "rgba(0, 0, 0, 0.87)",
							secondary: "rgba(0, 0, 0, 0.6)",
							disabled: "rgba(0, 0, 0, 0.38)",
						},
						divider: "rgba(0, 0, 0, 0.12)",
						background: {
							paper: "#f5f5f5",
							default: "#fff",
						},
						action: {
							active: "rgba(0, 0, 0, 0.54)",
							hover: "rgba(0, 0, 0, 0.04)",
							hoverOpacity: 0.04,
							selected: "rgba(0, 0, 0, 0.08)",
							selectedOpacity: 0.08,
							disabled: "rgba(0, 0, 0, 0.26)",
							disabledBackground: "rgba(0, 0, 0, 0.12)",
							disabledOpacity: 0.38,
							focus: "rgba(0, 0, 0, 0.12)",
							focusOpacity: 0.12,
							activatedOpacity: 0.12,
						},
				  }
				: {
						common: {
							black: "#000",
							white: "#fff",
						},
						primary: {
							main: "#e3f2fd",
							light: "#90caf9",
							dark: "#42a5f5",
							contrastText: "rgba(0, 0, 0, 0.87)",
						},
						secondary: {
							main: "#ce93d8",
							light: "#f3e5f5",
							dark: "#ab47bc",
							contrastText: "rgba(0, 0, 0, 0.87)",
						},
						error: {
							main: "#f44336",
							light: "#e57373",
							dark: "#d32f2f",
							contrastText: "#fff",
						},
						warning: {
							main: "#ffa726",
							light: "#ffb74d",
							dark: "#f57c00",
							contrastText: "rgba(0, 0, 0, 0.87)",
						},
						info: {
							main: "#29b6f6",
							light: "#4fc3f7",
							dark: "#0288d1",
							contrastText: "rgba(0, 0, 0, 0.87)",
						},
						success: {
							main: "#66bb6a",
							light: "#81c784",
							dark: "#388e3c",
							contrastText: "rgba(0, 0, 0, 0.87)",
						},
						grey: {
							50: "#fafafa",
							100: "#f5f5f5",
							200: "#eeeeee",
							300: "#e0e0e0",
							400: "#bdbdbd",
							500: "#9e9e9e",
							600: "#757575",
							700: "#616161",
							800: "#424242",
							900: "#212121",
							A100: "#f5f5f5",
							A200: "#eeeeee",
							A400: "#bdbdbd",
							A700: "#616161",
						},
						contrastThreshold: 3,
						getContrastText: (background: string) => {
							return background;
						},
						/*augmentColor: () => { },*/
						tonalOffset: 0.2,
						text: {
							primary: "#fff",
							secondary: "rgba(255, 255, 255, 0.7)",
							disabled: "rgba(255, 255, 255, 0.5)",
						},
						divider: "rgba(255, 255, 255, 0.12)",
						background: {
							paper: "#121212",
							default: "#121212",
						},
						action: {
							active: "#fff",
							hover: "rgba(255, 255, 255, 0.08)",
							hoverOpacity: 0.08,
							selected: "rgba(255, 255, 255, 0.16)",
							selectedOpacity: 0.16,
							disabled: "rgba(255, 255, 255, 0.3)",
							disabledBackground: "rgba(255, 255, 255, 0.12)",
							disabledOpacity: 0.38,
							focus: "rgba(255, 255, 255, 0.12)",
							focusOpacity: 0.12,
							activatedOpacity: 0.24,
						},
				  }),
		},
		// spacing: () => {
		// 	return 8;
		// },
		shape: {
			borderRadius: 4,
		},
		mixins: {
			toolbar: {
				minHeight: 56,
			},
			//'@media(min-width:0px) and (orientation:landscape)': {
			//    minHeight: 48
			//},
			//'@media(min-width:600px)': {
			//    minHeight: 64
			//},
		},
		shadows: [
			"none",
			"0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
			"0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
			"0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
			"0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
			"0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
			"0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
			"0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
			"0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
			"0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
			"0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
			"0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
			"0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
			"0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
			"0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
			"0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
			"0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
			"0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
			"0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
			"0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
			"0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
			"0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
			"0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
			"0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
			"0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
		],
		typography: {
			htmlFontSize: 16,
			//pxToRem: (px: number) => {return ''},
			fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
			fontSize: 14,
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 500,
			fontWeightBold: 700,
			h1: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 300,
				fontSize: "6rem",
				lineHeight: 1.167,
				letterSpacing: "-0.01562em",
			},
			h2: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 300,
				fontSize: "3.75rem",
				lineHeight: 1.2,
				letterSpacing: "-0.00833em",
			},
			h3: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "3rem",
				lineHeight: 1.167,
				letterSpacing: "0em",
			},
			h4: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "2.125rem",
				lineHeight: 1.235,
				letterSpacing: "0.00735em",
			},
			h5: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "1.375rem",
				lineHeight: 1.334,
				letterSpacing: "0em",
			},
			h6: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "1.25rem",
				lineHeight: 1.6,
				letterSpacing: "0.0075em",
			},
			subtitle1: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "1rem",
				lineHeight: 1.75,
				letterSpacing: "0.00938em",
			},
			subtitle2: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "0.875rem",
				lineHeight: 1.57,
				letterSpacing: "0.00714em",
			},
			body1: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "1rem",
				lineHeight: 1.5,
				letterSpacing: "0.00938em",
			},
			body2: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.875rem",
				lineHeight: 1.43,
				letterSpacing: "0.01071em",
			},
			button: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "0.875rem",
				lineHeight: 1.75,
				letterSpacing: "0.02857em",
				textTransform: "uppercase",
			},
			caption: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.75rem",
				lineHeight: 1.66,
				letterSpacing: "0.03333em",
			},
			overline: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.75rem",
				lineHeight: 2.66,
				letterSpacing: "0.08333em",
				textTransform: "uppercase",
			},
		},
		transitions: {
			getAutoHeightDuration: (height: number) => {
				return height;
			},
			create: () => {
				return "";
			},
			easing: {
				easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
				easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
				easeIn: "cubic-bezier(0.4, 0, 1, 1)",
				sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
			},
			duration: {
				shortest: 150,
				shorter: 200,
				short: 250,
				standard: 300,
				complex: 375,
				enteringScreen: 225,
				leavingScreen: 195,
			},
		},
		zIndex: {
			mobileStepper: 1000,
			fab: 1050,
			speedDial: 1050,
			appBar: 1100,
			drawer: 1200,
			modal: 1300,
			snackbar: 1400,
			tooltip: 1500,
		},
	});
}

export function RetroTheme() {
	return createTheme({
		palette: {
			mode: "dark",
			primary: {
				main: "#0f0",
			},
			background: {
				default: "#111111",
				paper: "#212121",
			},
		},
		typography: {
			fontFamily: "Open Sans",
			h1: {
				fontFamily: "Ubuntu Mono",
			},
			h2: {
				fontFamily: "Ubuntu Mono",
			},
			h3: {
				fontFamily: "Ubuntu Mono",
			},
			h4: {
				fontFamily: "Ubuntu Mono",
			},
			h6: {
				fontFamily: "Ubuntu Mono",
			},
			h5: {
				fontFamily: "Ubuntu Mono",
			},
			subtitle1: {
				fontFamily: "Ubuntu Mono",
			},
			subtitle2: {
				fontFamily: "Ubuntu Mono",
			},
			button: {
				fontFamily: "Ubuntu Mono",
				fontWeight: 900,
			},
			overline: {
				fontFamily: "Ubuntu Mono",
			},
			caption: {
				fontSize: 10,
			},
		},
		components: {
			MuiFormHelperText: {
				defaultProps: {
					style: {
						backgroundColor: "transparent",
					},
				},
			},
			MuiCardContent: {
				defaultProps: {
					style: {
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: "secondary",
					},
				},
			},
			MuiCardActions: {
				defaultProps: {
					style: {
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					},
				},
			},
			MuiButton: {
				defaultProps: {
					style: {
						borderRadius: 5,
						textTransform: "none",
					},
				},
			},
			MuiScopedCssBaseline: {
				defaultProps: {
					style: {
						backgroundColor: "Transparent",
						color: "unset",
					},
				},
			},
			MuiTextField: {
				defaultProps: {
					style: {
						borderRadius: 5,
						marginTop: 0,
					},
				},
			},
		},
	});
}

export function LoyallTheme() {
	let loyallTheme = createTheme({
		shape: {
			borderRadius: 20,
		},
		/*spacing: () => { return 8 },*/
		palette: {
			mode: "light",
			background: {
				paper: "#134E6C",
				default: "#fff",
			},
			primary: {
				main: "#fff",
				light: "#EBEBEB",
				dark: "#fff", //button bg color
			},
			secondary: {
				main: "#000",
				light: "#5C5C5C",
				dark: "#DBDBDB",
			},
			warning: {
				main: "#FF0062",
				light: "yellow",
				dark: "#1976D2",
			},
			text: {
				primary: "#fff",
				secondary: "#000", // button text color
			},
			success: {
				main: "#08CD04",
				dark: "#2E7D32",
				light: "#ED6C02",
			},
		},
		/*TODO: make sure it's set for production and all components*/
		/*default styling*/
		components: {
			MuiFormHelperText: {
				defaultProps: {
					style: {
						backgroundColor: "transparent",
					},
				},
			},
			MuiCardContent: {
				defaultProps: {
					style: {
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: "secondary",
					},
				},
			},
			MuiCardActions: {
				defaultProps: {
					style: {
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					},
				},
			},
			MuiButton: {
				defaultProps: {
					style: {
						borderRadius: 5,
						textTransform: "none",
					},
				},
			},
			MuiScopedCssBaseline: {
				defaultProps: {
					style: {
						backgroundColor: "Transparent",
						color: "unset",
					},
				},
			},
			MuiTextField: {
				defaultProps: {
					style: {
						borderRadius: 5,
						marginTop: 0,
					},
				},
			},
		},
		typography: {
			htmlFontSize: 16,
			//pxToRem: (px: number) => {return ''},
			fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
			fontSize: 12,
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 500,
			fontWeightBold: 700,
			h1: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 300,
				fontSize: "6rem",
				lineHeight: 1.167,
				letterSpacing: "-0.01562em",
				textTransform: "inherit",
			},
			h2: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 300,
				fontSize: "3.75rem",
				lineHeight: 1.2,
				letterSpacing: "-0.00833em",
				textTransform: "inherit",
			},
			h3: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "3rem",
				lineHeight: 1.167,
				letterSpacing: "0em",
				textTransform: "inherit",
			},
			h4: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "2.125rem",
				lineHeight: 1.235,
				letterSpacing: "0.00735em",
				textTransform: "inherit",
			},
			h5: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "1.375rem",
				lineHeight: 1.334,
				letterSpacing: "0em",
				textTransform: "inherit",
			},
			h6: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "1.25rem",
				lineHeight: 1.6,
				letterSpacing: "0.0075em",
				textTransform: "inherit",
			},
			subtitle1: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.625rem",
				lineHeight: 1.75,
				letterSpacing: "0.00625", //10px
				textTransform: "inherit",
			},
			subtitle2: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "0.875rem",
				lineHeight: 1.57,
				letterSpacing: "0.00714em",
				textTransform: "inherit",
			},
			body1: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "1rem",
				lineHeight: 1.5,
				letterSpacing: "0.00938em",
				textTransform: "inherit",
			},
			body2: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.75rem",
				lineHeight: 1.43,
				letterSpacing: "0.01071em",
				textTransform: "inherit",
			},
			button: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 500,
				fontSize: "0.875rem",
				lineHeight: 1.75,
				letterSpacing: "0.02857em",
				textTransform: "uppercase",
			},
			caption: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.75rem",
				lineHeight: 1.66,
				letterSpacing: "0.03333em",
				textTransform: "inherit",
			},
			overline: {
				fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
				fontWeight: 400,
				fontSize: "0.75rem",
				lineHeight: 2.66,
				letterSpacing: "0.08333em",
				textTransform: "uppercase",
			},
		},
	});
	loyallTheme = responsiveFontSizes(loyallTheme);
	return loyallTheme;
}
