import { log } from "./service/clientlog";
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import RedeemIcon from "@mui/icons-material/Redeem";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { t } from "@lingui/macro";
import { APIContext } from "./context/apicontext";
import { UserContext } from "./context/usercontext";
import { IntegrationSettingsContext } from "./context/integrationsettingscontext";
import { ConsoleType, MainMenu, WidgetSize } from "./types/enum";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

interface Props {
	size: WidgetSize;
	isMobile: boolean;
}

const BottomNavBar = (props: Props) => {
	let loc = useLocation().pathname;
	if (loc.charAt(0) === "/") loc = loc.substring(1);

	if (loc === "") loc = MainMenu[MainMenu.Benefits].toLowerCase();

	const [activeTabs, setActiveTabs] = useState(loc);
	const navigate = useNavigate();
	useEffect(() => {
		switch (activeTabs.toLowerCase()) {
			case MainMenu[MainMenu.Profile].toLowerCase():
				navigate("/profile");
				break;
			case MainMenu[MainMenu.Benefits].toLowerCase():
				navigate("/benefits");
				break;
			case MainMenu[MainMenu.Support].toLowerCase():
				navigate("/support");
				break;
			case MainMenu[MainMenu.Settings].toLowerCase():
				navigate("/settings");
				break;
			case MainMenu[MainMenu.Purchases].toLowerCase():
				navigate("/purchases");
				break;
			default:
				log("Unknown tab", ConsoleType.Warning, activeTabs);
				break;
		}
	}, [activeTabs, navigate]);

	let menu = [
		{
			key: MainMenu[MainMenu.Benefits].toLowerCase(),
			label: t`Benefits`,
			code: MainMenu.Benefits,
			icon: () => <RedeemIcon />,
		},
		{
			key: MainMenu[MainMenu.Profile].toLowerCase(),
			label: t`Profile`,
			code: MainMenu.Profile,
			icon: () => <PersonIcon />,
		},
		{
			key: MainMenu[MainMenu.Purchases].toLowerCase(),
			label: t`Orders`,
			code: MainMenu.Purchases,
			icon: () => <ShoppingBagIcon />,
		},
		{
			key: MainMenu[MainMenu.Settings].toLowerCase(),
			label: t`Settings`,
			code: MainMenu.Settings,
			icon: () => <SettingIcon />,
		},
		{
			key: MainMenu[MainMenu.Support].toLowerCase(),
			label: t`Support`,
			code: MainMenu.Support,
			icon: () => <ContactSupportIcon />,
		},
		{
			key: MainMenu[MainMenu.Logout].toLowerCase(),
			label: t`Logout`,
			code: MainMenu.Logout,
			icon: () => <LogoutIcon />,
		},
	];
	const authContext = useContext(APIContext);
	const userContext = useContext(UserContext);
	const integrationContext = useContext(IntegrationSettingsContext);

	menu = menu.filter(
		(x) =>
			Object.values(integrationContext.menu)
				.filter((v) => !isNaN(Number(v)))
				.indexOf(x.code) >= 0
	);

	if (props.size === WidgetSize.Small) {
		for (let j = 0; j < menu.length; j++) {
			if (menu[j].key !== "logout") delete menu[j];
		}
	}

	let style = {};
	switch (userContext.widgetSizeState()) {
		case WidgetSize.Full:
			style = {
				position: "absolute",
				width: "100%",
				bottom: "0",
				zIndex: 1,
			};
			break;
		case WidgetSize.Small:
			break;
		case WidgetSize.Main:
			style = {
				position: "relative",
				top: "0",
				width: "100%",
				zIndex: 1,
			};
			break;
		default:
			break;
	}

	return (
		<BottomNavigation
			sx={{
				"& .MuiBottomNavigationAction-label": {
					color: (theme) => theme.palette.text.primary,
					fontSize: 9,
					fontWeight: 400,
					opacity: 0.8,
				},
				"& .MuiButtonBase-root": {
					color: (theme) => theme.palette.text.primary,
				},
				"& .Mui-selected": {
					color: (theme) => theme.palette.text.primary,
					fontSize: 12,
					fontWeight: "bold",
					opacity: 1,
					"& .MuiSvgIcon-root": {
						color: (theme) => theme.palette.text.primary,
					},
					"& .MuiBottomNavigationAction-label": {
						width: "max-content",
					},
				},
				borderBottomLeftRadius: (theme) => {
					return !props.isMobile ? theme.shape.borderRadius : 0;
				},
				borderBottomRightRadius: (theme) => {
					return !props.isMobile ? theme.shape.borderRadius : 0;
				},
				...style,
			}}
			showLabels
			value={activeTabs}
			onChange={(event, newValue) => {
				if (newValue === "logout") {
					navigate("/");
					setActiveTabs("/");
					authContext.logout();
				}
				setActiveTabs(newValue);
			}}
		>
			{menu.map((e) => {
				return <BottomNavigationAction key={e.key} label={e.label} value={e.key} icon={e.icon()} sx={{ minWidth: 0 }} />;
			})}
		</BottomNavigation>
	);
};

export default BottomNavBar;
