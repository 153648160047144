import { log } from "./service/clientlog";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { ScopedCssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { APIContext, CreateAuthContext } from "./context/apicontext";
import * as LoyaltyTheme from "./utils/themeCreator";
import * as Routing from "./routing/router";
import { CreateThemeContext, ThemeContext } from "./context/themecontext";
import { LanguageContext, LanguageContextDefaults } from "./context/languagecontext";
import { CreateKeyedStorage } from "./utils/keyedStorage";
import { Api as MemberApiSwagger } from "./apiclient/MemberAPI";
import { Api as VippsApiSwagger } from "./apiclient/VippsAPI";
import { IntegrationSettings, IntegrationSettingsContext, IntegrationSettingsDefaults } from "./context/integrationsettingscontext";
import { ContainerThemeSettingsByConfig, ThemeSettingsContext } from "./context/themesettingscontext";
import { CreateUserContext, UserContext } from "./context/usercontext";
import { Cart, Product } from "./types/type";
import { Constants } from "./constants/constants";
import "./assets/styles/reset.css";
import { useEffect, useState } from "react";
import { LoyallApiService } from "./service/ApiService";
import { parseJWT, getTokenByMRC } from "./service/jwt";
import { WidgetFunctions } from "./types/interface";
import { AuthState, WidgetMode, WidgetSize, ConsoleType, AuthenticationTypes, ThemeCodes, LanguageCodes } from "./types/enum";

interface Props {
	IntegrationSettings: IntegrationSettings;
	WidgetFunctions: (widgetFunctions: WidgetFunctions) => boolean;
}

function App(props: Props) {
	const is = props.IntegrationSettings ?? IntegrationSettingsDefaults;
	const storage = CreateKeyedStorage(is.storageId || is.container || "default");
	const themeContext = CreateThemeContext(storage, ThemeCodes[is.defaultTheme] || "dark");
	const widgetSettings = ContainerThemeSettingsByConfig(is.containerThemeSettings);
	const languageContext = LanguageContextDefaults(storage, LanguageCodes[is.defaultLanguage]);
	var theme = LoyaltyTheme.Get(themeContext.currentTheme as keyof typeof ThemeCodes, is.palette);

	const terminal = new URLSearchParams(window.location.search).get(Constants.TerminalSearchParam);
	if (terminal) {
		let loc = window.location.search;
		const indexes = [];
		for (let index = 0; index < loc.length; index++) {
			if (loc[index] === "?") {
				indexes.push(index);
			}
		}
		if (indexes.length > 1) {
			indexes.forEach((elementIndex) => {
				if (elementIndex > 0) {
					loc = loc.substring(0, elementIndex) + "&" + loc.substring(elementIndex + 1);
				}
			});
			window.history.pushState(null, document.title, new URL(window.location.origin + window.location.pathname + loc).href);
		}
	}

	const terminalGUID = { "X-Merchant-Terminal-Guid": terminal && terminal.length === 36 ? terminal : is.terminalGUID ?? "" };
	const requestHeader = terminalGUID["X-Merchant-Terminal-Guid"].length === 36 ? { headers: terminalGUID } : undefined;
	const memberAPIClass = new MemberApiSwagger<string>({
		baseUrl: process.env.REACT_APP_MEMBERAPI ?? "https://memberapi.loyalty.loyall.no",
		securityWorker: (accessToken) => (accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {}),
		baseApiParams: { ...(requestHeader || {}) },
	});
	const vippsAPIClass = new VippsApiSwagger<string>({
		baseUrl: process.env.REACT_APP_MEMBERAPI ?? "https://memberapi.loyalty.loyall.no",
		securityWorker: (accessToken) => (accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {}),
		baseApiParams: { ...(requestHeader || {}) },
	});
	const [programId, setProgramId] = useState(is.programId);
	// Authentication via token
	useEffect(() => {
		is.onWidgetInitialize(widgetFunctions);
		log("While rendering (0 second timeout)", ConsoleType.Trace, typeof is.onWidgetInitialize);
		const init = props.WidgetFunctions(widgetFunctions);
		log("WidgetFunctions Resolved:", ConsoleType.Trace, init, is.programId);

		const search = window.location.search;
		const hasToken = search.includes(Constants.Token);
		const hasMRC = search.includes(Constants.MRC);
		if (!hasToken && !hasMRC) return;
		const out = (reason: string | undefined = "") => {
			authContext.logout();
			if (reason) {
				log("TOKEN Sign out", ConsoleType.Info, reason);
			}
			return;
		};
		authContext.authState() === AuthState.AuthorizedByToken && (!hasToken || !hasMRC) && out("AuthorizedByToken but there is no token and MRC.");

		const params = new URLSearchParams(search);
		let token = params.get(Constants.Token);
		const MRC = params.get(Constants.MRC);
		!token && !MRC && out("Empty token and MRC.");

		const authenticateAsync = async () => {
			if (!token && MRC) {
				token = await getTokenByMRC(authContext, MRC);
			}
			!token && out(`Object:${token},${MRC}}`);
			const response = parseJWT(token ?? "");
			!response && out(`Empty response: ${response}`);
			token && (await authContext.loginValidate({ Type: AuthenticationTypes.JWT, Token: token }));
			log("authenticateViaJWT result", ConsoleType.Trace, response?.ProgramId);
			setProgramId(response?.ProgramId ?? "");
			params.delete(Constants.Token);
		};
		authenticateAsync()
			.catch((e) => log("JWT authenticateAsync:", ConsoleType.Error, e))
			.finally(() => {
				window.history.pushState({}, document.title, "/");
			});
	}, []);

	const memberApiService = LoyallApiService(memberAPIClass, vippsAPIClass);

	const authContext = CreateAuthContext(storage, memberApiService, Number(programId)); //MyProgramId(programId, is.customer)
	const userContext = CreateUserContext(storage, is);

	const widgetFunctions: WidgetFunctions = {
		ChangeSize: (widgetSize: WidgetSize) => {
			if (!Object.values(WidgetSize).includes(widgetSize)) {
				log("Use: " + Object.values(WidgetSize), ConsoleType.Warning, widgetSize);
				return widgetSize;
			}
			userContext.changeWidgetSize(widgetSize);
			return widgetSize;
		},
		Close: () => {
			userContext.toggleWidgetMode(WidgetMode.Open);
		},
		Open: () => {
			userContext.toggleWidgetMode(WidgetMode.Close);
		},
		CartTotalPrice: (cartTotalAmount: number) => {
			console.log("CartTotalPrice (do something here)", cartTotalAmount);
		},
		Logout: () => {
			authContext.logout();
			return true;
		},
		InitializeLogin: (phoneNumber: string) => {
			authContext.loginStart(phoneNumber);
		},
		SetLanguage: (languageCode: string) => {
			languageContext.setLanguage(languageCode);
		},
		SetCart: (cart: Cart) => {
			console.log("SetCart (do something here)", cart);
		},
		SetProduct: (product: Product) => {
			if (!(product as Product)) {
				log("Use Sample: ", ConsoleType.Warning, is.product);
				return false;
			}
			if (
				typeof product.Name === "undefined" ||
				typeof product.Discount === "undefined" ||
				typeof product.DiscountPercentage === "undefined" ||
				typeof product.IsInSale === "undefined" ||
				typeof product.Price === "undefined"
			) {
				log("Sample: ", ConsoleType.Warning, product.Name, product.Discount, product.DiscountPercentage, product.IsInSale, product.Price, is.product);
				return false;
			}

			setProduct(product);
			return true;
		},
		UpdateCards: (loadAPI: boolean) => {
			const result = authContext.cardInfo({ loadAPI: loadAPI });
			log("UpdateCards result:", ConsoleType.Info, result);
		},
		UpdateBenefits: async (loadAPI: boolean) => {
			let result = false;
			try {
				const voucher = await authContext.voucherInfo({ loadAPI: loadAPI });
				const bonus = await authContext.bonusInfo({ loadAPI: loadAPI });
				result = true;
				log("UpdateBenefits Called: ", ConsoleType.Info, voucher, bonus);
			} catch (error) {
				log("UpdateBenefits Called: ", ConsoleType.Warning, error);
			}
			return result;
		},
		UpdateConsents: async (loadAPI: boolean) => {
			const result = await authContext.consentInfo({ loadAPI: loadAPI });
			log("UpdateConsent result:", ConsoleType.Info, result);
			return Promise.resolve(true);
		},
	};

	const [product, setProduct] = useState(props.IntegrationSettings.product);

	// setTimeout(() => {
	// 	is.onWidgetInitialize(widgetFunctions);
	// 	log("While rendering (0 second timeout)", ConsoleType.Trace, typeof is.onWidgetInitialize);
	// 	const init = props.WidgetFunctions(widgetFunctions);
	// 	log("WidgetFunctions Resolved:", ConsoleType.Trace, init);
	// }, 0);

	return (
		<ThemeSettingsContext.Provider value={widgetSettings}>
			<IntegrationSettingsContext.Provider value={is}>
				<LanguageContext.Provider value={languageContext}>
					<ThemeContext.Provider value={themeContext}>
						<ThemeProvider theme={theme}>
							<ScopedCssBaseline>
								<I18nProvider i18n={i18n}>
									<UserContext.Provider value={userContext}>
										{userContext.widgetModeState() !== WidgetMode.Close && userContext.widgetSizeState() !== WidgetSize.Minimize && (
											<APIContext.Provider value={authContext}>
												{authContext.authState() === AuthState.Authorized ? (
													<Routing.WidgetRouter />
												) : authContext.authState() !== AuthState.AuthorizedByToken ? (
													<Routing.LoginRouter />
												) : (
													<Routing.WelcomeRouter />
												)}
											</APIContext.Provider>
										)}
										{userContext.widgetModeState() !== WidgetMode.Close && userContext.widgetSizeState() === WidgetSize.Minimize && (
											<APIContext.Provider value={authContext}>{<Routing.MinimizeRouter product={product} />}</APIContext.Provider>
										)}
										{userContext.widgetModeState() === WidgetMode.Close && (
											<APIContext.Provider value={authContext}>
												{<Routing.CloseRouter AssetsURL={window.localStorage.getItem(Constants.Assets_URL)} programID={programId} />}
											</APIContext.Provider>
										)}
									</UserContext.Provider>
								</I18nProvider>
							</ScopedCssBaseline>
						</ThemeProvider>
					</ThemeContext.Provider>
				</LanguageContext.Provider>
			</IntegrationSettingsContext.Provider>
		</ThemeSettingsContext.Provider>
	);
}

export default App;
