import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useContext, useState } from "react";
import { APIContext } from "../context/apicontext";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormControl,
	InputLabel,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Stack,
	styled,
} from "@mui/material";
import { ThemeSettingsContext } from "../context/themesettingscontext";
import { UserContext } from "../context/usercontext";
import { HearderUI } from "../components/header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TransactionDto } from "../apiclient/MemberAPI";
import { GetYears } from "../service/receipt";
import { FixedSizes } from "../constants/constants";
import { WidgetSize } from "../types/enum";
import { t, Trans } from "@lingui/macro";
import { ReceiptDialog } from "../components/receipt";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";

function GetReceipts(): TransactionDto[] {
	const authContext = useContext(APIContext);
	const receipts = authContext.receiptInfo();
	return receipts;
}

function FilterTransactions(year: number, receipts: TransactionDto[]): TransactionDto[] {
	var receiptsOfSelectedYear = receipts.filter(function (y) {
		let tempDate = y.purchaseTime;
		if (!tempDate) tempDate = y.createdAt;
		if (!tempDate) return false;
		const receiptDate = new Date(tempDate);
		return receiptDate.getFullYear() === year ?? [];
	});
	return receiptsOfSelectedYear;
}

const Lines = styled(Box)(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.secondary.main,
}));

function PurchasesFull() {
	const receipts = GetReceipts();
	let transactionDate: string[] = receipts.map((x) => x.purchaseTime ?? x.createdAt ?? "");
	const years = GetYears(transactionDate).reverse();
	const [year, setYear] = useState(years[0]);
	const handleChange = (event: SelectChangeEvent) => {
		setYear(Number(event.target.value as string));
	};
	const IntegrationSetting = useContext(IntegrationSettingsContext);

	if (!year && years.length > 0) setYear(years[0]);
	const transactions = (years && FilterTransactions(year, receipts)) ?? [];

	const [open, setOpen] = useState(false);
	const [transaction, SetTransaction] = useState(receipts[0]);
	const handleClose = async (newValue?: string) => {
		if (newValue === "Printed") {
			setOpen(false);
			return;
		}
		setOpen(false);
	};

	return (
		<Card sx={{ borderRadius: "unset", overflow: "none" }}>
			<CardContent sx={{ padding: 0 }}>
				<HearderUI />
			</CardContent>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					height: window.innerHeight - FixedSizes.TOPBAR - FixedSizes.HEADER - FixedSizes.NAVBAR,
					overflow: "scroll",
				}}
			>
				{year !== undefined && (
					<Box sx={{ maxWidth: "239px", minWidth: "239px", mt: 7 }}>
						<FormControl fullWidth>
							<InputLabel
								variant="standard"
								id="transactionYears"
								sx={{
									color: (theme) => theme.palette.secondary.main,
									"&.Mui-focused": {
										color: (theme) => theme.palette.secondary.main,
									},
								}}
							>
								<Trans>Purchase year</Trans>
							</InputLabel>
							<Select
								sx={{
									color: (theme) => theme.palette.secondary.main,
								}}
								variant="standard"
								labelId="transactionYears"
								id="transactionYearsSelect"
								value={year?.toString()}
								onChange={handleChange}
							>
								{years.map((element) => (
									<MenuItem value={element}>{element}</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				)}
				{year === undefined && (
					<Box sx={{ maxWidth: "239px", minWidth: "239px", mt: 7, textAlign: "center" }}>
						<Lines>{t`you have no purchases`}</Lines>
					</Box>
				)}
				<Box sx={{ ml: "0 !important", mt: 3, minWidth: "75%", maxWidth: "75%" }}>
					{transactions.map((element, index) => {
						const receiptDate = new Date((element.purchaseTime ?? element.createdAt)!);
						let title = t`View Receipt`;
						switch (element.department?.departmentBudgetSegment) {
							case "Hotel":
								title = t`View Reservations`;
								break;
							default:
								break;
						}
						return (
							<>
								{IntegrationSetting.enableDownloadReceipt && (
									<Stack
										sx={{ justifyContent: "space-between", p: 1, boxShadow: "0 1px 1px rgb(0 0 0 / 0.2)", mb: 1 }}
										direction="row"
										id={"Stack" + index.toString()}
									>
										<Stack spacing={1} sx={{ justifyContent: "space-between" }} direction="column" id={"Stack" + index.toString()}>
											<Lines sx={{ fontSize: 12 }}>
												{receiptDate.toLocaleString("en-US", {
													month: "short",
													day: "numeric",
												})}
											</Lines>
											<Lines sx={{ fontSize: 12 }}>{element.paidAmount?.amount?.toString() + (element.paidAmount?.currencyCode ?? ",-")}</Lines>
										</Stack>
										<Link
											id="viewReceipt"
											name="viewReceipt"
											textTransform={"unset"}
											href="#"
											underline="hover"
											variant="overline"
											component="button"
											onClick={() => {
												setOpen(true);
												SetTransaction(element);
											}}
											color="text.primary"
											sx={{
												color: (theme) => theme.palette.secondary.main,
												fontSize: (theme) => theme.typography.subtitle1.fontSize,
												fontWeight: "bold",
											}}
										>
											{title}
										</Link>
									</Stack>
								)}
								{!IntegrationSetting.enableDownloadReceipt && (
									<Accordion
										sx={{
											mb: 1,
											bgcolor: (theme) => theme.palette.background.default,
											minHeight: "40px",
											maxWidth: "100%",
										}}
										square
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon sx={{ color: (theme) => theme.palette.secondary.light }} />}
											aria-controls={`panel${index}a-content`}
											id={`panel${index}a-header`}
											sx={{
												minHeight: "20px",
												backgroundColor: (theme) => theme.palette.background.default,
												"& .MuiAccordionSummary-content": {
													marginTop: 0,
													marginBottom: 0,
													flexDirection: "column",
												},
												pt: 1.2,
											}}
										>
											<Stack sx={{ justifyContent: "space-between" }} direction="row" id={"Stack" + index.toString()}>
												<Lines sx={{ fontSize: 12 }}>
													{receiptDate.toLocaleString("en-US", {
														month: "short",
														day: "numeric",
													})}
												</Lines>
												<Lines sx={{ fontSize: 12 }}>{element.paidAmount?.amount?.toString() + (element.paidAmount?.currencyCode ?? ",-")}</Lines>
											</Stack>
										</AccordionSummary>
										<AccordionDetails
											id={"AccordionDetails" + index.toString()}
											sx={{
												"& .MuiTypography-root": {
													textAlign: "center",
													width: "100%",
												},
											}}
										>
											{element.transactionLines &&
												element.transactionLines.map((x, lineItem) => {
													return (
														<Stack direction="row" justifyContent="space-between" spacing={"10px"}>
															<Lines id={"name_" + index.toString() + "tIndex_" + lineItem.toString()} sx={{ fontSize: 12 }}>
																{x.product?.displayName?.toString() ?? "unknown"}
															</Lines>
															<Lines id={"amount_" + index.toString() + "tIndex_" + lineItem.toString()} sx={{ fontSize: 12 }}>
																{x.lineSumWithTaxes?.toString() ?? "-"}
															</Lines>
														</Stack>
													);
												})}
										</AccordionDetails>
									</Accordion>
								)}
							</>
						);
					})}
				</Box>
				{IntegrationSetting.enableDownloadReceipt && <ReceiptDialog id="loyall-receipt-box" transaction={transaction} open={open} onClose={handleClose} />}
			</CardActions>
		</Card>
	);
}

function PurchasesMain() {
	const receipts = GetReceipts();
	let transactionDate: string[] = receipts.map((x) => x.purchaseTime ?? x.createdAt ?? "");
	const years = GetYears(transactionDate).reverse();
	const [year, setYear] = useState(years[0]);
	const handleChange = (event: SelectChangeEvent) => {
		setYear(Number(event.target.value as string));
	};
	const IntegrationSetting = useContext(IntegrationSettingsContext);

	if (!year && years.length > 0) setYear(years[0]);
	const transactions = (years && FilterTransactions(year, receipts)) ?? [];

	let layout = Layouting();
	let minNavigationHight = 0;
	if (layout.fs) {
		delete layout["height"];
		delete layout["overflow"];

		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		minNavigationHight = 100 * (182 / vh);
	}

	const [open, setOpen] = useState(false);
	const [transaction, SetTransaction] = useState(receipts[0]);
	const handleClose = async (newValue?: string) => {
		if (newValue === "Printed") {
			setOpen(false);
			return;
		}
		setOpen(false);
	};

	return (
		<Card sx={layout}>
			<CardContent sx={{ padding: 0 }}>
				<HearderUI />
			</CardContent>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					maxHeight: "246px",
					minHeight: () => {
						if (layout.fs) return `calc(100vh - ${minNavigationHight}vh)`;
						return "246px";
					},
					overflow: "auto",
				}}
			>
				{year !== undefined && (
					<Box sx={{ maxWidth: "239px", minWidth: "239px", mt: 7 }}>
						<FormControl fullWidth>
							<InputLabel
								variant="standard"
								id="transactionYears"
								sx={{
									color: (theme) => theme.palette.secondary.main,
									"&.Mui-focused": {
										color: (theme) => theme.palette.secondary.main,
									},
								}}
							>
								<Trans>Purchase year</Trans>
							</InputLabel>
							<Select
								sx={{
									color: (theme) => theme.palette.secondary.main,
								}}
								variant="standard"
								labelId="transactionYears"
								id="transactionYearsSelect"
								value={year.toString()}
								onChange={handleChange}
							>
								{years.map((element) => (
									<MenuItem value={element}>{element}</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				)}
				{year === undefined && (
					<Box sx={{ maxWidth: "239px", minWidth: "239px", mt: 7, textAlign: "center" }}>
						<Lines>{t`you have no purchases`}</Lines>
					</Box>
				)}
				<Box sx={{ ml: "0 !important", mt: 3, maxWidth: "275px", minWidth: "275px" }}>
					{transactions.map((element, index) => {
						const receiptDate = new Date((element.purchaseTime ?? element.createdAt)!);
						let title = t`View Receipt`;
						switch (element.department?.departmentBudgetSegment) {
							case "Hotel":
								title = t`View Reservations`;
								break;
							default:
								break;
						}
						return (
							<>
								{IntegrationSetting.enableDownloadReceipt && (
									<Stack
										sx={{ justifyContent: "space-between", p: 1, boxShadow: "0 1px 1px rgb(0 0 0 / 0.2)", mb: 1 }}
										direction="row"
										id={"Stack" + index.toString()}
									>
										<Stack spacing={1} sx={{ justifyContent: "space-between" }} direction="column" id={"Stack" + index.toString()}>
											<Lines sx={{ fontSize: 12 }}>
												{receiptDate.toLocaleString("en-US", {
													month: "short",
													day: "numeric",
												})}
											</Lines>
											<Lines sx={{ fontSize: 12 }}>{element.paidAmount?.amount?.toString() + (element.paidAmount?.currencyCode ?? ",-")}</Lines>
										</Stack>
										<Link
											id="viewReceipt"
											name="viewReceipt"
											textTransform={"unset"}
											href="#"
											underline="hover"
											variant="overline"
											component="button"
											onClick={() => {
												setOpen(true);
												SetTransaction(element);
											}}
											color="text.primary"
											sx={{
												color: (theme) => theme.palette.secondary.main,
												fontSize: (theme) => theme.typography.subtitle1.fontSize,
												fontWeight: "bold",
											}}
										>
											{title}
										</Link>
									</Stack>
								)}
								{!IntegrationSetting.enableDownloadReceipt && (
									<Accordion
										sx={{
											mb: 1,
											"& .MuiAccordionSummary-root.Mui-expanded": {
												minHeight: "20px",
												maxHeight: "20px",
											},
											bgcolor: (theme) => theme.palette.background.default,
											minHeight: "40px",
										}}
										square
										elevation={1}
										id={"Accordion" + index.toString()}
									>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon sx={{ color: (theme) => theme.palette.secondary.light }} />}
											aria-controls={`panel${index}a-content`}
											id={"AccordionSummary" + index.toString()}
											sx={{
												minHeight: "20px",
												paddingRight: "2px",
												paddingLeft: "16px",
												backgroundColor: (theme) => theme.palette.background.default,
												"& .MuiAccordionSummary-content": {
													marginTop: 0,
													marginBottom: 0,
													flexDirection: "column",
												},
												pt: 1.2,
											}}
										>
											<Stack sx={{ justifyContent: "space-between" }} direction="row" id={"Stack" + index.toString()}>
												<Lines sx={{ fontSize: 12 }}>
													{receiptDate.toLocaleString("en-US", {
														month: "short",
														day: "numeric",
													})}
												</Lines>
												<Lines sx={{ fontSize: 12 }}>{element.paidAmount?.amount?.toString() + (element.paidAmount?.currencyCode ?? ",-")}</Lines>
											</Stack>
										</AccordionSummary>
										<AccordionDetails
											id={"AccordionDetails" + index.toString()}
											sx={{
												"& .MuiTypography-root": {
													textAlign: "center",
													width: "100%",
												},
											}}
										>
											{element.transactionLines &&
												element.transactionLines.map((x, lineItem) => {
													return (
														<Stack direction="row" justifyContent="space-between" spacing={"10px"}>
															<Lines id={"name_" + index.toString() + "tIndex_" + lineItem.toString()} sx={{ fontSize: 12 }}>
																{x.product?.displayName?.toString() ?? "unknown"}
															</Lines>
															<Lines id={"amount_" + index.toString() + "tIndex_" + lineItem.toString()} sx={{ fontSize: 12 }}>
																{x.lineSumWithTaxes?.toString() ?? "-"}
															</Lines>
														</Stack>
													);
												})}
											{IntegrationSetting.enableDownloadReceipt && (
												<Link
													id="viewReceipt"
													name="viewReceipt"
													textTransform={"unset"}
													href="#"
													underline="hover"
													variant="overline"
													component="button"
													onClick={() => {
														setOpen(true);
														SetTransaction(element);
													}}
													color="text.primary"
													sx={{
														color: (theme) => theme.palette.secondary.main,
														fontSize: (theme) => theme.typography.subtitle1.fontSize,
														fontWeight: "bold",
														mb: -1,
													}}
												>
													{title}
												</Link>
											)}
										</AccordionDetails>
									</Accordion>
								)}
							</>
						);
					})}
				</Box>
				{IntegrationSetting.enableDownloadReceipt && <ReceiptDialog id="loyall-receipt-box" transaction={transaction} open={open} onClose={handleClose} />}
			</CardActions>
		</Card>
	);
}

function PurchasesSmall() {
	const receipts = GetReceipts();
	let transactionDate: string[] = receipts.map((x) => x.purchaseTime ?? x.createdAt ?? "");
	const years = GetYears(transactionDate).reverse();
	const [year, setYear] = useState(years[0]);
	const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: number) => {
		setYear(item);
	};
	const transactions = (years && FilterTransactions(year, receipts)) ?? [];

	return (
		<Card sx={Layouting()}>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					minHeight: "180px",
					maxHeight: "180px",
					overflow: "auto",
				}}
			>
				<Box sx={{ mt: 3 }}>
					<List sx={{ display: "flex" }}>
						{years.map((element) => (
							<ListItem disablePadding key={element}>
								<ListItemButton
									selected={element === year}
									onClick={(event) => handleListItemClick(event, element)}
									sx={{
										color: (theme) => theme.palette.secondary.main,
										textDecoration: element === year ? "underline" : "none",
									}}
								>
									<ListItemText primary={element} />
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Box>
				<Box sx={{ mt: 3, minWidth: "75%", maxWidth: "75%" }}>
					{transactions.map((element, index) => {
						const receiptDate = new Date((element.purchaseTime ?? element.createdAt)!);
						return (
							<Accordion
								sx={{
									mb: 1,
									bgcolor: (theme) => theme.palette.background.default,
									minHeight: "40px",
									maxWidth: "235px",
								}}
								square
							>
								<AccordionSummary
									// expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel${index}a-content`}
									id={`panel${index}a-header`}
									sx={{
										minHeight: "20px",
										backgroundColor: (theme) => theme.palette.background.default,
										"& .MuiAccordionSummary-content": {
											marginTop: 0,
											marginBottom: 0,
											flexDirection: "column",
										},
										pt: 1.2,
									}}
								>
									<Stack direction="row" justifyContent="space-between" spacing={9}>
										<Lines sx={{ fontSize: 12 }}>
											{receiptDate.toLocaleString("en-US", {
												month: "short",
												day: "numeric",
											})}
										</Lines>
										<Lines sx={{ fontSize: 12 }}>{element.paidAmount?.amount?.toString() + (element.paidAmount?.currencyCode ?? ",-")}</Lines>
									</Stack>
								</AccordionSummary>
							</Accordion>
						);
					})}
				</Box>
			</CardActions>
		</Card>
	);
}

export function PurchasesUI() {
	const userContext = useContext(UserContext);
	switch (userContext.widgetSizeState()) {
		case WidgetSize.Main:
			return <PurchasesMain />;
		case WidgetSize.Small:
			return <PurchasesSmall />;
		case WidgetSize.Full:
			return <PurchasesFull />;
		default:
			return <PurchasesMain />;
	}
}

function Layouting() {
	const widgetSetting = useContext(ThemeSettingsContext);
	let state = null;
	if (widgetSetting.overflow) {
		state = {
			height: "fit-content",
			overflow: "auto",
			boxShadow: "0",
		};
	}
	state = {
		...state,
		borderRadius: "0",
		fs: widgetSetting.floatInMobile,
	};
	return state;
}
