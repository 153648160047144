export function ContainerSX() {
	// Center text, ensure that we have a border & max width
	const containerSx = {
		// sample only
		textAlign: "center",
	};

	return containerSx;
}

export function ContainerStyle() {
	// Remove unnecessary padding that container adds
	const containerStyle = {
		paddingLeft: 0,
		paddingRight: 0,
	};

	return containerStyle;
}

export function hexToRGBA(hex: string, alpha: string) {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	if (alpha) {
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	}
	return `rgb(${r}, ${g}, ${b})`;
}
