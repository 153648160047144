export class Constants {
	public static readonly STORAGE_COUNTRY_CODE = "lastPhoneCountry";
	public static readonly STOREBOX_HASH = "cardstorebox";
	public static readonly STOREBOX_URL = "https://cardapi.prod.storebox.com/api/v1/cards/cardframe";
	public static readonly SSO_PARAM = "code";
	public static readonly SSO_TYPE = "type";
	public static readonly Assets_URL = "AssetsURL";
	public static readonly Token = "token"; // visbook case
	public static readonly MRC = "mrc"; // member registration contact
	public static readonly TerminalSearchParam = "t"; // terminal guid
	public static readonly EmailSearchParam = "email"; // email
	public static readonly ProgramTagSession = "LoyaltyProgramTag"; // tag set in session via homeHandler.js
	public static readonly StoreboxSearchParam = "storebox"; // redirect controller after submitting card
	public static readonly StoreboxHashSearchParam = "hash"; // redirect controller after submitting card
	public static readonly MWCGuid = "widgetConfigurationGuid"; // local storage via homehandler.js
	public static readonly GuidEmpty = "00000000-0000-0000-0000-000000000000";
	public static readonly StoreboxRedirect = "redirected";
	public static readonly SystemLanguages = ["en", "no", "sv", "dk", "de", "el"];
	public static readonly LoyaltyInviteSession = "page";
}

export class FixedSizes {
	public static readonly TOPBAR = 31;
	public static readonly HEADER = 77;
	public static readonly NAVBAR = 56;
}

export const textEllipsis = {
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	overflow: "hidden",
};
