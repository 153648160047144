import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	Stack,
	styled,
	Theme,
	Typography,
} from "@mui/material";
import { HearderUI } from "../components/header";
import { FixedSizes } from "../constants/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserContext } from "../context/usercontext";
import { WidgetSize } from "../types/enum";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeSettingsContext } from "../context/themesettingscontext";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { CustomTextbox } from "../components/textbox";
import { t } from "@lingui/macro";
import { FaqDto, SupportTicketDto, TicketReplyRequestDto, TicketStatus } from "../apiclient/MemberAPI";
import { APIContext } from "../context/apicontext";
import { TextFieldProps } from "@mui/material";
import CircularStatic from "../components/progress";
import { groupBy } from "../service/helper";
import { LanguageContext } from "../context/languagecontext";

const Lines = styled(Box)(({ theme }) => ({
	...theme.typography.body2,
	color: theme.palette.secondary.main,
}));

function Layouting() {
	const widgetSetting = useContext(ThemeSettingsContext);
	let state = null;
	if (widgetSetting.overflow) {
		state = {
			height: "fit-content",
			overflow: "auto",
			boxShadow: "0",
		};
	}
	state = {
		...state,
		borderRadius: "0",
		fs: widgetSetting.floatInMobile,
	};
	return state;
}

function ReplyContainer(props: any) {
	const ticket: SupportTicketDto = props.ticket;
	const ticketDate = new Date(ticket.createdAt!);
	const item = ticket.ticketId!.toString();
	const TicketReplyContentRef = useRef<TextFieldProps>(null);
	const [replyBox, setReplyBox] = useState(false);
	const [replyBoxMessage, setReplyBoxMessage] = useState("");
	const [processingAll, setProcessingAll] = useState<boolean>(false);
	const [animation, setAnimation] = useState<boolean>(false);
	const [animationColor, setAnimationColor] = useState("");
	useEffect(() => {
		if (animation) {
			setTimeout(() => {
				setAnimation(false);
			}, 2000);
		}
	}, [animation]);
	const TicketResponse = (ticketStatus: TicketStatus) => {
		switch (ticketStatus) {
			case TicketStatus.WaitingForMember:
				if (replyBox) setReplyBox(false);
				if (!replyBox) setReplyBox(true);
				break;
			case TicketStatus.WaitingForSupport:
				if (replyBoxMessage) setReplyBoxMessage("");
				if (!replyBoxMessage) setReplyBoxMessage(t`Your response has been submitted and will be answered as soon as possible.`);
				break;
			default:
				break;
		}
	};
	const ticketColor = (theme: Theme, ticketStatus: TicketStatus) => {
		let color = "";
		switch (ticketStatus) {
			case TicketStatus.Solved:
				color = theme.palette.success.dark;
				break;
			case TicketStatus.WaitingForSupport:
				color = theme.palette.success.light;
				break;
			case TicketStatus.WaitingForMember:
				color = theme.palette.warning.dark;
				break;
			case TicketStatus.Hidden:
				color = theme.palette.secondary.main;
				break;
		}
		return color;
	};
	const ReplyHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const checkEmptyWhitespace = (TicketReplyContentRef.current?.value as string).replace(/\s/g, "").length;
		if (!(TicketReplyContentRef.current && TicketReplyContentRef.current.value) || !checkEmptyWhitespace) {
			TicketReplyContentRef.current!.value = "";
			return;
		}
		try {
			setProcessingAll(true);
			const reply: TicketReplyRequestDto = {
				ticketId: Number((TicketReplyContentRef.current?.id as string).split("_")[1]),
				content: TicketReplyContentRef.current?.value as string,
				ticketAttachments: null,
			};
			const problem = await props.memberContext.replyTicket(reply);
			await props.memberContext.ticketsInfo({ loadAPI: true });
			TicketReplyContentRef.current!.value = "";
			props.setTickets(null);
			setAnimation(true);
			if (problem) {
				setAnimationColor("darkred");
				return;
			}
			setAnimationColor("darkgreen");
			setProcessingAll(false);
		} catch (error) {
			setAnimationColor("darkred");
			setAnimation(false);
			setProcessingAll(false);
		}
	};
	return (
		<Box sx={{ mb: 1 }} id={item}>
			<Accordion
				sx={{
					"& .MuiAccordionSummary-root.Mui-expanded": {
						minHeight: "20px",
						maxHeight: "20px",
					},
					bgcolor: (theme) => theme.palette.background.default,
					minHeight: "40px",
				}}
				square
				elevation={2}
				id={`accordion_support_ticket_${item}`}
				expanded={props.expanded === `panel${item}`}
				onChange={props.handleChange(`panel${item}`)}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: (theme) => theme.palette.secondary.light }} />}
					aria-controls={`panel_${item}_content`}
					id={`accordion_summary_${item}`}
					sx={{
						minHeight: "20px",
						paddingRight: "2px",
						paddingLeft: "16px",
						backgroundColor: (theme) => theme.palette.background.default,
						"& .MuiAccordionSummary-content": {
							marginTop: 0,
							marginBottom: 0,
							flexDirection: "column",
						},
						pt: 1.2,
					}}
				>
					<Stack sx={{ justifyContent: "space-between", pt: "1px" }} direction="row" id={`support_stack_${item}`}>
						<Lines id={`support_stack_line1_${item}`} sx={{ fontSize: 12, color: (theme) => ticketColor(theme, ticket.ticketStatus!) }}>
							{t`Ticket ID` + `: #` + ticket.ticketId}
						</Lines>
						<Lines id={`support_stack_line2_${item}`} sx={{ fontSize: 12 }}>
							{ticketDate.toLocaleString("en-US", {
								month: "short",
								day: "numeric",
							})}
						</Lines>
					</Stack>
				</AccordionSummary>
				<AccordionDetails id={`support_accordion_details_${item}`}>
					<Stack direction="column" justifyContent="space-between" spacing={"10px"}>
						<Lines id={`ticket_content_text_${item}`} sx={{ pt: 1, fontSize: 12, textAlign: "justify", textJustify: "inter-character" }}>
							{ticketDate.toLocaleString() + `:`}
							<br />
							{ticket.content}
						</Lines>
						{ticket.ticketReplys &&
							ticket.ticketReplys.map((replied, repliedItem) => {
								const repliedDate = new Date(replied.createdAt!);
								return (
									<Lines
										id={`ticket_reply_text_${item}_${repliedItem}`}
										sx={{ fontSize: 12, padding: 1, textAlign: "justify", textJustify: "inter-character" }}
									>
										<hr />
										{repliedDate.toLocaleString() + `:`}
										<br />
										{replied.replyContent}
									</Lines>
								);
							})}
						<Lines>
							<Button
								id={`support_stack_status_btn_${item}`}
								sx={{
									minWidth: "90px",
									color: (theme) => ticketColor(theme, ticket.ticketStatus!),
									border: (theme) => `1px solid ${ticketColor(theme, ticket.ticketStatus!)}`,
									height: "30px",
								}}
								onClick={() => TicketResponse(ticket.ticketStatus!)}
								type="submit"
								variant="text"
								size="small"
								disabled={processingAll}
							>
								{ticket.ticketStatus === TicketStatus.WaitingForMember
									? t`Pending reply`
									: ticket.ticketStatus === TicketStatus.WaitingForSupport
									? t`Under progress`
									: ticket.ticketStatus === TicketStatus.Solved
									? t`Solved`
									: t`Archived`}
							</Button>
						</Lines>
						{replyBox && (
							<Lines sx={{ pt: 0 }}>
								<CustomTextbox
									name="reply"
									id={`reply_` + ticket.ticketId}
									variant="outlined"
									size="small"
									fullWidth
									autoFocus
									multiline
									rows={3}
									fontSize="11px"
									fontWeight="100"
									inputRef={TicketReplyContentRef}
									disabled={processingAll}
								/>
								<Button
									id={`support_stack_reply_btn_${item}`}
									sx={{
										bgcolor: (theme) => theme.palette.primary.dark,
										color: (theme) => theme.palette.text.secondary,
										border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
										marginTop: 1,
										height: "30px",
										"&.Mui-disabled": {
											color: (theme) => theme.palette.text.secondary,
											backgroundColor: (theme) => theme.palette.primary.light,
											border: "unset",
											opacity: 0.8,
										},
										minWidth: "90px",
									}}
									type="submit"
									variant="contained"
									size="small"
									disabled={processingAll}
									onClick={ReplyHandler}
								>
									{processingAll && <CircularStatic visible={processingAll} />}
									{!processingAll && t`Reply`}
								</Button>
								<Grid
									item
									xs={12}
									sx={{
										height: "2px",
										animationName: "blinker",
										animationDuration: "5000ms",
										animationTimingFunction: "linear",
										animationIterationCount: animation ? "infinite" : 0,
										"@-webkit-keyframes blinker": {
											from: { backgroundColor: animationColor },
											to: { backgroundColor: "white" },
										},
										"@keyframes blinker": {
											from: { backgroundColor: animationColor },
											to: { backgroundColor: "white" },
											// "0%": {
											// 	background: "#00ff44",
											// },
											// "20%": {
											// 	background: "#02d139",
										},
										textAlign: "left",
									}}
								>
									{animation && animationColor === "darkred" ? (
										<Typography sx={{ color: animationColor }}>error 😏</Typography>
									) : (
										animation && <Typography sx={{ color: animationColor }}>Congratulation 🎉</Typography>
									)}
								</Grid>
							</Lines>
						)}
						{!replyBox && replyBoxMessage && (
							<Lines id={`support_stack_message_${item}`}>
								<Typography id={`support_stack_msg_${item}`} variant="body2" sx={{ color: (theme) => theme.palette.warning.main }}>
									{replyBoxMessage}
								</Typography>
							</Lines>
						)}
					</Stack>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
}

function SupportFull(Props: any) {
	const languageCtx = useContext(LanguageContext);
	const memberContext = useContext(APIContext);
	const BoxRef = useRef<null | HTMLElement>(null);
	const TicketContentRef = useRef<TextFieldProps>(null);
	const [processingAll, setProcessingAll] = useState<boolean>(false);
	const [tickets, setTickets] = useState<SupportTicketDto[] | null>(null);
	const [faqItems, setFaqItems] = useState<Map<string, FaqDto[]>>(new Map<string, FaqDto[]>());

	const GetFAQ = async () => {
		const temp = await memberContext.faqInfo({ loadAPI: false });
		let systemLang = languageCtx.language.length > 2 ? languageCtx.language.substring(0, 2) : languageCtx.language;
		if (systemLang === "da") systemLang = "dk";
		const faqDic = new Map<string, FaqDto[]>();
		// FIXME
		try {
			const FAQs = temp.get(systemLang);
			var faqRecord: Record<string, FaqDto[]> | null = null;
			faqRecord = groupBy(FAQs, (p) => p.category);
			if (faqRecord)
				for (const key in faqRecord) {
					const value = faqRecord[key] as FaqDto[];
					faqDic.set(key, value);
				}
			setFaqItems(faqDic);
			return faqDic;
		} catch {
			for (let index = 0; index < temp.length; index++) {
				const lang = temp[index][0] as string;
				if (lang === systemLang) {
					const FAQs = temp[index][1] as FaqDto[];
					const faqRecord = groupBy(FAQs, (p) => p.category);
					if (faqRecord)
						for (const key in faqRecord) {
							const value = faqRecord[key] as FaqDto[];
							faqDic.set(key, value);
						}
					setFaqItems(faqDic);
					return faqDic;
				}
			}
		}
	};

	useEffect(() => {
		let isMounted = true;
		const updateAsync = async (api: boolean) => {
			const data = await memberContext.ticketsInfo({ loadAPI: api });
			if (!api) isMounted = false;
			const sortData = data.sort((a: SupportTicketDto, b: SupportTicketDto) => {
				const order = (a: number, b: number) => {
					if (a === b) return 0;
					if (a === null) return 1;
					if (b === null) return -1;
					return a > b ? 1 : -1;
				};
				return order(Number(b.ticketId), Number(a.ticketId));
			});
			setTickets(sortData);
		};
		tickets === null && isMounted && updateAsync(false);
		return () => {
			isMounted && updateAsync(true);
			isMounted = false;
		};
	}, [tickets === null]);
	const [showNewTicketBox, setShowNewTicketBox] = useState(true);
	const [animation, setAnimation] = useState<boolean>(false);
	const [animationColor, setAnimationColor] = useState("");

	useEffect(() => {
		if (animation) {
			setTimeout(() => {
				setAnimation(false);
			}, 2000);
		}
	}, [animation]);

	useEffect(() => {
		const updateAsync = async () => {
			await GetFAQ();
		};
		updateAsync();
	}, [faqItems.size]);

	const OpenTicket = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const checkEmptyWhitespace = (TicketContentRef.current?.value as string).replace(/\s/g, "").length;
		if (!(TicketContentRef.current && TicketContentRef.current.value) || !checkEmptyWhitespace) {
			TicketContentRef.current!.value = "";
			return;
		}
		try {
			setProcessingAll(true);
			const request: SupportTicketDto = {
				content: TicketContentRef.current?.value as string,
				source: "Widget",
				ticketAttachments: null,
			};
			const problem = await memberContext.createTicket(request);
			await memberContext.ticketsInfo({ loadAPI: true });
			TicketContentRef.current!.value = "";
			setTickets(null);
			setAnimation(true);
			if (problem) {
				setAnimationColor("darkred");
				return;
			}
			setAnimationColor("darkgreen");
			setProcessingAll(false);
		} catch (error) {
			setAnimationColor("darkred");
			setAnimation(false);
			setProcessingAll(false);
		}
	};
	const [expanded, setExpanded] = useState<string | false>(false);
	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setShowNewTicketBox(!isExpanded);
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<Card sx={{ borderRadius: "unset", overflow: "none" }}>
			<CardContent sx={{ padding: 0 }}>
				<HearderUI />
			</CardContent>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					height: window.innerHeight - FixedSizes.TOPBAR - FixedSizes.HEADER - FixedSizes.NAVBAR,
					overflow: "scroll",
				}}
			>
				{/* Ofte stilte spørsmål  */}
				<Box sx={{ maxWidth: "275px", minWidth: "275px", mt: 7 }}>
					<Typography variant="body1" sx={{ pb: 1, textAlign: "left", color: (theme) => theme.palette.secondary.main }}>
						FAQ
					</Typography>
					{Array.from(faqItems.keys()).map((category, categoryIndex) => {
						return (
							<>
								<Accordion
									sx={{
										mb: 1,
										"& .MuiAccordionSummary-root.Mui-expanded": {
											minHeight: "20px",
											maxHeight: "20px",
										},
										bgcolor: (theme) => theme.palette.background.default,
										minHeight: "40px",
									}}
									square
									elevation={1}
									id={"Accordion" + categoryIndex.toString()}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: (theme) => theme.palette.secondary.light }} />}
										aria-controls={`panel${categoryIndex}a-content`}
										id={"AccordionSummary" + categoryIndex.toString()}
										sx={{
											minHeight: "20px",
											paddingRight: "2px",
											paddingLeft: "16px",
											backgroundColor: (theme) => theme.palette.background.default,
											"& .MuiAccordionSummary-content": {
												marginTop: 0,
												marginBottom: 0,
												flexDirection: "column",
											},
											pt: 1.2,
										}}
									>
										<Typography variant="body2" sx={{ color: (theme) => theme.palette.secondary.main }}>
											{category}
										</Typography>
									</AccordionSummary>
									<AccordionDetails id={"AccordionDetails" + categoryIndex.toString()}>
										{(Array.from(faqItems.values()) as Array<FaqDto[]>).map((FAQs, faqIndex) => {
											return FAQs.map((faq, b) => {
												if (faq.category === category)
													return (
														<>
															<Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.secondary.main }}>
																{faq.question}
															</Typography>
															<Typography variant="body2" sx={{ pb: "4px", color: (theme) => theme.palette.secondary.main }}>
																{faq.answer}
															</Typography>
															<hr style={{ border: "0.01em solid rgba(0, 0, 0, 0.05)" }} />
														</>
													);
												return null;
											});
										})}
									</AccordionDetails>
								</Accordion>
							</>
						);
					})}
				</Box>
				{tickets && tickets?.length > 0 && (
					<Box sx={{ maxWidth: "275px", minWidth: "275px", mt: 2 }}>
						<Typography variant="body1" sx={{ pb: 1, textAlign: "left", color: (theme) => theme.palette.secondary.main }}>
							{t`My support tickets`}
						</Typography>
						{tickets.map((ticket) => {
							return (
								<ReplyContainer
									setTickets={setTickets}
									expanded={expanded}
									handleChange={handleChange}
									ticket={ticket}
									memberContext={memberContext}
								></ReplyContainer>
							);
						})}
					</Box>
				)}
				{showNewTicketBox && (
					<Box
						onSubmit={OpenTicket}
						noValidate
						component="form"
						autoComplete="off"
						ref={BoxRef}
						sx={{ ml: "0 !important", mt: 2, mb: 2, maxWidth: "275px", minWidth: "275px" }}
					>
						<Typography variant="body1" sx={{ pb: 0, textAlign: "left", color: (theme) => theme.palette.secondary.main }}>
							{t`New support ticket`}
						</Typography>
						<Typography variant="subtitle1" sx={{ pb: 2, textAlign: "left", color: (theme) => theme.palette.secondary.main }}>
							{t`Inquire about your membership only. For other queries, contact ${Props.program}; they won't be addressed here.`}
						</Typography>
						<CustomTextbox
							label={t`What can we help you with?`}
							name="ticket"
							id="ticket"
							variant="outlined"
							size="small"
							fullWidth
							onClick={() => {
								BoxRef.current?.scrollIntoView({ behavior: "smooth" });
							}}
							multiline
							rows={3}
							fontSize="11px"
							fontWeight="100"
							inputRef={TicketContentRef}
							disabled={processingAll}
						/>
						<Button
							sx={{
								bgcolor: (theme) => theme.palette.primary.dark,
								color: (theme) => theme.palette.text.secondary,
								border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
								marginTop: 1,
								height: "30px",
								"&.Mui-disabled": {
									color: (theme) => theme.palette.text.secondary,
									backgroundColor: (theme) => theme.palette.primary.light,
									border: "unset",
									opacity: 0.8,
								},
							}}
							type="submit"
							fullWidth
							variant="contained"
							size="small"
							disabled={processingAll}
						>
							{processingAll && <CircularStatic visible={processingAll} />}
							{!processingAll && t`SUBMIT`}
						</Button>
						<Grid
							item
							xs={12}
							sx={{
								height: "2px",
								animationName: "blinker",
								animationDuration: "5000ms",
								animationTimingFunction: "linear",
								animationIterationCount: animation ? "infinite" : 0,
								"@-webkit-keyframes blinker": {
									from: { backgroundColor: animationColor },
									to: { backgroundColor: "white" },
								},
								"@keyframes blinker": {
									from: { backgroundColor: animationColor },
									to: { backgroundColor: "white" },
									// "0%": {
									// 	background: "#00ff44",
									// },
									// "20%": {
									// 	background: "#02d139",
								},
								textAlign: "left",
							}}
						>
							{animation && animationColor === "darkred" ? (
								<Typography sx={{ color: animationColor }}>error 😏</Typography>
							) : (
								animation && <Typography sx={{ color: animationColor }}>Congratulation 🎉</Typography>
							)}
						</Grid>
					</Box>
				)}
			</CardActions>
		</Card>
	);
}

function SupportMain(Props: any) {
	const languageCtx = useContext(LanguageContext);
	const memberContext = useContext(APIContext);
	const TicketContentRef = useRef<TextFieldProps>(null);
	const BoxRef = useRef<null | HTMLElement>(null);
	const [processingAll, setProcessingAll] = useState<boolean>(false);
	const [tickets, setTickets] = useState<SupportTicketDto[] | null>(null);
	const [faqItems, setFaqItems] = useState<Map<string, FaqDto[]>>(new Map<string, FaqDto[]>());
	const [animation, setAnimation] = useState<boolean>(false);
	const [animationColor, setAnimationColor] = useState("");

	const GetFAQ = async () => {
		const temp = await memberContext.faqInfo({ loadAPI: false });
		let systemLang = languageCtx.language.length > 2 ? languageCtx.language.substring(0, 2) : languageCtx.language;
		if (systemLang === "da") systemLang = "dk";
		const faqDic = new Map<string, FaqDto[]>();
		// FIXME
		try {
			const FAQs = temp.get(systemLang);
			var faqRecord: Record<string, FaqDto[]> | null = null;
			faqRecord = groupBy(FAQs, (p) => p.category);
			if (faqRecord)
				for (const key in faqRecord) {
					const value = faqRecord[key] as FaqDto[];
					faqDic.set(key, value);
				}
			setFaqItems(faqDic);
			return faqDic;
		} catch {
			for (let index = 0; index < temp.length; index++) {
				const lang = temp[index][0] as string;
				if (lang === systemLang) {
					const FAQs = temp[index][1] as FaqDto[];
					const faqRecord = groupBy(FAQs, (p) => p.category);
					if (faqRecord)
						for (const key in faqRecord) {
							const value = faqRecord[key] as FaqDto[];
							faqDic.set(key, value);
						}
					setFaqItems(faqDic);
					return faqDic;
				}
			}
		}
	};

	useEffect(() => {
		let isMounted = true;
		const updateAsync = async (api: boolean) => {
			const ticketData = await memberContext.ticketsInfo({ loadAPI: api });

			if (!api) isMounted = false;
			const sortTicketData = ticketData.sort((a: SupportTicketDto, b: SupportTicketDto) => {
				const order = (a: number, b: number) => {
					if (a === b) return 0;
					if (a === null) return 1;
					if (b === null) return -1;
					return a > b ? 1 : -1;
				};
				return order(Number(b.ticketId), Number(a.ticketId));
			});
			setTickets(sortTicketData);
		};
		tickets === null && isMounted && updateAsync(false);
		return () => {
			isMounted && updateAsync(true);
			isMounted = false;
		};
	}, [tickets === null]);

	useEffect(() => {
		if (animation) {
			setTimeout(() => {
				setAnimation(false);
			}, 2000);
		}
	}, [animation]);

	useEffect(() => {
		const updateAsync = async () => {
			await GetFAQ();
		};
		updateAsync();
	}, [faqItems.size]);

	const [showNewTicketBox, setShowNewTicketBox] = useState(true);
	const OpenTicket = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const checkEmptyWhitespace = (TicketContentRef.current?.value as string).replace(/\s/g, "").length;
		if (!(TicketContentRef.current && TicketContentRef.current.value) || !checkEmptyWhitespace) {
			TicketContentRef.current!.value = "";
			return;
		}
		try {
			setProcessingAll(true);
			const request: SupportTicketDto = {
				content: TicketContentRef.current?.value as string,
				source: "Widget",
				ticketAttachments: null,
			};
			const problem = await memberContext.createTicket(request);
			await memberContext.ticketsInfo({ loadAPI: true });
			TicketContentRef.current!.value = "";
			setTickets(null);
			setAnimation(true);
			if (problem) {
				setAnimationColor("darkred");
				return;
			}
			setAnimationColor("darkgreen");
			setProcessingAll(false);
		} catch (error) {
			setAnimationColor("darkred");
			setAnimation(false);
			setProcessingAll(false);
		}
	};

	let layout = Layouting();
	let minNavigationHight = 0;
	if (layout.fs) {
		delete layout["height"];
		delete layout["overflow"];
		const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
		minNavigationHight = 100 * (182 / vh);
	}
	const [expanded, setExpanded] = useState<string | false>(false);
	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setShowNewTicketBox(!isExpanded);
		setExpanded(isExpanded ? panel : false);
	};
	return (
		<Card sx={layout}>
			<CardContent sx={{ padding: 0 }}>
				<HearderUI />
			</CardContent>
			<CardActions
				sx={{
					backgroundColor: (theme) => theme.palette.background.default,
					maxHeight: "246px",
					minHeight: () => {
						if (layout.fs) return `calc(100vh - ${minNavigationHight}vh)`;
						return "246px";
					},
					overflow: "auto",
				}}
			>
				{/* Ofte stilte spørsmål  */}
				<Box sx={{ maxWidth: "275px", minWidth: "275px", mt: 7 }}>
					<Typography variant="body1" sx={{ pb: 1, textAlign: "left", color: (theme) => theme.palette.secondary.main }}>
						FAQ
					</Typography>
					{Array.from(faqItems.keys()).map((category, categoryIndex) => {
						return (
							<>
								<Accordion
									sx={{
										mb: 1,
										"& .MuiAccordionSummary-root.Mui-expanded": {
											minHeight: "20px",
											maxHeight: "20px",
										},
										bgcolor: (theme) => theme.palette.background.default,
										minHeight: "40px",
									}}
									square
									elevation={1}
									id={Math.random().toString(36).substring(2)}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon sx={{ color: (theme) => theme.palette.secondary.light }} />}
										aria-controls={`panel${categoryIndex}a-content`}
										id={Math.random().toString(36).substring(2)}
										sx={{
											minHeight: "20px",
											paddingRight: "2px",
											paddingLeft: "16px",
											backgroundColor: (theme) => theme.palette.background.default,
											"& .MuiAccordionSummary-content": {
												marginTop: 0,
												marginBottom: 0,
												flexDirection: "column",
											},
											pt: 1.2,
										}}
									>
										<Typography id={Math.random().toString(36).substring(2)} variant="body2" sx={{ color: (theme) => theme.palette.secondary.main }}>
											{category}
										</Typography>
									</AccordionSummary>
									<AccordionDetails id={Math.random().toString(36).substring(2)}>
										{(Array.from(faqItems.values()) as Array<FaqDto[]>).map((FAQs, faqIndex) => {
											return FAQs.map((faq, b) => {
												if (faq.category === category)
													return (
														<>
															<Typography
																id={Math.random().toString(36).substring(2)}
																variant="subtitle2"
																sx={{ color: (theme) => theme.palette.secondary.main }}
															>
																{faq.question}
															</Typography>
															<Typography
																id={Math.random().toString(36).substring(2)}
																variant="body2"
																sx={{ pb: "4px", color: (theme) => theme.palette.secondary.main }}
															>
																{faq.answer}
															</Typography>
															<hr id={Math.random().toString(36).substring(2)} style={{ border: "0.01em solid rgba(0, 0, 0, 0.05)" }} />
														</>
													);
												return null;
											});
										})}
									</AccordionDetails>
								</Accordion>
							</>
						);
					})}
				</Box>
				{tickets && tickets?.length > 0 && (
					<Box sx={{ maxWidth: "275px", minWidth: "275px", mt: 2 }}>
						<Typography variant="body1" sx={{ pb: 1, textAlign: "left", color: (theme) => theme.palette.secondary.main }}>
							{t`My support tickets`}
						</Typography>
						{tickets.map((ticket) => {
							return (
								<ReplyContainer
									setTickets={setTickets}
									expanded={expanded}
									handleChange={handleChange}
									ticket={ticket}
									memberContext={memberContext}
								></ReplyContainer>
							);
						})}
					</Box>
				)}
				{showNewTicketBox && (
					<Box
						onSubmit={OpenTicket}
						noValidate
						component="form"
						autoComplete="off"
						ref={BoxRef}
						sx={{ ml: "0 !important", mt: 2, mb: 2, maxWidth: "275px", minWidth: "275px" }}
					>
						<Typography variant="body1" sx={{ pb: 2, textAlign: "left", color: (theme) => theme.palette.secondary.main }}>
							{t`New support ticket`}
						</Typography>
						<Typography variant="subtitle1" sx={{ pb: 2, textAlign: "left", color: (theme) => theme.palette.secondary.main }}>
							{t`Inquire about your membership only. For other queries, contact ${Props.program}; they won't be addressed here.`}
						</Typography>
						<CustomTextbox
							label={t`What can we help you with?`}
							name="ticket"
							id="ticket"
							variant="outlined"
							size="small"
							fullWidth
							onClick={() => {
								BoxRef.current?.scrollIntoView({ behavior: "smooth" });
							}}
							multiline
							rows={3}
							fontSize="11px"
							fontWeight="100"
							inputRef={TicketContentRef}
							disabled={processingAll}
						/>
						<Button
							sx={{
								bgcolor: (theme) => theme.palette.primary.dark,
								color: (theme) => theme.palette.text.secondary,
								border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
								marginTop: 1,
								height: "30px",
								"&.Mui-disabled": {
									color: (theme) => theme.palette.text.secondary,
									backgroundColor: (theme) => theme.palette.primary.light,
									border: "unset",
									opacity: 0.8,
								},
							}}
							type="submit"
							fullWidth
							variant="contained"
							size="small"
							disabled={processingAll}
						>
							{processingAll && <CircularStatic visible={processingAll} />}
							{!processingAll && t`SUBMIT`}
						</Button>
						<Grid
							item
							xs={12}
							sx={{
								height: "2px",
								animationName: "blinker",
								animationDuration: "5000ms",
								animationTimingFunction: "linear",
								animationIterationCount: animation ? "infinite" : 0,
								"@-webkit-keyframes blinker": {
									from: { backgroundColor: animationColor },
									to: { backgroundColor: "white" },
								},
								"@keyframes blinker": {
									from: { backgroundColor: animationColor },
									to: { backgroundColor: "white" },
									// "0%": {
									// 	background: "#00ff44",
									// },
									// "20%": {
									// 	background: "#02d139",
								},
								textAlign: "left",
							}}
						>
							{animation && animationColor === "darkred" ? (
								<Typography sx={{ color: animationColor }}>error 😏</Typography>
							) : (
								animation && <Typography sx={{ color: animationColor }}>Congratulation 🎉</Typography>
							)}
						</Grid>
					</Box>
				)}
			</CardActions>
		</Card>
	);
}

export function SupportUI() {
	const userContext = useContext(UserContext);
	const is = useContext(IntegrationSettingsContext);
	switch (userContext.widgetSizeState()) {
		case WidgetSize.Main:
			return <SupportMain program={is.displayName} />;
		case WidgetSize.Full:
			return <SupportFull program={is.displayName} />;
		default:
			return <SupportMain />;
	}
}
