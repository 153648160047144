/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Address
 */
export interface AddressDto {
  /** Address type */
  addressType?: AddressType;
  /** Street name */
  street?: string | null;
  /** Zip code */
  zipCode?: string | null;
  /** City */
  city?: string | null;
  /** Two letter country code */
  countryCode?: string | null;
  /** Country name (TBD) */
  country?: string | null;
}

/**
 * Address type (1 = customer address, 2 = shipping address, 3 = billing address, 4 = other address
 */
export enum AddressType {
  Customer = "Customer",
  Shipping = "Shipping",
  Billing = "Billing",
}

export interface AuthRequestByEmailDTO {
  /**
   * Provided API key
   * @format uuid
   */
  apiKey: string;
  /**
   * Which program this relates to
   * @format int32
   */
  programId: number;
  /** Email */
  emailAddress: string;
  /**
   * Duration of jwt token in seconds
   * @format double
   */
  duration?: number;
}

/**
 * Parameters needed to authenticate via an apikey
 */
export interface AuthRequestDTO {
  /**
   * Provided API key
   * @format uuid
   */
  apiKey: string;
  /**
   * Which program this relates to
   * @format int32
   */
  programId: number;
  /** Phone number in the format of: {prefix}{number}, i.e.: 4740123456 */
  phoneNumber: string;
  /**
   * Duration of jwt token in seconds
   * @format double
   */
  duration?: number;
}

export interface AuthResponseDTO {
  token?: string | null;
  /** Represents a given member in a given program */
  member?: MemberDTO | null;
}

export interface BonusInfoDto {
  /** @format double */
  bonusApplicableAmount?: number | null;
  /** @format double */
  bonusUnapplicableAmount?: number;
  /** @format double */
  basePercentage?: number | null;
}

export interface CardDTO {
  /** Card types */
  cardType?: CardType;
  cardId?: string | null;
  source?: string | null;
  maskedPan?: string | null;
  /** @format int32 */
  expMonth?: number;
  /** @format int32 */
  expYear?: number;
  /** @format date-time */
  createdAt?: string;
}

/**
 * Card types
 */
export enum CardType {
  AccountNumber = "AccountNumber",
  Visa = "Visa",
  MasterCard = "MasterCard",
  Maestro = "Maestro",
  Diners = "Diners",
  Amex = "Amex",
  Bring = "Bring",
  Dankort = "Dankort",
  Netsprepaid = "Netsprepaid",
  Unkowncreditcard = "Unkowncreditcard",
  Verifonetoken = "Verifonetoken",
  SpondEncryption = "SpondEncryption",
  StoreboxCards = "StoreboxCards",
  AmericanExpress = "AmericanExpress",
}

export enum ConsentType {
  SMS = "SMS",
  Email = "Email",
  Offers = "Offers",
  UserAgreement = "UserAgreement",
}

/**
 * Request to create a new member in given program
 */
export interface CreateMemberProfileRequestDTO {
  /** Represents a given member in a given program */
  member: MemberDTO;
  /** Source of recruitment */
  recruitmentSource: string;
  /** Temporary variable due to typo. */
  recuitmentSource?: string | null;
  /**
   * Program to add member to
   * @format int32
   */
  programId: number;
  /** Loyalty Context */
  loyaltyContext?: LoyaltyContextDto | null;
  /** Verification code from CreateVerificationCode. */
  verificationCode?: string | null;
}

export interface CreateMemberProfileResponseDTO {
  /** Represents a given member in a given program */
  member?: MemberDTO | null;
  token?: string | null;
}

export interface CreateMrcTokenResponse {
  token?: string | null;
}

/**
 * reference with MerchantDeparmentUsers and BudgetSegments
 */
export interface DepartmentDTO {
  /** @format int32 */
  id?: number;
  displayName?: string | null;
  /** Address */
  address?: AddressDto | null;
  phoneNumber?: string | null;
  email?: string | null;
  website?: string | null;
  departmentBudgetSegment?: string | null;
}

export interface DiscountDto {
  amount?: Money | null;
  /** @format double */
  percentage?: number;
}

export interface ErrorDTO {
  message?: string | null;
}

export interface FaqDto {
  question?: string | null;
  answer?: string | null;
  category?: string | null;
  /** @format int32 */
  sortIndex?: number;
}

/**
 * Gender
 */
export enum Gender {
  Female = "Female",
  Male = "Male",
  Other = "Other",
  Unknown = "Unknown",
}

/**
* All requests need ProgramId and MerchantTerminalGuid.  Will be automatically filled out from:
- User claims
- DTO
- Http-headers
            
Relevant http-headers are:
            
X-Program-Id (int)
X-Merchant-Terminal-Guid (guid)
X-Operator-Id (string)
*/
export interface LoyaltyContextDto {
  /** @format int32 */
  programId?: number | null;
  /** @format uuid */
  merchantTerminalGuid?: string | null;
}

/**
 * Bonus information for given member
 */
export interface MemberBonusInfoDTO {
  /** Merchant information for this bonus object */
  merchant?: MerchantInfoDTO | null;
  /**
   * How much bonus is available
   * @format double
   */
  availableBonus?: number;
  /**
   * How much bonus will become available in the future
   * @format double
   */
  upcomingBonus?: number;
  /**
   * How much bonus has the member actually used
   * @format double
   */
  appliedBonus?: number;
  /**
   * Amount of reserved bonus
   * @format double
   */
  reservedBonus?: number;
}

/**
 * Member consent
 */
export interface MemberConsentDTO {
  /** Represents a given member in a given program */
  member?: MemberDTO | null;
  /** Program that this consent belongs to */
  program?: ProgramDTO | null;
  /** Consent type */
  consentType?: ConsentType;
  /**
   * Consent is valid from given date
   * @format date-time
   */
  givenAt?: string;
  /**
   * Time consent was withdrawn by user, if any
   * @format date-time
   */
  withdrawnAt?: string | null;
  /**
   * Time consent expires
   * @format date-time
   */
  expiresAt?: string | null;
  /**
   * Consent creation date
   * @format date-time
   */
  createdAt?: string;
  /** Whether or not the consent is currently valid (computed value) */
  isValid?: boolean;
}

/**
 * Represents a given member in a given program
 */
export interface MemberDTO {
  /**
   * Unique identifier for given member on given program
   * @format uuid
   */
  id: string;
  /** First name */
  firstName?: string | null;
  /** Last name */
  lastName?: string | null;
  /** Cellphone number */
  cellphoneNumber: string;
  /** E-mail address */
  emailAddress?: string | null;
  /**
   * Birth date
   * @format date-time
   */
  birthDate?: string | null;
  /** Default address */
  address?: AddressDto | null;
  /** Gender.  Can be m/f/o/u (male, female, other, unknown). Default unknown. */
  gender?: Gender;
  /** Program tag */
  programTag?: string | null;
  /** Current member level */
  memberLevel?: MemberLevelDto | null;
  /** Next member level */
  nextLevel?: MemberLevelDto | null;
  /**
   * UTC Date and Time for when user agreement was accepted
   * @format date-time
   */
  userAgreementAcceptDate?: string | null;
  /** Recruitment source */
  recruitmentSource?: string | null;
}

/**
 * Key figures for a member (revenue, member since, number of cards etc)
 */
export interface MemberKeyFiguresDTO {
  /**
   * Total sum of all transactions for this member
   * @format double
   */
  totalRevenue?: number;
  /**
   * How many transactions
   * @format int32
   */
  transactionCount?: number;
  /**
   * Average transaction size
   * @format double
   */
  basketSizeAvg?: number;
  /**
   * Number of connected cards/accounts
   * @format int32
   */
  cardCount?: number;
  /**
   * When was the last registered transaction time
   * @format date-time
   */
  lastTransactionTime?: string | null;
  /**
   * When did the member join the program
   * @format date-time
   */
  memberSince?: string | null;
}

export interface MemberLevelDto {
  /** Name of level */
  displayName?: string | null;
  /**
   * Level. Valid values are 0 and up (normally 3-5 levels)
   * @format int32
   */
  level?: number;
  /**
   * When member joined this level
   * @format date-time
   */
  fromDate?: string | null;
  /**
   * Progress of current level until member will be upgraded to next level. Valid values: 0-1.
   * @format double
   */
  progress?: number;
  /** Discriminator (currency, points, etc) */
  pointDiscriminator?: string | null;
  /**
   * How many points (of the discriminator type) remain until member reaches next level
   * @format double
   */
  pointsUntilNextLevel?: number;
  /** CSS */
  styling?: any;
}

export interface MemberLoginRequestDTO {
  /** @format int32 */
  programId?: number;
  /**
   * All requests need ProgramId and MerchantTerminalGuid.  Will be automatically filled out from:
   * - User claims
   * - DTO
   * - Http-headers
   *
   * Relevant http-headers are:
   * X-Program-Id (int)
   * X-Merchant-Terminal-Guid (guid)
   * X-Operator-Id (string)
   */
  loyaltyContext?: LoyaltyContextDto | null;
  phoneNumber?: string | null;
  verificationCode?: string | null;
  recruitmentSource?: string | null;
}

export interface MerchantDto {
  displayName?: string | null;
  /** Address */
  address?: AddressDto | null;
  phoneNumber?: string | null;
  email?: string | null;
  website?: string | null;
  /** @format uri */
  logoUrl?: string | null;
}

/**
 * Merchant information
 */
export interface MerchantInfoDTO {
  /**
   * Internal ID of Merchant
   * @format int32
   */
  merchantId?: number;
  /** Name of Merchant */
  name?: string | null;
}

export interface Money {
  /** @format double */
  amount?: number;
  currencyCode?: string | null;
}

export interface PaymentDto {
  paymentType?: PaymentType;
  amount?: Money | null;
  /** @format date-time */
  paymentTime?: string;
  attributes?: any;
}

export enum PaymentType {
  Cash = "Cash",
  Card = "Card",
  Other = "Other",
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export interface ProgramDTO {
  /** @format int32 */
  programId?: number;
  name?: string | null;
}

/**
 * Storebox css builder object
 */
export interface StoreboxIframeCSS {
  /** The border-radius CSS property rounds the corners of an element's outer border edge in px. */
  bodyBorderRadius?: string | null;
  /** Iframe background color */
  bodyBackgroundColor?: string | null;
  /** Padding is used to create space around an element's content. */
  bodyPadding?: string | null;
  /** Property sets how the total width and height of an element is calculated. */
  bodyBoxSizing?: string | null;
  /** Width */
  inputWidth?: string | null;
  /** Padding is used to create space around an element's content. */
  inputPadding?: string | null;
  /** Border with following order: border-width, border-style, border-color (hex/Color Names Supported by All Browsers) */
  inputBorder?: string | null;
  /** The border-radius CSS property rounds the corners of an element's outer border edge in px. */
  inputBorderRadius?: string | null;
  /** Property sets how the total width and height of an element is calculated. */
  inputBoxSizing?: string | null;
  /** Property sets the margin area on the top of an element in px. */
  inputMarginTop?: string | null;
  /** /// Property sets the margin area on the bottom of an element in px. */
  inputMarginBottom?: string | null;
  /** Property sets whether an element is resizable, and if so, in which directions. */
  inputResize?: string | null;
  /** Button background color */
  buttonBackgroundColor?: string | null;
  /** Button forecolor */
  buttonColor?: string | null;
  /** Padding is used to create space around an element's content. */
  buttonPadding?: string | null;
  /** Border with following order: border-width, border-style, border-color (hex/Color Names Supported by All Browsers) */
  buttonBorder?: string | null;
  /** The border-radius CSS property rounds the corners of an element's outer border edge in px. */
  buttonBorderRadius?: string | null;
  /** Property specifies the mouse cursor to be displayed when pointing over an element. */
  buttonCursor?: string | null;
  /** Labels forecolor */
  labelColor?: string | null;
}

/**
 * Request data object to provide requirements
 */
export interface StoreboxIframeRequestDTO {
  /** Where to redirect on successful registration */
  returnURL: string;
  /** ISO 639-1 language code */
  language?: string | null;
  /** Iframe css style */
  css?: StoreboxIframeCSS | null;
}

export interface SupportTicketDto {
  /** @format int32 */
  ticketId?: number;
  ticketStatus?: TicketStatus;
  source?: string | null;
  content?: string | null;
  ticketAttachments?: TicketAttachmentDto[] | null;
  ticketReplys?: TicketReplyDto[] | null;
  /** @format date-time */
  createdAt?: string | null;
}

export interface TaxInfo {
  taxCode?: string | null;
  /** @format double */
  percentage?: number;
  /** @format double */
  amount?: number | null;
}

/**
 * Terms of Service DTO
 */
export interface TermsOfServiceDTO {
  /** HTML representing the terms of service for given program */
  tosHtml?: string | null;
  /** Alpha2code for language */
  language?: string | null;
}

export interface TicketAttachmentDto {
  /** @format int32 */
  id?: number;
  /** @format uuid */
  guid?: string;
  filename?: string | null;
  /** @format byte */
  data?: string | null;
  /** @format date-time */
  createdAt?: string;
}

export interface TicketReplyDto {
  /** @format int32 */
  ticketId?: number;
  /** @format int32 */
  replyId?: number;
  replyContent?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  ticketAttachments?: TicketAttachmentDto[] | null;
}

export interface TicketReplyRequestDto {
  /** @format int32 */
  ticketId?: number;
  content?: string | null;
  ticketAttachments?: TicketAttachmentDto[] | null;
}

export enum TicketStatus {
  WaitingForSupport = "WaitingForSupport",
  WaitingForMember = "WaitingForMember",
  Solved = "Solved",
  Hidden = "Hidden",
}

/**
 * Represents a single transaction for a given member
 */
export interface TransactionDto {
  externalOrderReference?: string | null;
  amountWithTaxes?: Money | null;
  paidAmount?: Money | null;
  taxLines?: TaxInfo[] | null;
  payments?: PaymentDto[] | null;
  bonusInfo?: BonusInfoDto | null;
  /** @format date-time */
  purchaseTime?: string;
  /** @format date-time */
  bonusValidFrom?: string | null;
  /** @format date-time */
  createdAt?: string;
  transactionLines?: TransactionLineDto[] | null;
  merchant?: MerchantDto | null;
  program?: ProgramDTO | null;
  /** Represents a given member in a given program */
  member?: MemberDTO | null;
  /** reference with MerchantDeparmentUsers and BudgetSegments */
  department?: DepartmentDTO | null;
}

export interface TransactionLineDto {
  product?: TransactionProductDto | null;
  discount?: DiscountDto | null;
  bonusInfo?: BonusInfoDto | null;
  /** @format double */
  quantity?: number;
  /** @format double */
  unitPrice?: number;
  /** @format double */
  lineSumWithTaxes?: number;
  tax?: TaxInfo | null;
  externalOrderLineReference?: string | null;
}

export interface TransactionProductDto {
  displayName?: string | null;
  productGroups?: string[] | null;
}

/**
 * Summary of transactions group by year/month
 */
export interface TransactionSummaryDTO {
  /** @format int32 */
  year?: number;
  /** @format int32 */
  month?: number;
  productCategory?: string | null;
  /** @format int32 */
  transactionCount?: number;
  /** @format double */
  quantity?: number;
  /** @format double */
  sum?: number;
}

export interface UpdateMemberProfileRequestDTO {
  /** Represents a given member in a given program */
  member?: MemberDTO | null;
  /** @format int32 */
  programId?: number;
  /**
   * All requests need ProgramId and MerchantTerminalGuid.  Will be automatically filled out from:
   * - User claims
   * - DTO
   * - Http-headers
   *
   * Relevant http-headers are:
   * X-Program-Id (int)
   * X-Merchant-Terminal-Guid (guid)
   * X-Operator-Id (string)
   */
  loyaltyContext?: LoyaltyContextDto | null;
}

export interface UpdateMemberProfileResponseDTO {
  /** Represents a given member in a given program */
  member?: MemberDTO | null;
}

export interface VoucherDTO {
  reference?: string | null;
  voucherType?: VoucherType;
  title?: string | null;
  header?: string | null;
  subHeader?: string | null;
  body?: string | null;
  imageUrl?: string | null;
  /** @format double */
  amount?: number;
  canRelease?: boolean;
  /** @format date-time */
  validFrom?: string;
  /** @format date-time */
  validTo?: string | null;
  /** @format date-time */
  redeemedTime?: string | null;
}

export enum VoucherType {
  Offer = "Offer",
  Giftcard = "Giftcard",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Loyalty Member Self Service
 * @version latest
 * @contact
 *
 * Self service membership API for Loyalty
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Authentication
     * @name AuthCurrentClaims
     * @summary Returns all the claims of the currently authenticated user.
     * @request GET:/api/member/v1/auth
     * @secure
     */
    authCurrentClaims: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/member/v1/auth`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthDevOnly
     * @summary DEVELOPMENT ONLY:  easy login for development/testing
     * @request POST:/api/member/v1/auth/devbyphone
     * @secure
     */
    authDevOnly: (
      query?: {
        /**
         * Program identifier
         * @format int32
         */
        programId?: number;
        /** Member identifier */
        phoneNumber?: string;
        /**
         * Duration of token in seconds
         * @format int32
         */
        duration?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, ProblemDetails>({
        path: `/api/member/v1/auth/devbyphone`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthDevOnlyViaEmail
     * @summary DEVELOPMENT ONLY:  easy login for development/testing
     * @request POST:/api/member/v1/auth/devbyemail
     * @secure
     */
    authDevOnlyViaEmail: (
      query?: {
        /**
         * Program identifier
         * @format int32
         */
        programId?: number;
        /** Email identifier */
        emailAddress?: string;
        /**
         * Duration of token in seconds
         * @format int32
         */
        duration?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, ProblemDetails>({
        path: `/api/member/v1/auth/devbyemail`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthViaApikey
     * @summary Login using apikey with phone number
     * @request POST:/api/member/v1/auth/apikey
     * @secure
     */
    authViaApikey: (data: AuthRequestDTO, params: RequestParams = {}) =>
      this.request<AuthResponseDTO, ProblemDetails>({
        path: `/api/member/v1/auth/apikey`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name AuthViaApiKeyAndEmail
     * @summary Login using apikey with email address
     * @request POST:/api/member/v1/auth/email
     * @secure
     */
    authViaApiKeyAndEmail: (data: AuthRequestByEmailDTO, params: RequestParams = {}) =>
      this.request<AuthResponseDTO, ProblemDetails>({
        path: `/api/member/v1/auth/email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name MemberAuthMemberLogin
     * @summary Authenticates a member using verification code
     * @request POST:/api/member/v1/memberauth/login
     * @secure
     */
    memberAuthMemberLogin: (data: MemberLoginRequestDTO, params: RequestParams = {}) =>
      this.request<AuthResponseDTO, ProblemDetails | void>({
        path: `/api/member/v1/memberauth/login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name MemberAuthCreateMemberVerificationCode
     * @summary Sends a verification code via SMS
     * @request POST:/api/member/v1/memberauth/verificationcode
     * @secure
     */
    memberAuthCreateMemberVerificationCode: (
      query?: {
        /** @format int32 */
        programId?: number;
        phoneNumber?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails | void>({
        path: `/api/member/v1/memberauth/verificationcode`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Authentication
     * @name MemberAuthCreateTokenFromMrcId
     * @summary Internal method;  retrieves a member token based on member registration contact id
     * @request GET:/api/member/v1/memberauth/mrctoken/{mrcId}
     * @secure
     */
    memberAuthCreateTokenFromMrcId: (mrcId: string, params: RequestParams = {}) =>
      this.request<CreateMrcTokenResponse, ProblemDetails>({
        path: `/api/member/v1/memberauth/mrctoken/${mrcId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bonus
     * @name BonusGetMemberBonus
     * @summary Returns current bonus balance.
     * @request GET:/api/member/v1/bonus
     * @secure
     */
    bonusGetMemberBonus: (params: RequestParams = {}) =>
      this.request<MemberBonusInfoDTO[], ProblemDetails>({
        path: `/api/member/v1/bonus`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bonus
     * @name BonusApply
     * @summary Uses a specified amount of bonus.  Will return
     * @request POST:/api/member/v1/bonus/apply
     * @secure
     */
    bonusApply: (
      query?: {
        /** @format int32 */
        merchantId?: number;
        /** @format double */
        amount?: number;
        redeemedBy?: string;
        redeemedByEmail?: string;
        externalReferenceNumber?: string;
        reason?: string;
        terminalId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails>({
        path: `/api/member/v1/bonus/apply`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cards and Accounts
     * @name CardGetMemberCards
     * @summary Lists all cards for the given member.
     * @request GET:/api/member/v1/card
     * @secure
     */
    cardGetMemberCards: (params: RequestParams = {}) =>
      this.request<CardDTO[], ProblemDetails>({
        path: `/api/member/v1/card`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags Cards and Accounts
 * @name CardGetCardFrame
 * @summary Creates a registration link for VISA/mastercard for this member.  After
finalizing the registration the browser/iframe will be returned to
given URL.
 * @request POST:/api/member/v1/card/cardframe
 * @secure
 */
    cardGetCardFrame: (data: StoreboxIframeRequestDTO, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/api/member/v1/card/cardframe`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cards and Accounts
     * @name CardRegisterBankaxept
     * @summary Register a new bankAxept account on the member.
     * @request PUT:/api/member/v1/card/register/bankaxept
     * @secure
     */
    cardRegisterBankaxept: (
      query?: {
        /** Account number, 11 digits */
        accountNumber?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails>({
        path: `/api/member/v1/card/register/bankaxept`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Cards and Accounts
     * @name CardRemoveCard
     * @summary Disconnects a card from the given Member.
     * @request DELETE:/api/member/v1/card/{cardId}
     * @secure
     */
    cardRemoveCard: (cardId: string, params: RequestParams = {}) =>
      this.request<void, string>({
        path: `/api/member/v1/card/${cardId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Consent
     * @name ConsentGetMemberConsent
     * @summary Returns list of consents
     * @request GET:/api/member/v1/consent
     * @secure
     */
    consentGetMemberConsent: (params: RequestParams = {}) =>
      this.request<MemberConsentDTO[], ProblemDetails>({
        path: `/api/member/v1/consent`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Consent
     * @name ConsentCreateMemberConsent
     * @summary Grant a consent to the current program
     * @request POST:/api/member/v1/consent/consent
     * @secure
     */
    consentCreateMemberConsent: (
      query?: {
        /** Consent Type to grant */
        consentType?: ConsentType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails>({
        path: `/api/member/v1/consent/consent`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Consent
     * @name ConsentDeleteMemberConsent
     * @summary Withdraw given consent
     * @request DELETE:/api/member/v1/consent/consent
     * @secure
     */
    consentDeleteMemberConsent: (
      query?: {
        /** Consent Type to deny */
        consentType?: ConsentType;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails>({
        path: `/api/member/v1/consent/consent`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags FAQ
     * @name FaqGetFaq
     * @summary Get list of FAQ for specific program
     * @request GET:/api/member/v1/faq
     * @secure
     */
    faqGetFaq: (
      query?: {
        language?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FaqDto[], ProblemDetails>({
        path: `/api/member/v1/faq`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Giftcards and Offers
     * @name VoucherGetActive
     * @summary Lists all available giftcards / vouchers.
     * @request GET:/api/member/v1/voucher
     * @secure
     */
    voucherGetActive: (params: RequestParams = {}) =>
      this.request<VoucherDTO[], ProblemDetails>({
        path: `/api/member/v1/voucher`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Giftcards and Offers
     * @name VoucherApply
     * @summary Applies/uses a voucher.  Will return a 409 Conflict if voucher is already used or 404 Not Found if it doesn't exist.
     * @request POST:/api/member/v1/voucher/{voucherRef}/apply
     * @secure
     */
    voucherApply: (
      voucherRef: string,
      query?: {
        redeemedBy?: string;
        reason?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, ProblemDetails>({
        path: `/api/member/v1/voucher/${voucherRef}/apply`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberGetMemberInfo
     * @request GET:/api/member/v1/member
     * @secure
     */
    memberGetMemberInfo: (params: RequestParams = {}) =>
      this.request<MemberDTO, ProblemDetails>({
        path: `/api/member/v1/member`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberCreateMember
     * @summary Create a new member
     * @request POST:/api/member/v1/member
     * @secure
     */
    memberCreateMember: (data: CreateMemberProfileRequestDTO, params: RequestParams = {}) =>
      this.request<CreateMemberProfileResponseDTO, ErrorDTO>({
        path: `/api/member/v1/member`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberUpdateMemberProfile
     * @summary Update an existing member
     * @request PUT:/api/member/v1/member
     * @secure
     */
    memberUpdateMemberProfile: (data: UpdateMemberProfileRequestDTO, params: RequestParams = {}) =>
      this.request<UpdateMemberProfileResponseDTO, ErrorDTO>({
        path: `/api/member/v1/member`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberGetKeyFigures
     * @summary Retrieves key figures for the authenticated member.  Internal use.
     * @request GET:/api/member/v1/member/keyfigures
     * @secure
     */
    memberGetKeyFigures: (params: RequestParams = {}) =>
      this.request<MemberKeyFiguresDTO, ProblemDetails>({
        path: `/api/member/v1/member/keyfigures`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Member
     * @name MemberGetTransactionSummary
     * @request GET:/api/member/v1/member/transactionsummary
     * @secure
     */
    memberGetTransactionSummary: (params: RequestParams = {}) =>
      this.request<TransactionSummaryDTO[], ProblemDetails>({
        path: `/api/member/v1/member/transactionsummary`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Receipts
     * @name ReceiptGetTransactions
     * @request GET:/api/member/v1/receipt
     * @secure
     */
    receiptGetTransactions: (
      query?: {
        /** @format date-time */
        PurchaseDateMax?: string;
        /** @format date-time */
        PurchaseDateMin?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TransactionDto[], ProblemDetails>({
        path: `/api/member/v1/receipt`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Terms of Service
     * @name TermsOfServiceGetById
     * @summary Returns the terms of service / user agreement
     * @request GET:/api/member/v1/termsofservice/byid/{programId}/{language}
     * @secure
     */
    termsOfServiceGetById: (programId: number, language: string, params: RequestParams = {}) =>
      this.request<TermsOfServiceDTO, ProblemDetails>({
        path: `/api/member/v1/termsofservice/byid/${programId}/${language}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Terms of Service
     * @name TermsOfServiceGetByProgramId
     * @summary Returns the terms of service / user agreement
     * @request GET:/api/member/v1/termsofservice/{programId}/{language}
     * @secure
     */
    termsOfServiceGetByProgramId: (programId: string, language: string, params: RequestParams = {}) =>
      this.request<TermsOfServiceDTO, ProblemDetails>({
        path: `/api/member/v1/termsofservice/${programId}/${language}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name TicketGetTickets
     * @summary Member all tickets
     * @request GET:/api/member/v1/ticket
     * @secure
     */
    ticketGetTickets: (params: RequestParams = {}) =>
      this.request<SupportTicketDto[], ProblemDetails>({
        path: `/api/member/v1/ticket`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name TicketCreateTicket
     * @summary Create a new ticket
     * @request POST:/api/member/v1/ticket
     * @secure
     */
    ticketCreateTicket: (data: SupportTicketDto, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/member/v1/ticket`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Ticket
     * @name TicketReplyTicket
     * @summary Reply to the ticket
     * @request POST:/api/member/v1/ticket/reply
     * @secure
     */
    ticketReplyTicket: (data: TicketReplyRequestDto, params: RequestParams = {}) =>
      this.request<void, ProblemDetails>({
        path: `/api/member/v1/ticket/reply`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
