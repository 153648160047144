import React, { useContext } from "react";
import { LanguageContext } from "../context/languagecontext";

export function LoadHTML(programId: string, displayName: string) {
	const languageCtx = useContext(LanguageContext);

	const no_172 = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Bli medlem, få fordeler!</h4>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>Medlemmer i Smartmembers får våre beste fordeler.</h5>
			<h6 style={{ marginBottom: 0 }}>🛌 Gratis oppgradering av rom</h6>
			<h6 style={{ marginBottom: 0, marginTop: 1 }}>🎁 Velkomstpakke ved ankomst</h6>
			<h6 style={{ marginBottom: 0, marginTop: 1 }}>💰 10% rabatt og bonusopptjening ved direktebooking</h6>
		</center>
	);
	const en_172 = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Become a member, get benefits!</h4>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>Smartmembers receive our best benefits.</h5>
			<h6 style={{ marginBottom: 0 }}>🛌 Free room upgrade</h6>
			<h6 style={{ marginBottom: 0, marginTop: 1 }}>🎁 Welcome package upon arrival</h6>
			<h6 style={{ marginBottom: 0, marginTop: 1 }}>💰 10% discount and bonus accumulation on direct booking</h6>
		</center>
	);
	const sv_172 = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Bli medlem, få fördelar!</h4>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>Medlemmar i Smartmembers får våra bästa fördelar.</h5>
			<h6 style={{ marginBottom: 0 }}>🛌 Gratis uppgradering av rum</h6>
			<h6 style={{ marginBottom: 0, marginTop: 1 }}>🎁 Välkomstpakke vid ankomst</h6>
			<h6 style={{ marginBottom: 0, marginTop: 1 }}>💰 10% rabatt och bonusackumulering vid direktbokning</h6>
		</center>
	);
	const da_172 = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Bliv medlem, få fordele!</h4>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>Medlemmer af Smartmembers får vores bedste fordele.</h5>
			<h6 style={{ marginBottom: 0 }}>🛌 Gratis opgradering af værelse</h6>
			<h6 style={{ marginBottom: 0, marginTop: 1 }}>🎁 Velkomstpakke ved ankomst</h6>
			<h6 style={{ marginBottom: 0, marginTop: 1 }}>💰 10% rabat og bonusoptjening ved direktebooking</h6>
		</center>
	);

	const no_221 = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Classic Norway Medlemmer får våre beste fordeler.</h4>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>💰 5 - 15% medlemsrabatt</h5>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>🛌 Tidlig innsjekk ved tilgjengelig</h5>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>🎁 5% bonus på ditt opphold</h5>
		</center>
	);
	const en_221 = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Classic Norway members get our best benefits.</h4>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>💰 5 - 15% membership discount</h5>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>🛌 Early check-in if available</h5>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>🎁 5% bonus on your stay</h5>
		</center>
	);
	const sv_221 = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Classic Norway medlemmar får våra bästa förmåner.</h4>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>💰 5 - 15% medlemsrabatt</h5>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>🛌 Tidig incheckning om tillgängligt</h5>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>🎁 5% bonus på din vistelse</h5>
		</center>
	);
	const da_221 = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Classic Norway medlemmer får vores bedste fordele.</h4>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>💰 5 - 15% medlemsrabat</h5>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>🛌 Tidlig indtjekning, hvis tilgængelig</h5>
			<h5 style={{ marginBottom: 0, marginTop: 1 }}>🎁 5% bonus på dit ophold</h5>
		</center>
	);

	const no_default = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Bli med i {displayName} og få våre beste fordeler på oppholdet ditt.</h4>
		</center>
	);
	const en_default = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Join {displayName} and get our best benefits to enhance your stay.</h4>
		</center>
	);
	const sv_default = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Bli med i {displayName} och få våra bästa förmåner på din vistelse.</h4>
		</center>
	);
	const da_default = (
		<center>
			<h4 style={{ marginBottom: 0 }}>Tilmeld dig {displayName} og få vores bedste fordele på dit ophold.</h4>
		</center>
	);

	let html = en_default;
	const language = languageCtx.language.length > 2 ? languageCtx.language.substring(0, 2) : languageCtx.language;
	switch (programId) {
		case "172":
			switch (language) {
				case "en":
					html = en_172;
					break;
				case "sv":
					html = sv_172;
					break;
				case "da":
					html = da_172;
					break;
				default:
					html = no_172;
					break;
			}
			break;
		case "221":
			switch (language) {
				case "en":
					html = en_221;
					break;
				case "sv":
					html = sv_221;
					break;
				case "da":
					html = da_221;
					break;
				default:
					html = no_221;
					break;
			}
			break;
		default:
			switch (language) {
				case "en":
					html = en_default;
					break;
				case "sv":
					html = sv_default;
					break;
				case "da":
					html = da_default;
					break;
				default:
					html = no_default;
					break;
			}
			break;
	}

	return <React.Fragment>{html}</React.Fragment>;
}
