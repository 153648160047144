import { log } from "../service/clientlog";
import { Button, Box, Typography, Card, CardActions, CardContent } from "@mui/material";
import { Trans } from "@lingui/macro";
import { useContext, useEffect, useState } from "react";
import { APIContext } from "../context/apicontext";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { CountryCode, E164Number } from "libphonenumber-js/core";
import "../assets/styles/phoneInput.css";
import { TopBarIcons } from "../components/topbar";
import CircularStatic from "../components/progress";
import { ElementTextByTemplate, GetTranslatedText } from "../service/jsonreader";
import { IntegrationSettingsContext } from "../context/integrationsettingscontext";
import { Constants } from "../constants/constants";
import VippsButton from "../components/vipps";
import "../assets/styles/hr.css";
import { AuthenticationTypes, Components, TemplateElements, LoginButtonStyle, ConsoleType } from "../types/enum";

function cleanLocationBeforeVippsRedirect() {
	return new URL(window.location.origin + window.location.pathname + window.location.search).href;
}

export function LoginUI() {
	const authContext = useContext(APIContext);
	const IntegrationSetting = useContext(IntegrationSettingsContext);
	const [number, setNumber] = useState<E164Number | undefined>(undefined);
	const [country, setCountry] = useState<CountryCode | undefined>(localStorage.getItem(Constants.STORAGE_COUNTRY_CODE) as CountryCode);
	const [processing, setProcessing] = useState<boolean>(false);

	useEffect(() => {
		let isMounted = true;
		if (country === null) {
			if (IntegrationSetting.phoneCountryCode !== null && IntegrationSetting.phoneCountryCode !== "") {
				isMounted = false;
				updateDefaultPhoneCountry(IntegrationSetting.phoneCountryCode);
			}
			if (isMounted)
				fetch("https://api.country.is")
					.then((data) => data.json())
					.then((data) => updateDefaultPhoneCountry(data.country));
		}
		return () => {
			isMounted = false;
		};
	}, [country === null]);

	const performLogin = async () => {
		setProcessing(true);
		try {
			await authContext.loginStart(number ?? "");
		} finally {
			setProcessing(false);
		}
	};

	const performVipps = async () => {
		setProcessing(true);
		try {
			let returnURL = window.location.href;
			if (
				(window.location.search.includes(Constants.SSO_PARAM) && window.location.search.includes(Constants.SSO_TYPE)) ||
				window.location.search.includes(Constants.TerminalSearchParam) ||
				window.location.href.endsWith("#/") ||
				window.location.href.includes("/#/")
			)
				returnURL = cleanLocationBeforeVippsRedirect();
			const url = await authContext.vippsStart(returnURL);
			if (url) window.location.replace(url);
		} finally {
			setProcessing(false);
		}
	};

	const updateDefaultPhoneCountry = (c: CountryCode) => {
		if (c !== undefined) {
			localStorage.setItem(Constants.STORAGE_COUNTRY_CODE, c);
			setCountry(c);
		}
	};

	const handleEnter = (event: KeyboardEvent) => {
		if (!isValid) return;
		if (event.key === "Enter") performLogin();
	};

	var isValid = isValidPhoneNumber(number ?? "", country);
	const vippsIsEnabled = IntegrationSetting.authenticationTypes.includes(AuthenticationTypes.Vipps) ?? false;

	var TLD = window.location.host.split(".")[window.location.host.split(".").length - 1];
	let phoneInputPlaceholder = "000 00 000";
	switch (TLD) {
		case "se":
		case "localhost:3000":
			phoneInputPlaceholder = "000 00 0000";
			break;
		default:
			break;
	}

	return (
		<>
			<TopBarIcons Visibility={IntegrationSetting.topbarOnLoginRouter} IgnoreResizeOnLoginRouter IconSize="small" IconName="loyall" Transform="scale(1);" />
			<Card
				sx={{
					background: "unset",
					color: "unset",
					borderBottomLeftRadius: "inherit",
					borderBottomRightRadius: "inherit",
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					boxShadow: 0,
				}}
			>
				<CardContent sx={{ padding: 0, color: (theme) => theme.palette.text.primary, pl: 4, pr: 4, textAlign: "center" }}>
					<Typography
						variant="h5"
						sx={{
							fontSize: (theme) => (window.innerWidth <= 481 ? theme.typography.h5.fontSize : "1.2rem !important"),
						}}
						gutterBottom
					>
						{ElementTextByTemplate(Components.Login, IntegrationSetting.customer, 0, TemplateElements.texts)}
					</Typography>
					<Typography variant="body2" gutterBottom>
						{ElementTextByTemplate(
							Components.Login,
							IntegrationSetting.customer,
							IntegrationSetting.product.IsInSale ? 6 : 1,
							TemplateElements.texts,
							IntegrationSetting.product
						)}
					</Typography>
					<Typography
						variant="subtitle1"
						gutterBottom
						sx={{
							letterSpacing: (theme) => theme.typography.subtitle1.letterSpacing,
							fontSize: (theme) => (window.innerWidth <= 481 ? "2.5vw" : theme.typography.subtitle1.fontSize),
						}}
					>
						{ElementTextByTemplate(Components.Login, IntegrationSetting.customer, 2, TemplateElements.texts)}
					</Typography>
					<Typography variant="body2">
						{ElementTextByTemplate(Components.Login, IntegrationSetting.customer, 3, TemplateElements.texts)}{" "}
						{(IntegrationSetting.loginButtonStyle === LoginButtonStyle.Single &&
							ElementTextByTemplate(Components.Login, IntegrationSetting.customer, 4, TemplateElements.texts)) ||
							ElementTextByTemplate(Components.Login, IntegrationSetting.customer, 5, TemplateElements.texts)}
					</Typography>
				</CardContent>
				<CardActions sx={{ pt: 1, pb: 4, pl: 4, pr: 4 }}>
					<Box>
						<PhoneInput
							defaultCountry={country}
							onCountryChange={updateDefaultPhoneCountry}
							placeholder={phoneInputPlaceholder}
							value={number}
							numberInputProps={{
								onKeyDown: handleEnter,
							}}
							onChange={setNumber}
							autoComplete="tel"
							type="tel"
							inputMode="tel"
						/>
						<Box
							sx={{
								display: "flex",
								marginTop: 1,
								"& > *:not(:last-child)": { marginRight: 1 },
							}}
						>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								size="small"
								onClick={() => performLogin()}
								disabled={!isValid}
								sx={{
									bgcolor: (theme) => theme.palette.primary.dark,
									color: (theme) => theme.palette.text.secondary,
									fontSize: (theme) => theme.typography.subtitle2.fontSize,
									border: (theme) => (theme.palette.mode === "light" ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.primary.dark}`),
									height: "30px",
									"&.Mui-disabled": {
										color: (theme) => theme.palette.secondary.main,
										backgroundColor: (theme) => theme.palette.background.default,
										border: "unset",
										opacity: 0.8,
									},
								}}
							>
								{processing && <CircularStatic visible={processing} />}
								{!processing && <Trans>{GetTranslatedText(Components.Login, IntegrationSetting.customer, 0, TemplateElements.buttons)}</Trans>}
							</Button>
							{IntegrationSetting.loginButtonStyle !== LoginButtonStyle.Single && (
								<Button
									type="submit"
									fullWidth
									variant="contained"
									size="small"
									onClick={() => {
										log("navigating to /benefits", ConsoleType.Warning);
									}}
									disabled={!isValid}
								>
									<Trans>Sign up</Trans>
								</Button>
							)}
						</Box>
						{vippsIsEnabled && (
							<Box>
								<Box
									className="hr-loyall-login-divider"
									sx={{
										height: "24px",
										opacity: !isValid ? "0.1" : "0.5",
										color: (theme) => theme.palette.text.primary + " !important",
										"&::before": {
											border: (theme) => "0.01em solid " + theme.palette.text.primary + " !important",
										},
										"&::after": {
											border: (theme) => "0.01em solid " + theme.palette.text.primary + " !important",
										},
									}}
								>
									<Trans>or</Trans>
								</Box>
								<VippsButton onClick={() => performVipps()} isValid={!processing} />
							</Box>
						)}
					</Box>
				</CardActions>
			</Card>
		</>
	);
}
